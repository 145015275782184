import { Route, Routes } from 'react-router-dom';
import { RelativePages } from 'pages/page-types/pageType';
import { PaymentsPage } from './PaymentsPage';
import { PaymentsCompletePage } from './PaymentsCompletePage';

const PaymentsRouteBase: React.FC = () => {
  // Todo: add additional routing here as needed for our payments path options
  return (
    <Routes>
      <Route index element={<PaymentsPage />} />
      <Route path={RelativePages.upgradeComplete} element={<PaymentsCompletePage />} />
    </Routes>
  );
};
export default PaymentsRouteBase;
