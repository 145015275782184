import { createStyles, SelectItem } from '@mantine/core';
import { RedAlertBall, stringMax } from '@whylabs/observatory-lib';
import WhyLabsTooltip from '../../tooltip/WhyLabsTooltip';
import WhyLabsText from '../../text/WhyLabsText';
import WhyLabsTextHighlight from '../../text-highlight/WhyLabsTextHighlight';

interface LabelWithLineBreakAndAnomalyCountData extends React.ComponentPropsWithoutRef<'div'>, SelectItem {
  label: string;
  bottomText?: string;
  totalAnomalies?: number;
  disabled?: boolean;
  adHocRunId?: string;
  value: string;
  filterString?: string;
}

const useLabelWithLineBreakAndAnomalyCountStyles = createStyles(() => ({
  item: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemGrid: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto clamp(20px, auto, 65px)',
    gridTemplateAreas: '"label count"',
    gap: '15px',
  },
  count: {
    gridArea: 'count',
    justifySelf: 'end',
    '*': {
      cursor: 'inherit',
    },
  },
  labelArea: {
    gridArea: 'label',
  },
  label: {
    maxWidth: 'fit-content',
    whiteSpace: 'pre-wrap',
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
  },
  bottomText: {
    fontWeight: 400,
    fontSize: '12px',
  },
}));
const MAX_LABEL_LENGTH = 100;
export const LabelWithLineBreakAndAnomalyCount = ({
  label,
  bottomText,
  totalAnomalies,
  disabled,
  adHocRunId,
  filterString,
  ...others
}: LabelWithLineBreakAndAnomalyCountData): JSX.Element => {
  const disabledLabel = `[Disabled] ${label}`;
  const usedLabel = (disabled ? disabledLabel : '') || label;
  const showTooltip = disabled || label.length > MAX_LABEL_LENGTH;
  const { classes, cx } = useLabelWithLineBreakAndAnomalyCountStyles();
  return (
    <div {...others} id={others.value ? `item-${others.value}` : undefined} key={`item-${others.value}`}>
      <WhyLabsTooltip label={showTooltip ? usedLabel : ''} maxWidth={400}>
        <div className={cx({ [classes.itemGrid]: !!totalAnomalies }, classes.item)}>
          <div className={cx({ [classes.labelArea]: !!totalAnomalies }, classes.label)}>
            <WhyLabsText size={13} fw={600}>
              <WhyLabsTextHighlight highlight={filterString ?? ''}>
                {stringMax(label, MAX_LABEL_LENGTH)}
              </WhyLabsTextHighlight>
            </WhyLabsText>
            {bottomText && bottomText !== label && (
              <WhyLabsText size={12} fw={400}>
                {bottomText}
              </WhyLabsText>
            )}
          </div>
          {!!totalAnomalies && (
            <div className={classes.count}>
              <RedAlertBall adHocRunId={adHocRunId} alerts={totalAnomalies} inverted />
            </div>
          )}
        </div>
      </WhyLabsTooltip>
    </div>
  );
};
