import { createStyles, keyframes } from '@mantine/core';

const headTilt = keyframes({
  '0%': { transform: `rotate(-10deg)` },
  '15%': { transform: `rotate(5deg)` },
  '30%': { transform: `rotate(5deg)` },
  '50%': { transform: `rotate(0)` },
  '80%': { transform: `rotate(0)` },
  '90%': { transform: `rotate(-10deg)` },
  '100%': { transform: `rotate(-10deg)` },
});

const tailWag = keyframes({
  '0%': { transform: `rotate(-5deg)` },
  '50%': { transform: `rotate(5deg)` },
});

const eyebrowMove = keyframes({
  '0%': { transform: `translateY(-2px)` },
  '15%': { transform: `translateY(0px)` },
  '80%': { transform: `translateY(0px)` },
  '90%': { transform: `translateY(-2px)` },
  '100%': { transform: `translateY(-2px)` },
});
const headAnimationDuration = 7000;
const useStyles = createStyles({
  dogWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    marginTop: 80,
  },
  dialogWrapper: {
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    transform: 'translateX(10px)',
  },
  dogHead: {
    transform: 'rotate(-10deg)',
    animation: `${headTilt} ${headAnimationDuration}ms ease-in-out infinite`,
  },
  dogTail: {
    transform: 'rotate(-5deg)',
    animation: `${tailWag} 300ms ease-in-out infinite`,
  },
  dogEyebrowLeft: {
    animation: `${eyebrowMove} ${headAnimationDuration}ms ease-in-out infinite`,
  },
  dogEyebrowRight: {
    animation: `${eyebrowMove} ${headAnimationDuration}ms ease-in-out infinite`,
  },
});

export interface WhyDogProps {
  dialog?: JSX.Element;
}

export default function WhyDogIdle({ dialog }: WhyDogProps): JSX.Element {
  const { classes: styles } = useStyles();

  return (
    <div className={styles.dogWrapper}>
      <svg
        width="222"
        height="263"
        viewBox="0 0 222 263"
        fill="none"
        version="1.1"
        id="no-data-dog-svg1570"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9" id="no-data-dog-g1026">
          <ellipse opacity="0.1" cx="111" cy="243.5" rx="111" ry="19.5" fill="#9fa9ab" id="no-data-dog-ellipse824" />
          <path
            d="m 88.457,201.701 c 7.9242,10.188 22.169,14.056 28.3,14.716 4.292,0.749 18.396,-1.698 19.528,-14.716 1.132,-13.019 -6.226,-35.659 -16.414,-44.432 -10.189,-8.773 -28.3009,-9.622 -33.395,0 -5.0941,9.622 -7.9242,31.697 1.981,44.432 z"
            fill="url(#no-data-dog-paint0_linear)"
            id="no-data-dog-path826"
          />
          <path
            d="m 102.249,150.404 c -3.58,2.865 -6.9234,7.9 -8.2438,16.33 -2.3518,15.016 -1.2439,34.023 9.8818,46.158 5.096,2.147 9.921,3.206 12.87,3.524 4.291,0.749 18.395,-1.698 19.527,-14.716 1.132,-13.019 -6.226,-35.659 -16.414,-44.432 -4.835,-4.164 -11.455,-6.542 -17.621,-6.864 z"
            fill="url(#no-data-dog-paint1_linear)"
            id="no-data-dog-path828"
          />
          <path
            d="m 122.6,174.741 -20.295,-1.115 c -1.239,-0.068 -2.22,-1.097 -2.133,-2.335 0.48,-6.835 3.125,-11.173 5.188,-13.276 0.78,-0.794 1.937,-1.026 3.012,-0.736 8.104,2.189 13.577,9.222 16.315,14.425 0.782,1.487 -0.41,3.129 -2.087,3.037 z"
            fill="url(#no-data-dog-paint2_linear)"
            id="no-data-dog-path830"
          />
          <path
            d="m 118.854,194.613 c 0.396,-0.073 0.777,0.19 0.85,0.586 l 1.075,5.856 c 0.073,0.396 -0.189,0.777 -0.586,0.85 -0.397,0.073 -0.777,-0.19 -0.85,-0.586 l -1.076,-5.856 c -0.072,-0.396 0.19,-0.777 0.587,-0.85 z"
            fill="url(#no-data-dog-paint3_linear)"
            id="no-data-dog-path832"
          />
          <path
            d="m 121.574,194.613 c 0.396,-0.073 0.777,0.19 0.85,0.586 l 1.075,5.856 c 0.073,0.396 -0.189,0.777 -0.586,0.85 -0.397,0.073 -0.777,-0.19 -0.85,-0.586 l -1.076,-5.856 c -0.072,-0.396 0.19,-0.777 0.587,-0.85 z"
            fill="url(#no-data-dog-paint4_linear)"
            id="no-data-dog-path834"
          />
          <path
            d="m 125.144,195.199 c -0.073,-0.396 -0.454,-0.659 -0.85,-0.586 -0.397,0.073 -0.659,0.454 -0.587,0.85 l 1.076,5.856 c 0.073,0.396 0.453,0.659 0.85,0.586 0.397,-0.073 0.659,-0.454 0.586,-0.85 z"
            fill="url(#no-data-dog-paint5_linear)"
            id="no-data-dog-path836"
          />
          <path
            d="m 104.876,236.148 c -4.29,0.477 -11.5212,-1.986 -14.6003,-3.277 -1.6885,-2.682 -4.9464,-9.416 -4.4696,-14.899 0.5959,-6.854 2.9797,-9.684 12.5149,-11.323 9.535,-1.639 25.626,2.533 31.138,5.065 5.513,2.533 8.94,10.132 5.662,13.111 -3.278,2.98 -24.881,10.727 -30.245,11.323 z"
            fill="url(#no-data-dog-paint6_linear)"
            id="no-data-dog-path838"
          />
          <path
            opacity="0.5"
            d="m 96.3892,234.975 c 2.937,0.821 6.1488,1.435 8.4868,1.175 1.443,-0.161 4.062,-0.839 7.183,-1.802 -0.447,-4.343 -1.4,-7.571 -1.961,-8.911 l -9.322,-1.683 c -1.1516,2.936 -2.8353,7.238 -4.3868,11.221 z"
            fill="url(#no-data-dog-paint7_linear)"
            id="no-data-dog-path840"
          />
          <path
            d="m 122.082,209.187 c -17.927,-2.528 -27.1025,1.355 -29.5707,3.869 l -6.6202,4.081 c 0.7227,-6.252 3.2927,-8.917 12.4307,-10.488 6.6672,-1.146 16.5402,0.549 23.7602,2.538 z"
            fill="url(#no-data-dog-paint8_linear)"
            id="no-data-dog-path842"
          />
          <path
            d="m 86.5919,213.77 c 1.0133,-0.992 2.4035,-1.861 4.2789,-2.352 6.2575,-1.639 12.3662,7.598 13.8562,15.644 1.006,5.431 -1.738,7.991 -3.932,8.896 -3.8892,-0.603 -8.2919,-2.151 -10.5201,-3.086 -1.6886,-2.682 -4.9464,-9.416 -4.4697,-14.899 0.1402,-1.611 0.3791,-3 0.7867,-4.203 z"
            fill="url(#no-data-dog-paint9_linear)"
            id="no-data-dog-path844"
          />
          <path
            d="m 100.516,233.852 c -1.6364,2.407 -13.9916,4.404 -21.2359,5.263 -0.9171,0.109 -1.7553,-0.435 -1.8256,-1.356 -0.3058,-4.004 0.8683,-13.158 4.4172,-17.372 4.1433,-4.921 6.8623,-8.028 12.9477,-6.474 6.0856,1.553 7.8986,16.702 5.6966,19.939 z"
            fill="url(#no-data-dog-paint10_linear)"
            id="no-data-dog-path846"
          />
          <path
            d="m 98.1924,216.602 c -11.8842,1.255 -18.0597,13.981 -20.1982,22.179 -0.3026,-0.237 -0.5066,-0.588 -0.5397,-1.022 -0.3058,-4.004 0.8683,-13.158 4.4172,-17.372 4.1433,-4.921 6.8623,-8.028 12.9477,-6.474 1.3265,0.338 2.4499,1.323 3.373,2.689 z"
            fill="url(#no-data-dog-paint11_linear)"
            id="no-data-dog-path848"
          />
          <g className={styles.dogTail} transform-origin="155 190">
            <path
              d="m 185.878,129.633 -16.521,62.555 -14.625,-22.5 24.737,-42.701 c 2.045,-3.53 7.45,-1.298 6.409,2.646 z"
              fill="url(#no-data-dog-paint12_linear)"
              id="no-data-dog-why-dog-tail"
            />
          </g>
          <path
            d="m 73.6484,240.752 h -5.2336 c -5.1734,1.384 -15.1592,0.794 -13.7155,-12.632 1.8047,-16.784 9.7453,-34.47 16.0617,-40.967 6.3163,-6.496 13.7155,-5.053 14.2569,6.497 0.5414,11.55 -0.9024,29.958 -1.9852,34.47 -0.8662,3.609 -6.6172,9.925 -9.3843,12.632 z"
            fill="url(#no-data-dog-paint13_linear)"
            id="no-data-dog-path852"
          />
          <path
            d="m 78.7079,183.328 c -4.5209,5.973 -12.2239,20.002 -16.4297,41.541 -1.3438,6.882 -2.9908,11.31 -4.7336,14.017 -2.118,-1.94 -3.4358,-5.283 -2.846,-10.769 1.8047,-16.783 9.7453,-34.469 16.0616,-40.966 2.6545,-2.73 5.5002,-4.058 7.9477,-3.823 z"
            fill="url(#no-data-dog-paint14_linear)"
            id="no-data-dog-path854"
          />
          <path
            d="m 68.4146,240.75 h 5.2336 c 0,0 -1e-4,0 0,0 0.3007,-4.633 -0.6496,-14.871 -6.8577,-18.769 -7.7601,-4.873 -11.3695,0.541 -11.9109,4.692 -0.0106,0.082 -0.0249,0.167 -0.0428,0.256 -0.0492,0.396 -0.0951,0.792 -0.1377,1.188 -1.4437,13.427 8.5421,14.016 13.7155,12.633 z"
            fill="url(#no-data-dog-paint15_linear)"
            id="no-data-dog-path856"
          />
          <path
            d="m 66.8363,226.464 c 3.4449,5.377 3.6059,12.603 3.2558,15.544 -5.9864,4.411 -19.6397,5.356 -20.4799,2.31 -0.8402,-3.045 0.9452,-12.813 4.201,-17.854 3.2558,-5.041 8.7171,-6.721 13.0231,0 z"
            fill="url(#no-data-dog-paint16_linear)"
            id="no-data-dog-path858"
          />
          <path
            d="m 85.3036,191.643 c 4.1503,-2.767 10.0873,-18.445 12.5371,-25.939 l -34.8012,-61.82 c -2.3057,7.133 -6.6577,25.593 -5.6201,42.366 1.2969,20.968 6.917,43.016 10.8078,45.393 3.8908,2.378 11.8886,3.459 17.0764,0 z"
            fill="url(#no-data-dog-paint17_linear)"
            id="no-data-dog-path860"
          />
          <path
            d="m 97.8407,165.705 c -2.4498,7.494 -8.3868,23.172 -12.5371,25.939 -0.1757,0.117 -0.3547,0.229 -0.5366,0.336 -2.0863,0.562 -4.6353,0.166 -6.1643,-3.362 -2.248,-5.188 -2.81,-26.371 -2.81,-36.315 l 6.4585,-14.291 z"
            fill="url(#no-data-dog-paint18_linear)"
            id="no-data-dog-path862"
          />
          <path
            d="m 67.799,112.338 c -1.7126,10.276 -3.5814,28.035 -2.1655,46.017 1.5657,19.884 5.8484,31.11 8.3559,35.248 -2.2561,-0.355 -4.2993,-1.066 -5.762,-1.96 -3.8908,-2.377 -9.5109,-24.425 -10.8079,-45.393 -1.0375,-16.773 3.3145,-35.233 5.6201,-42.366 z"
            fill="url(#no-data-dog-paint19_linear)"
            id="no-data-dog-path864"
          />
          <path
            d="m 122.357,77.8879 c -5.735,-6.8817 -5.974,-14.224 -5.376,-17.0349 -15.652,-4.039 -47.7191,-10.3488 -50.7776,-3.2758 -3.8232,8.8411 -8.8412,34.6477 -4.0622,67.8618 4.779,33.214 17.4434,48.029 25.8066,56.153 8.3633,8.125 17.2042,17.205 24.8512,27.718 7.646,10.514 18.16,18.639 36.081,16.01 17.921,-2.628 30.108,-26.762 24.851,-43.728 -5.257,-16.965 -9.08,-16.726 -18.877,-43.011 -9.797,-26.284 -25.329,-52.0909 -32.497,-60.6931 z"
            fill="url(#no-data-dog-paint20_linear)"
            id="no-data-dog-path866"
          />
          <path
            d="m 60.7082,112.322 c 0.3152,4.22 0.784,8.6 1.4339,13.117 4.7791,33.214 17.4434,48.029 25.8066,56.154 8.3633,8.124 17.2043,17.204 24.8513,27.718 7.646,10.514 18.16,18.638 36.081,16.009 6.011,-0.881 11.377,-4.182 15.656,-8.821 l -4.109,-38.279 c -12.597,4.387 -42.121,3.481 -59.444,-35.242 -8.9071,-19.909 -24.556,-28.251 -40.2758,-30.656 z"
            fill="url(#no-data-dog-paint21_linear)"
            id="no-data-dog-path868"
          />
          <path
            opacity="0.3"
            d="m 158.917,221.349 c -3.049,2.04 -6.426,3.442 -10.036,3.971 -17.921,2.629 -28.435,-5.495 -36.081,-16.009 -7.647,-10.514 -16.488,-19.594 -24.8513,-27.718 -8.3632,-8.125 -21.0276,-22.94 -25.8066,-56.154 -0.65,-4.517 -1.1187,-8.897 -1.4339,-13.117 4.2104,0.644 8.4157,1.714 12.4877,3.319 0.2108,1.842 0.4512,3.71 0.7235,5.602 4.779,33.214 17.4433,48.029 25.8066,56.154 8.363,8.124 17.204,17.204 24.851,27.718 7.395,10.168 17.473,18.102 34.34,16.234 z"
            fill="url(#no-data-dog-paint22_linear)"
            id="no-data-dog-path870"
          />
          <path
            d="m 134.262,95.722 c -2.212,6.583 -5.806,14.801 -11.427,23.982 -6.955,11.361 -10.143,20.36 -12.754,27.733 -2.772,7.825 -4.895,13.818 -10.185,18.862 -7.8674,7.502 -18.6767,5.757 -27.1719,-5.234 -4.4174,-8.671 -8.3556,-20.151 -10.5822,-35.626 -4.779,-33.2141 0.239,-59.0207 4.0622,-67.8618 3.0585,-7.073 35.1259,-0.7632 50.7779,3.2758 -0.598,2.8109 -0.359,10.1532 5.375,17.0349 2.907,3.4879 7.188,9.8043 11.905,17.8341 z"
            fill="url(#no-data-dog-paint23_linear)"
            id="no-data-dog-path872"
          />
          <path
            d="m 128.451,86.337 c -3.33,12.366 -12.75,31.721 -33.0948,43.642 -19.9651,11.698 -30.5631,-7.861 -35.1172,-27.175 -0.5217,-22.0551 3.0655,-38.521 5.9653,-45.2268 3.0586,-7.073 35.1257,-0.7632 50.7777,3.2758 -0.598,2.8109 -0.359,10.1532 5.376,17.0349 1.646,1.9761 3.734,4.86 6.093,8.4491 z"
            fill="url(#no-data-dog-paint24_linear)"
            id="no-data-dog-path874"
          />
          <path
            d="m 89.1125,55.3256 c -2.9635,15.13 -7.8598,46.7204 -7.1382,70.3524 0.5429,17.781 4.8126,34.205 8.6686,45.114 -5.9954,0.606 -12.4389,-2.636 -17.9192,-9.727 -4.4174,-8.671 -8.3556,-20.151 -10.5822,-35.626 -4.779,-33.2141 0.2389,-59.0207 4.0621,-67.8618 1.6386,-3.7892 11.6031,-3.7375 22.9089,-2.2516 z"
            fill="url(#no-data-dog-paint25_linear)"
            id="no-data-dog-path876"
          />
          <path
            d="m 126.873,112.649 c 3.384,-6.35 5.761,-12.081 7.389,-16.9276 -4.717,-8.0298 -8.998,-14.3462 -11.905,-17.8341 -5.734,-6.8817 -5.973,-14.224 -5.375,-17.0349 -0.269,-0.0693 -0.542,-0.1392 -0.82,-0.2098 l -6.23,8.4036 c 0.218,6.8508 3.998,24.412 16.941,43.6028 z"
            fill="url(#no-data-dog-paint26_linear)"
            id="no-data-dog-path878"
          />
          <path
            d="m 121.285,103.498 c 3.555,-6.2214 5.842,-12.2441 7.166,-17.1616 -2.359,-3.5891 -4.447,-6.473 -6.093,-8.4491 -0.045,-0.0537 -0.09,-0.1075 -0.134,-0.1613 -5.605,-6.8349 -5.835,-14.0846 -5.242,-16.8736 -0.269,-0.0693 -0.542,-0.1392 -0.82,-0.2098 l -6.23,8.4036 c 0.183,5.742 2.868,19.0079 11.353,34.4518 z"
            fill="url(#no-data-dog-paint27_linear)"
            id="no-data-dog-path880"
          />
          <path
            opacity="0.5"
            d="m 60.3809,103.401 c -0.0481,-0.199 -0.0957,-0.398 -0.1426,-0.597 -0.5217,-22.0551 3.0655,-38.521 5.9653,-45.2268 0.9899,-2.2891 5.0182,-3.1764 10.5451,-3.1927 0.0683,-2e-4 0.1368,-3e-4 0.2056,-2e-4 0.0175,0 0.035,0 0.0525,10e-5 -5.6074,10.0182 -14.2279,29.379 -16.6259,49.0166 z"
            fill="url(#no-data-dog-paint28_linear)"
            id="no-data-dog-path882"
          />
          <path
            d="m 119.76,74.1457 c -2.005,11.4504 -11.254,29.6963 -37.7864,38.8683 -6.52,-6.312 -18.3193,-22.0351 -19.8699,-39.6256 1.1755,-7.1903 2.7156,-12.6117 4.0992,-15.8112 3.0585,-7.073 35.1261,-0.7632 50.7771,3.2758 -0.489,2.3035 -0.418,7.6501 2.78,13.2927 z"
            fill="url(#no-data-dog-paint29_linear)"
            id="no-data-dog-path884"
          />
          <path
            d="m 81.9429,112.982 c 0.1355,-2.438 1.131,-7.954 4.1432,-11.689 3.843,-4.7654 3.9967,-5.3803 4.4579,-7.225 0.3135,-1.2539 -0.5346,-23.0917 -1.2053,-38.7126 -11.3985,-1.5148 -21.4848,-1.5926 -23.1342,2.2218 -1.3836,3.1995 -2.9237,8.6209 -4.0992,15.8112 1.5481,17.5615 13.3109,33.2616 19.8376,39.5936 z"
            fill="url(#no-data-dog-paint30_linear)"
            id="no-data-dog-path886"
          />
          <path
            d="m 81.9429,112.982 c 0.1355,-2.438 1.131,-7.954 4.1432,-11.689 3.843,-4.7654 3.9967,-5.3803 4.4579,-7.225 0.3135,-1.2539 -0.5346,-23.0917 -1.2053,-38.7126 -11.3985,-1.5148 -21.4848,-1.5926 -23.1342,2.2218 -1.3836,3.1995 -2.9237,8.6209 -4.0992,15.8112 1.5481,17.5615 13.3109,33.2616 19.8376,39.5936 z"
            fill="url(#no-data-dog-paint31_linear)"
            id="no-data-dog-path888"
          />
          <path
            d="m 70.3529,156.013 c -2.563,-12.823 -4.3959,-29.012 -1.8866,-41.559 3.0588,6.118 13.7984,15.192 32.2867,2.549 1.519,5.959 4.97,17.759 9.536,29.845 -0.07,0.198 -0.139,0.394 -0.209,0.59 -2.771,7.825 -4.894,13.819 -10.1843,18.862 -7.8674,7.502 -18.6767,5.757 -27.1719,-5.234 -0.8089,-1.587 -1.6017,-3.27 -2.3709,-5.053 z"
            fill="url(#no-data-dog-paint32_linear)"
            id="no-data-dog-path890"
          />
          <path
            d="m 70.4947,156.34 c -0.0474,-0.108 -0.0947,-0.218 -0.1419,-0.327 -2.563,-12.823 -4.3959,-29.012 -1.8866,-41.559 1.8051,3.61 6.2853,8.25 13.4546,8.849 0.0118,0.8 0.0296,1.592 0.0535,2.376 0.5429,17.781 4.8126,34.205 8.6686,45.114 -5.6207,0.568 -11.6352,-2.246 -16.8807,-8.442 -0.3497,-0.413 -0.696,-0.842 -1.0385,-1.285 -0.7593,-1.49 -1.5043,-3.063 -2.229,-4.726 z"
            fill="url(#no-data-dog-paint33_linear)"
            id="no-data-dog-path892"
          />
          <path
            d="m 89.0851,85.2804 c -2.8247,3.8261 -7.2726,5.9529 -14.4573,6.275 C 69.6994,86.3175 67.9915,81.6926 68.632,76.98 69.1443,73.21 73.0054,60.5995 74.8718,54.7655 l 16.7597,-5.1094 8.4595,15.3438 c -2.7272,5.4848 -8.746,17.2196 -11.0059,20.2805 z"
            fill="url(#no-data-dog-paint40_linear)"
            id="no-data-dog-path906"
          />
          <path
            opacity="0.3"
            d="m 90.406,83.2556 c 0,0 -6.5755,0.5214 -12.1504,-0.5399 -6.1509,-4.2927 -9.1438,-8.174 -9.1438,-8.174 -0.2367,1.0108 -0.4081,1.8555 -0.4912,2.4674 -0.6405,4.7126 1.0674,9.3374 5.9958,14.5753 7.1847,-0.3221 11.6326,-2.4488 14.4573,-6.275 0.3588,-0.4859 0.8123,-1.1904 1.3323,-2.0538 z"
            fill="url(#no-data-dog-paint41_linear)"
            id="no-data-dog-path908"
          />

          <g className={styles.dogHead} id="no-data-dog-why-dog-head" transform-origin="80 65">
            <path
              d="m 133.016,28.6919 c -0.838,-0.6895 -8.401,-0.8186 -12.078,-0.7969 -0.573,3.3484 2.758,19.4599 6.114,15.4943 3.356,-3.9656 7.012,-13.8356 5.964,-14.6974 z"
              fill="url(#no-data-dog-paint34_linear)"
              id="no-data-dog-path894"
            />
            <path
              d="m 120.983,30.7297 c 0.574,5.6358 3.313,15.9173 6.069,12.6609 2.286,-2.7012 4.711,-8.142 5.694,-11.6092 -3.529,-0.3349 -9.015,-0.8412 -11.763,-1.0517 z"
              fill="url(#no-data-dog-paint35_linear)"
              id="no-data-dog-path896"
            />
            <path
              d="m 76.8058,5.88037 c 1.0816,0.08959 6.5959,5.26773 9.2179,7.84563 -1.9226,2.8006 -15.539,12.0347 -15.1824,6.8518 0.3565,-5.1828 4.6126,-14.8094 5.9645,-14.69743 z"
              fill="url(#no-data-dog-paint36_linear)"
              id="no-data-dog-path898"
            />
            <path
              d="m 84.015,15.7272 c -4.3393,3.6417 -13.4684,9.107 -13.1756,4.8511 0.2429,-3.5304 2.2951,-9.1226 4.0063,-12.29432 2.7647,2.21952 7.0515,5.67892 9.1693,7.44322 z"
              fill="url(#no-data-dog-paint37_linear)"
              id="no-data-dog-path900"
            />
            <path
              d="M 65.4683,26.3424 C 70.9192,12.9107 89.0957,7.60535 106.067,14.4925 c 16.26,6.599 25.514,21.997 21.466,35.0892 -0.698,2.2547 -1.789,4.3012 -3.115,6.246 -3.866,5.6679 -13.011,17.2139 -14.662,18.9953 -7.368,7.9518 -19.584,10.8219 -30.7308,6.2983 -11.1468,-4.5236 -17.908,-15.0952 -17.6511,-25.9329 0.0593,-2.5024 1.6463,-17.9839 2.841,-24.4299 0.2557,-1.512 0.6765,-2.995 1.2532,-4.4161 z"
              fill="url(#no-data-dog-paint38_linear)"
              id="no-data-dog-path902"
            />
            <path
              opacity="0.3"
              d="m 117.662,64.9705 c -2.004,-9.5254 5.085,-23.6976 9.265,-30.182 1.786,4.8761 2.091,9.9902 0.605,14.7939 -0.697,2.2547 -1.788,4.3012 -3.115,6.246 -1.631,2.3923 -4.203,5.8317 -6.755,9.1421 z"
              fill="url(#no-data-dog-paint39_linear)"
              id="no-data-dog-path904"
            />
            <path
              opacity="0.2"
              d="m 97.179,70.7551 c -3.6571,1.3866 -9.508,2.646 -15.6005,1.2094 -7.7343,-1.8236 -10.8029,6.8184 -11.7811,12.7051 -1.169,-2.6118 -1.5108,-5.1381 -1.164,-7.69 0.5124,-3.7701 4.3734,-16.3806 6.2398,-22.2146 l 10.7517,-3.2777 0.8766,0.0068 12.1485,16.378 c -0.4585,0.9055 -0.9539,1.8771 -1.471,2.883 z"
              fill="url(#no-data-dog-paint42_linear)"
              id="no-data-dog-path910"
            />
            <g opacity="0.35" id="no-data-dog-g916">
              <path
                d="m 62.6569,41.9283 4.6751,12.2049 c -0.7635,2.6174 -1.8137,8.8728 -1.3025,16.546 -3.1196,-4.597 -4.7848,-10.01 -4.6549,-15.4911 0.0346,-1.4598 0.5891,-7.3366 1.2823,-13.2598 z"
                fill="url(#no-data-dog-paint43_linear)"
                id="no-data-dog-path912"
              />
              <path
                d="m 97.3203,82.2828 c 5.2357,-4.8939 8.5557,-9.7243 9.7687,-12.0151 l 10.094,-4.6776 c -3.326,4.2982 -6.516,8.2502 -7.426,9.2327 -3.335,3.5993 -7.664,6.1574 -12.4367,7.46 z"
                fill="url(#no-data-dog-paint44_linear)"
                id="no-data-dog-path914"
              />
            </g>
            <path
              d="M 121.237,25.2338 107.881,36.9278 88.6157,29.1095 87.8528,11.611 c 5.7905,-0.5039 12.0811,0.3927 18.2142,2.8817 6.186,2.5106 11.359,6.2948 15.17,10.7411 z"
              fill="url(#no-data-dog-paint45_linear)"
              id="no-data-dog-path918"
            />
            <path
              opacity="0.3"
              d="m 75.5078,15.2663 c -4.5416,2.5385 -8.098,6.2919 -10.0398,11.0765 -0.5767,1.4211 -0.9975,2.9041 -1.2532,4.4162 -0.6255,3.375 -1.3586,9.2269 -1.9236,14.396 C 69.805,34.7289 73.9921,22.0693 75.5078,15.2663 Z"
              fill="url(#no-data-dog-paint46_linear)"
              id="no-data-dog-path920"
            />
            <path
              d="M 98.4861,33.1151 106.047,14.4845 C 99.9201,12.0017 93.6369,11.1076 87.8527,11.611 l 0.7629,17.4984 z"
              fill="url(#no-data-dog-paint47_linear)"
              id="no-data-dog-path922"
            />
            <path
              d="m 101.786,59.4747 6.163,-1.2388 c 1.809,-0.3636 3.425,-1.3702 4.549,-2.8336 l 7.923,-10.3159 c 1.184,-1.5421 1.632,-3.5555 0.863,-5.3416 C 119,34.4354 113.415,26.3904 102.96,22.1478 92.5062,17.9053 82.8942,19.783 77.5557,21.9987 75.7597,22.744 74.6777,24.5 74.4525,26.4314 L 72.9456,39.351 c -0.2137,1.8327 0.2437,3.6808 1.2877,5.2021 l 3.557,5.1835 z"
              fill="url(#no-data-dog-paint48_linear)"
              id="no-data-dog-path924"
            />
            <path
              d="M 108.199,57.7039 74.4233,43.9971 73.4608,57.0542 c -0.2435,3.3036 1.6719,6.387 4.7412,7.6326 l 12.8683,5.2223 c 3.0694,1.2456 6.5919,0.3689 8.7194,-2.17 z"
              fill="url(#no-data-dog-paint49_linear)"
              id="no-data-dog-path926"
            />
            <path
              opacity="0.3"
              d="m 100.892,61.3294 c -5.7577,-1.6535 -18.0643,-4.9685 -21.2331,-5.0009 -2.3223,-0.0238 -3.7039,3.239 -4.3992,6.1832 -1.2296,-1.4841 -1.8842,-3.417 -1.7356,-5.4318 l 0.9625,-13.0571 11.1163,4.5112 z"
              fill="url(#no-data-dog-paint50_linear)"
              id="no-data-dog-path928"
            />
            <path
              d="m 109.518,57.7593 c 2.414,-3.0715 0.741,-13.5 -13.2303,-19.17 -13.9715,-5.67 -22.4385,0.6437 -22.8478,4.5286 -0.4093,3.8848 -0.723,7.5338 4.0777,9.0701 7.9745,2.5519 15.2577,5.5076 22.7554,9.2346 4.513,2.2437 6.831,-0.5919 9.245,-3.6633 z"
              fill="url(#no-data-dog-paint51_linear)"
              id="no-data-dog-path930"
            />
            <path
              d="m 82.2721,36.6245 c 3.6716,-0.6575 8.3985,-0.3147 14.0149,1.9645 5.616,2.2793 9.245,5.3276 11.42,8.3577 -0.964,3.8463 -3.717,9.6201 -9.6633,13.3876 C 92.0624,57.4675 86.1609,55.0726 79.8734,52.9604 78.234,46.1142 80.2833,40.055 82.2721,36.6245 Z"
              fill="url(#no-data-dog-paint52_linear)"
              id="no-data-dog-path932"
            />
            <path
              d="m 91.5985,50.1438 c 0.727,0.2333 3.2092,0.2916 7.3224,-1.3417 5.1421,-2.0418 6.6191,-2.7978 6.8061,-4.0161 0.186,-1.2184 -2.015,-4.1451 -9.1045,-7.0221 -7.0892,-2.877 -10.7075,-2.312 -11.4229,-1.3083 -0.7154,1.0037 -0.1826,2.5755 2.0822,7.6231 1.8118,4.0378 3.6327,5.7258 4.3167,6.0651 z"
              fill="url(#no-data-dog-paint53_linear)"
              id="no-data-dog-path934"
            />
            <path
              d="m 91.5985,50.1438 c 0,-10e-5 0,0 0,0 z"
              fill="url(#no-data-dog-paint54_linear)"
              id="no-data-dog-path936"
            />
            <circle
              cx="86.672699"
              cy="29.1327"
              r="3.4744501"
              transform="rotate(22.0885,86.6727,29.1327)"
              fill="url(#no-data-dog-paint55_linear)"
              id="no-data-dog-circle938"
            />
            <ellipse
              cx="88.105003"
              cy="28.853399"
              rx="1.11295"
              ry="0.95395797"
              transform="rotate(22.0885,88.105,28.8534)"
              fill="url(#no-data-dog-paint56_linear)"
              id="no-data-dog-ellipse940"
            />
            <path
              className={styles.dogEyebrowLeft}
              opacity="0.5"
              d="m 86.6183,22.236 c 1.1429,-0.5633 2.3505,-1.1372 2.8114,-1.3537 0.8352,-0.3962 1.1107,-0.8786 1.1447,-1.3612 0.034,-0.4826 -0.6137,-1.2597 -2.624,-0.8588 -2.0102,0.4009 -3.0002,1.7992 -3.3962,2.7675 -0.396,0.9683 0.6354,1.5103 2.0641,0.8062 z"
              fill="url(#no-data-dog-paint57_linear)"
              id="no-data-dog-why-dog-eyebrow-left"
            />
            <circle
              r="3.4744501"
              transform="matrix(-0.926604,-0.376038,-0.376038,0.926604,109.963,38.5844)"
              fill="url(#no-data-dog-paint58_linear)"
              id="no-data-dog-circle944"
              cx="0"
              cy="0"
            />
            <circle
              r="0.95395797"
              transform="matrix(-0.926604,-0.376038,-0.376038,0.926604,109.169,37.4019)"
              fill="url(#no-data-dog-paint59_linear)"
              id="no-data-dog-circle946"
              cx="0"
              cy="0"
            />
            <path
              className={styles.dogEyebrowRight}
              opacity="0.5"
              d="m 115.64,34.0053 c -0.41,-1.2064 -0.859,-2.466 -1.031,-2.9451 -0.311,-0.8706 -0.165,-1.4065 0.152,-1.7719 0.318,-0.3653 1.325,-0.4569 2.463,1.2479 1.138,1.7048 0.85,3.3936 0.445,4.3583 -0.404,0.9648 -1.517,0.6188 -2.029,-0.8892 z"
              fill="url(#no-data-dog-paint60_linear)"
              id="no-data-dog-why-dog-eyebrow-right"
            />
            <path
              d="m 126.06,42.9065 c 2.662,-6.2701 5.782,-12.0912 7.01,-14.2179 2.096,2.3818 5.04,18.3058 4.825,23.6872 -0.215,5.3815 -0.372,9.1823 -3.116,10.9126 -2.744,1.7302 -10.528,5.2796 -12.556,1.8316 -2.028,-3.4479 0.511,-14.3759 3.837,-22.2135 z"
              fill="url(#no-data-dog-paint61_linear)"
              id="no-data-dog-path950"
            />
            <path
              d="m 122.07,64.825 c 7.796,-8.4325 12.586,-16.3742 14.988,-21.4218 -0.99,-6.2574 -2.659,-13.2067 -3.987,-14.7162 -1.228,2.1267 -4.349,7.9478 -7.01,14.2179 -3.237,7.6273 -5.729,18.1814 -3.991,21.9201 z"
              fill="url(#no-data-dog-paint62_linear)"
              id="no-data-dog-path952"
            />
            <path
              d="m 124.762,66.5419 c -0.953,-1.2158 -1.758,-4.5177 0.009,-11.4728 1.934,-7.6081 4.449,-13.0158 6.32,-17.038 1.77,-3.8066 2.963,-6.3722 2.54,-8.3875 -0.006,-0.0298 -0.012,-0.0594 -0.018,-0.0889 -0.185,-0.3744 -0.366,-0.6683 -0.541,-0.8674 -1.228,2.1267 -4.349,7.9478 -7.01,14.2179 -3.327,7.8376 -5.866,18.7656 -3.838,22.2135 0.524,0.8907 1.432,1.3144 2.538,1.4232 z"
              fill="url(#no-data-dog-paint63_linear)"
              id="no-data-dog-path954"
            />
            <path
              d="M 71.889,20.9203 C 74.3498,14.569 76.1683,8.21941 76.7699,5.83855 73.6069,6.08621 60.3984,15.4551 56.8025,19.4646 c -3.5959,4.0095 -6.132,6.8449 -5.3699,9.9979 0.7621,3.1531 3.8714,11.1233 7.7286,10.0636 3.8571,-1.0597 9.6517,-10.6666 12.7278,-18.6058 z"
              fill="url(#no-data-dog-paint64_linear)"
              id="no-data-dog-path956"
            />
            <path
              d="M 59.4744,39.4222 C 59.7593,27.9415 61.8582,18.9076 63.6537,13.6142 68.724,9.81606 74.7638,5.9952 76.7685,5.83823 76.1669,8.21909 74.3485,14.5687 71.8876,20.92 68.894,28.6462 63.3259,37.9518 59.4744,39.4222 Z"
              fill="url(#no-data-dog-paint65_linear)"
              id="no-data-dog-path958"
            />
            <path
              d="m 56.3487,38.7783 c 1.5304,-0.2083 4.4091,-2.015 7.9883,-8.2349 3.9151,-6.8039 5.8798,-12.435 7.3412,-16.6233 1.383,-3.96377 2.3151,-6.63534 4.0229,-7.78589 0.0253,-0.01702 0.0504,-0.03395 0.0753,-0.05079 0.3935,-0.13979 0.7282,-0.22428 0.9927,-0.24499 -0.6016,2.38086 -2.42,8.73047 -4.8809,15.08177 -3.0761,7.9392 -8.8706,17.5461 -12.7278,18.6058 -0.9963,0.2737 -1.9427,-0.0551 -2.8117,-0.7477 z"
              fill="url(#no-data-dog-paint66_linear)"
              id="no-data-dog-path960"
            />
          </g>
          <path
            d="m 71.5687,106.444 c 0.0995,-0.337 0.4532,-0.529 0.7901,-0.429 0.3368,0.099 0.5292,0.453 0.4297,0.79 l -1.163,3.936 c -0.0996,0.337 -0.4533,0.529 -0.7902,0.43 -0.3368,-0.1 -0.5292,-0.454 -0.4297,-0.79 z"
            fill="url(#no-data-dog-paint67_linear)"
            id="no-data-dog-path962"
          />
          <path
            d="m 71.5687,106.444 c 0.0995,-0.337 0.4532,-0.529 0.7901,-0.429 0.3368,0.099 0.5292,0.453 0.4297,0.79 l -1.163,3.936 c -0.0996,0.337 -0.4533,0.529 -0.7902,0.43 -0.3368,-0.1 -0.5292,-0.454 -0.4297,-0.79 z"
            fill="url(#no-data-dog-paint68_linear)"
            id="no-data-dog-path964"
          />
          <path
            d="m 74.3005,108.078 c -0.3368,-0.099 -0.6905,0.093 -0.7901,0.43 l -1.163,3.936 c -0.0995,0.337 0.0929,0.691 0.4297,0.79 0.3368,0.1 0.6906,-0.093 0.7901,-0.429 l 1.163,-3.937 c 0.0996,-0.337 -0.0928,-0.69 -0.4297,-0.79 z"
            fill="url(#no-data-dog-paint69_linear)"
            id="no-data-dog-path966"
          />
          <path
            d="m 74.3005,108.078 c -0.3368,-0.099 -0.6905,0.093 -0.7901,0.43 l -1.163,3.936 c -0.0995,0.337 0.0929,0.691 0.4297,0.79 0.3368,0.1 0.6906,-0.093 0.7901,-0.429 l 1.163,-3.937 c 0.0996,-0.337 -0.0928,-0.69 -0.4297,-0.79 z"
            fill="url(#no-data-dog-paint70_linear)"
            id="no-data-dog-path968"
          />
          <path
            d="m 76.2426,110.142 c -0.3368,-0.1 -0.6906,0.093 -0.7901,0.43 l -1.163,3.936 c -0.0995,0.337 0.0928,0.69 0.4297,0.79 0.3368,0.099 0.6906,-0.093 0.7901,-0.43 l 1.163,-3.936 c 0.0995,-0.337 -0.0928,-0.691 -0.4297,-0.79 z"
            fill="url(#no-data-dog-paint71_linear)"
            id="no-data-dog-path970"
          />
          <path
            d="m 76.2426,110.142 c -0.3368,-0.1 -0.6906,0.093 -0.7901,0.43 l -1.163,3.936 c -0.0995,0.337 0.0928,0.69 0.4297,0.79 0.3368,0.099 0.6906,-0.093 0.7901,-0.43 l 1.163,-3.936 c 0.0995,-0.337 -0.0928,-0.691 -0.4297,-0.79 z"
            fill="url(#no-data-dog-paint72_linear)"
            id="no-data-dog-path972"
          />
          <path
            d="m 95.5992,160.807 c -1.4951,17.942 2.6996,30.042 4.9838,33.849 18.274,1.453 28.242,-11.837 31.772,-16.405 3.53,-4.569 7.476,-12.668 5.814,-33.849 -1.661,-21.181 -5.191,-24.504 -10.798,-26.581 -5.607,-2.076 -11.006,-1.869 -16.82,0 -5.815,1.869 -13.0829,20.559 -14.9518,42.986 z"
            fill="url(#no-data-dog-paint73_linear)"
            id="no-data-dog-path974"
          />
          <path
            d="m 127.328,117.805 c -12.183,0.478 -18.536,27.404 -20.1,43.832 -1.662,17.443 3.322,25.749 9.137,27.618 1.903,0.612 4.057,0.109 6.108,-0.889 4.798,-3.53 7.974,-7.644 9.722,-9.909 0.054,-0.071 0.108,-0.14 0.16,-0.208 3.53,-4.568 7.475,-12.667 5.814,-33.848 -1.661,-21.182 -5.191,-24.504 -10.798,-26.581 -0.014,-0.005 -0.029,-0.01 -0.043,-0.015 z"
            fill="url(#no-data-dog-paint74_linear)"
            id="no-data-dog-path976"
          />
          <path
            d="m 119.32,122.482 c 2.321,-2.812 4.985,-4.559 8.008,-4.677 l 0.043,0.015 c 5.607,2.077 9.137,5.399 10.798,26.581 1.661,21.181 -2.284,29.28 -5.814,33.848 -0.052,0.068 -0.106,0.137 -0.16,0.207 -0.872,1.131 -2.1,2.721 -3.707,4.47 4.363,-30.91 -3.228,-51.531 -9.168,-60.444 z"
            fill="url(#no-data-dog-paint75_linear)"
            id="no-data-dog-path978"
          />
          <path
            d="m 107.593,194.487 c -8.2038,-7.586 -7.526,-33.214 -1.197,-57.147 3.332,-12.598 7.599,-18.513 11.104,-20.935 -2.276,0.167 -4.579,0.653 -6.951,1.415 -5.814,1.869 -13.0822,20.559 -14.9511,42.986 -1.4951,17.942 2.6996,30.041 4.9841,33.849 2.49,0.198 4.825,0.122 7.011,-0.168 z"
            fill="url(#no-data-dog-paint76_linear)"
            id="no-data-dog-path980"
          />
          <path
            d="m 107.593,194.487 c -8.2038,-7.586 -7.526,-33.214 -1.197,-57.147 3.332,-12.598 7.599,-18.513 11.104,-20.935 -2.276,0.167 -4.579,0.653 -6.951,1.415 -5.814,1.869 -13.0822,20.559 -14.9511,42.986 -1.4951,17.942 2.6996,30.041 4.9841,33.849 2.49,0.198 4.825,0.122 7.011,-0.168 z"
            fill="url(#no-data-dog-paint77_linear)"
            id="no-data-dog-path982"
          />
          <path
            d="m 98.892,240.753 h -5.2336 c -5.1733,1.383 -15.1592,0.794 -13.7154,-12.633 1.8047,-16.784 9.7452,-34.469 16.0616,-40.966 6.3164,-6.497 13.7154,-5.053 14.2574,6.497 0.541,11.55 -0.903,29.957 -1.986,34.469 -0.866,3.609 -6.617,9.926 -9.384,12.633 z"
            fill="url(#no-data-dog-paint78_linear)"
            id="no-data-dog-path984"
          />
          <path
            d="m 101.734,225.335 c -0.372,-0.233 -0.484,-0.723 -0.251,-1.095 0.233,-0.372 0.724,-0.485 1.096,-0.252 l 3.441,2.159 c 0.372,0.233 0.485,0.723 0.251,1.095 -0.233,0.372 -0.723,0.485 -1.095,0.252 z"
            fill="url(#no-data-dog-paint79_linear)"
            id="no-data-dog-path986"
          />
          <path
            d="m 101.734,225.335 c -0.372,-0.233 -0.484,-0.723 -0.251,-1.095 0.233,-0.372 0.724,-0.485 1.096,-0.252 l 3.441,2.159 c 0.372,0.233 0.485,0.723 0.251,1.095 -0.233,0.372 -0.723,0.485 -1.095,0.252 z"
            fill="url(#no-data-dog-paint80_linear)"
            id="no-data-dog-path988"
          />
          <path
            d="m 100.801,227.494 c -0.372,-0.234 -0.485,-0.724 -0.252,-1.096 0.234,-0.372 0.724,-0.485 1.096,-0.251 l 3.442,2.158 c 0.372,0.233 0.484,0.724 0.251,1.096 -0.233,0.372 -0.724,0.484 -1.096,0.251 z"
            fill="url(#no-data-dog-paint81_linear)"
            id="no-data-dog-path990"
          />
          <path
            d="m 100.801,227.494 c -0.372,-0.234 -0.485,-0.724 -0.252,-1.096 0.234,-0.372 0.724,-0.485 1.096,-0.251 l 3.442,2.158 c 0.372,0.233 0.484,0.724 0.251,1.096 -0.233,0.372 -0.724,0.484 -1.096,0.251 z"
            fill="url(#no-data-dog-paint82_linear)"
            id="no-data-dog-path992"
          />
          <path
            d="m 103.953,183.328 c -4.5211,5.973 -12.2241,20.002 -16.43,41.541 -1.3437,6.882 -2.9907,11.31 -4.7336,14.017 -2.118,-1.94 -3.4358,-5.283 -2.846,-10.769 1.8047,-16.783 9.7453,-34.469 16.0617,-40.966 2.6544,-2.73 5.4999,-4.058 7.9479,-3.823 z"
            fill="url(#no-data-dog-paint83_linear)"
            id="no-data-dog-path994"
          />
          <path
            d="m 93.6579,240.75 h 5.2335 c 10e-5,0 0,0 0,0 0.3008,-4.633 -0.6496,-14.871 -6.8576,-18.769 -7.7601,-4.873 -11.3695,0.541 -11.9109,4.692 -0.0106,0.082 -0.025,0.167 -0.0428,0.256 -0.0492,0.396 -0.0951,0.792 -0.1377,1.188 -1.4437,13.427 8.5421,14.016 13.7155,12.633 z"
            fill="url(#no-data-dog-paint84_linear)"
            id="no-data-dog-path996"
          />
          <path
            d="m 92.0811,226.464 c 3.4449,5.377 3.6059,12.603 3.2558,15.544 -5.9863,4.411 -19.6397,5.356 -20.4799,2.31 -0.8402,-3.045 0.9452,-12.813 4.201,-17.854 3.2558,-5.041 8.7171,-6.721 13.0231,0 z"
            fill="url(#no-data-dog-paint85_linear)"
            id="no-data-dog-path998"
          />
          <path
            d="m 143.141,206.887 c 7.924,10.188 22.169,14.056 28.3,14.717 4.292,0.749 18.396,-1.699 19.528,-14.717 1.132,-13.018 -6.226,-35.659 -16.414,-44.432 -10.189,-8.773 -28.301,-9.622 -33.395,0 -5.094,9.623 -7.924,31.697 1.981,44.432 z"
            fill="url(#no-data-dog-paint86_linear)"
            id="no-data-dog-path1000"
          />
          <path
            d="m 156.934,155.591 c -3.58,2.865 -6.924,7.9 -8.244,16.331 -2.352,15.015 -1.244,34.022 9.881,46.157 5.097,2.147 9.922,3.207 12.87,3.524 4.292,0.749 18.396,-1.698 19.528,-14.716 1.132,-13.019 -6.226,-35.659 -16.414,-44.432 -4.836,-4.163 -11.455,-6.542 -17.621,-6.864 z"
            fill="url(#no-data-dog-paint87_linear)"
            id="no-data-dog-path1002"
          />
          <path
            opacity="0.7"
            d="m 177.284,179.929 -20.296,-1.115 c -1.239,-0.068 -2.22,-1.097 -2.133,-2.335 0.48,-6.835 3.125,-11.173 5.188,-13.275 0.78,-0.795 1.937,-1.027 3.012,-0.737 8.104,2.189 13.577,9.222 16.315,14.426 0.782,1.486 -0.41,3.128 -2.086,3.036 z"
            fill="url(#no-data-dog-paint88_linear)"
            id="no-data-dog-path1004"
          />
          <g opacity="0.7" id="no-data-dog-g1012">
            <path
              d="m 173.538,199.8 c 0.397,-0.072 0.778,0.19 0.851,0.587 l 1.075,5.855 c 0.073,0.397 -0.19,0.777 -0.586,0.85 -0.397,0.073 -0.778,-0.189 -0.85,-0.586 l -1.076,-5.855 c -0.073,-0.397 0.19,-0.778 0.586,-0.851 z"
              fill="url(#no-data-dog-paint89_linear)"
              id="no-data-dog-path1006"
            />
            <path
              d="m 176.258,199.8 c 0.397,-0.072 0.778,0.19 0.851,0.587 l 1.075,5.855 c 0.073,0.397 -0.19,0.777 -0.586,0.85 -0.397,0.073 -0.778,-0.189 -0.851,-0.586 l -1.075,-5.855 c -0.073,-0.397 0.19,-0.778 0.586,-0.851 z"
              fill="url(#no-data-dog-paint90_linear)"
              id="no-data-dog-path1008"
            />
            <path
              d="m 179.829,200.387 c -0.073,-0.397 -0.454,-0.659 -0.851,-0.587 -0.396,0.073 -0.659,0.454 -0.586,0.851 l 1.076,5.855 c 0.072,0.397 0.453,0.659 0.85,0.586 0.396,-0.073 0.659,-0.453 0.586,-0.85 z"
              fill="url(#no-data-dog-paint91_linear)"
              id="no-data-dog-path1010"
            />
          </g>
          <path
            d="m 159.559,241.336 c -4.291,0.476 -11.522,-1.987 -14.601,-3.278 -1.688,-2.682 -4.946,-9.416 -4.469,-14.899 0.596,-6.853 2.979,-9.684 12.515,-11.323 9.535,-1.639 25.625,2.533 31.138,5.066 5.512,2.532 8.939,10.131 5.661,13.111 -3.277,2.979 -24.88,10.727 -30.244,11.323 z"
            fill="url(#no-data-dog-paint92_linear)"
            id="no-data-dog-path1014"
          />
          <path
            opacity="0.5"
            d="m 151.073,240.161 c 2.937,0.821 6.149,1.434 8.487,1.174 1.443,-0.16 4.062,-0.838 7.183,-1.802 -0.447,-4.343 -1.4,-7.57 -1.961,-8.911 l -9.322,-1.683 c -1.152,2.936 -2.835,7.239 -4.387,11.222 z"
            fill="url(#no-data-dog-paint93_linear)"
            id="no-data-dog-path1016"
          />
          <path
            opacity="0.2"
            d="m 176.766,214.374 c -17.927,-2.529 -27.102,1.355 -29.57,3.868 l -6.621,4.081 c 0.723,-6.251 3.293,-8.916 12.431,-10.487 6.667,-1.146 16.54,0.549 23.76,2.538 z"
            fill="url(#no-data-dog-paint94_linear)"
            id="no-data-dog-path1018"
          />
          <path
            d="m 141.277,218.955 c 1.014,-0.991 2.404,-1.86 4.279,-2.352 6.258,-1.638 12.366,7.599 13.856,15.644 1.006,5.432 -1.737,7.991 -3.932,8.897 -3.889,-0.604 -8.291,-2.152 -10.52,-3.086 -1.688,-2.682 -4.946,-9.416 -4.469,-14.899 0.14,-1.612 0.379,-3.001 0.786,-4.204 z"
            fill="url(#no-data-dog-paint95_linear)"
            id="no-data-dog-path1020"
          />
          <path
            d="m 155.2,239.039 c -1.637,2.407 -13.992,4.404 -21.237,5.263 -0.917,0.109 -1.755,-0.435 -1.825,-1.356 -0.306,-4.004 0.868,-13.158 4.417,-17.372 4.143,-4.921 6.862,-8.028 12.948,-6.474 6.085,1.553 7.898,16.702 5.697,19.939 z"
            fill="url(#no-data-dog-paint96_linear)"
            id="no-data-dog-path1022"
          />
          <path
            opacity="0.4"
            d="m 152.876,221.789 c -11.885,1.255 -18.06,13.981 -20.199,22.18 -0.302,-0.238 -0.506,-0.589 -0.539,-1.023 -0.306,-4.004 0.868,-13.158 4.417,-17.372 4.143,-4.921 6.862,-8.028 12.948,-6.474 1.326,0.338 2.45,1.323 3.373,2.689 z"
            fill="url(#no-data-dog-paint97_linear)"
            id="no-data-dog-path1024"
          />
        </g>

        <defs id="no-data-dog-defs1568">
          <linearGradient
            id="no-data-dog-paint0_linear"
            x1="112.835"
            y1="208.411"
            x2="105.801"
            y2="150.727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1028" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1030" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint1_linear"
            x1="79.6033"
            y1="147.259"
            x2="89.0932"
            y2="194.864"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1033" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1035" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint2_linear"
            x1="112.55"
            y1="157.159"
            x2="112.55"
            y2="174.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#49FF87" id="no-data-dog-stop1038" />
            <stop offset="1" stopColor="#53FFF5" stopOpacity="0.71" id="no-data-dog-stop1040" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint3_linear"
            x1="122.243"
            y1="194.601"
            x2="122.243"
            y2="201.917"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#49FF87" id="no-data-dog-stop1043" />
            <stop offset="1" stopColor="#53FFF5" stopOpacity="0.71" id="no-data-dog-stop1045" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint4_linear"
            x1="122.243"
            y1="194.601"
            x2="122.243"
            y2="201.917"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#49FF87" id="no-data-dog-stop1048" />
            <stop offset="1" stopColor="#53FFF5" stopOpacity="0.71" id="no-data-dog-stop1050" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint5_linear"
            x1="122.243"
            y1="194.601"
            x2="122.243"
            y2="201.917"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#49FF87" id="no-data-dog-stop1053" />
            <stop offset="1" stopColor="#53FFF5" stopOpacity="0.71" id="no-data-dog-stop1055" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint6_linear"
            x1="96.6971"
            y1="236.207"
            x2="111.204"
            y2="206.871"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5D9EF" id="no-data-dog-stop1058" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1060" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint7_linear"
            x1="99.3732"
            y1="225.512"
            x2="100.141"
            y2="233.925"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#106EA3" id="no-data-dog-stop1063" />
            <stop offset="1" stopColor="#0F4A80" id="no-data-dog-stop1065" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint8_linear"
            x1="76.491"
            y1="204.032"
            x2="77.2828"
            y2="213.999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" id="no-data-dog-stop1068" />
            <stop offset="1" stopColor="#B6FFFB" id="no-data-dog-stop1070" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint9_linear"
            x1="95.3495"
            y1="211.227"
            x2="95.3495"
            y2="235.958"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#49FF87" id="no-data-dog-stop1073" />
            <stop offset="1" stopColor="#53FFF5" stopOpacity="0.71" id="no-data-dog-stop1075" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint10_linear"
            x1="81.9799"
            y1="217.126"
            x2="84.0863"
            y2="234.315"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1078" />
            <stop offset="1" stopColor="#0F7080" id="no-data-dog-stop1080" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint11_linear"
            x1="81.366"
            y1="217.077"
            x2="82.3723"
            y2="233.611"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1CC6C6" id="no-data-dog-stop1083" />
            <stop offset="1" stopColor="#0F7080" id="no-data-dog-stop1085" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint12_linear"
            x1="168.24"
            y1="183.963"
            x2="180.429"
            y2="127.344"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1088" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1090" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint13_linear"
            x1="78.531"
            y1="241.26"
            x2="46.6342"
            y2="221.148"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5D9EF" id="no-data-dog-stop1093" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1095" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint14_linear"
            x1="61.6445"
            y1="232.425"
            x2="98.0877"
            y2="218.214"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#95C9EA" id="no-data-dog-stop1098" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1100" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint15_linear"
            x1="52.0972"
            y1="232.917"
            x2="74.3473"
            y2="228.499"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#59708A" id="no-data-dog-stop1103" />
            <stop offset="1" stopColor="#C5ECFF" id="no-data-dog-stop1105" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint16_linear"
            x1="66.2559"
            y1="225.427"
            x2="64.1182"
            y2="241.53"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#106EA3" id="no-data-dog-stop1108" />
            <stop offset="1" stopColor="#0F4A80" id="no-data-dog-stop1110" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint17_linear"
            x1="74.7876"
            y1="182.8"
            x2="93.8392"
            y2="139.958"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1113" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1115" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint18_linear"
            x1="85.315"
            y1="185.535"
            x2="100.066"
            y2="158.26"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#111F2F" id="no-data-dog-stop1118" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1120" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint19_linear"
            x1="64.4866"
            y1="182.583"
            x2="101.013"
            y2="141.737"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#121A37" id="no-data-dog-stop1123" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1125" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint20_linear"
            x1="150.172"
            y1="225.809"
            x2="48.16"
            y2="144.18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5D9EF" id="no-data-dog-stop1128" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1130" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint21_linear"
            x1="91.1768"
            y1="212.626"
            x2="198.995"
            y2="124.101"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EAADA" id="no-data-dog-stop1133" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1135" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint22_linear"
            x1="103.123"
            y1="211.879"
            x2="114.636"
            y2="112.806"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1138" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1140" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint23_linear"
            x1="81.9251"
            y1="157.325"
            x2="143.073"
            y2="102.628"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EAADA" id="no-data-dog-stop1143" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1145" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint24_linear"
            x1="80.2199"
            y1="124.309"
            x2="121.244"
            y2="74.525"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EAADA" id="no-data-dog-stop1148" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1150" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint25_linear"
            x1="69.1246"
            y1="157.325"
            x2="108.989"
            y2="142.664"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EAADA" id="no-data-dog-stop1153" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1155" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint26_linear"
            x1="117.072"
            y1="106.604"
            x2="153.03"
            y2="91.5063"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EAADA" id="no-data-dog-stop1158" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1160" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint27_linear"
            x1="115.366"
            y1="98.5163"
            x2="135.461"
            y2="86.3024"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C0E7FF" id="no-data-dog-stop1163" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1165" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint28_linear"
            x1="82.1917"
            y1="52.0533"
            x2="69.9775"
            y2="84.0815"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1168" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1170" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint29_linear"
            x1="87.0049"
            y1="105.812"
            x2="94.9214"
            y2="59.1175"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3F496F" id="no-data-dog-stop1173" />
            <stop offset="1" stopColor="#68A0D9" id="no-data-dog-stop1175" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint30_linear"
            x1="99.3985"
            y1="51.5977"
            x2="88.3971"
            y2="92.4955"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1178" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1180" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint31_linear"
            x1="74.4166"
            y1="105.785"
            x2="89.1416"
            y2="62.8202"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#222B4C" id="no-data-dog-stop1183" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1185" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint32_linear"
            x1="87.6299"
            y1="164.633"
            x2="111.604"
            y2="123.351"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EAADA" id="no-data-dog-stop1188" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1190" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint33_linear"
            x1="74.1291"
            y1="164.308"
            x2="109.825"
            y2="151.034"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#95C9EA" id="no-data-dog-stop1193" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1195" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint34_linear"
            x1="122.363"
            y1="41.1199"
            x2="130.412"
            y2="23.2363"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AE6B1C" id="no-data-dog-stop1198" />
            <stop offset="1" stopColor="#D5B16D" id="no-data-dog-stop1200" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint35_linear"
            x1="122.783"
            y1="41.5586"
            x2="129.36"
            y2="26.8034"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7A4C16" id="no-data-dog-stop1203" />
            <stop offset="1" stopColor="#8C671E" id="no-data-dog-stop1205" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint36_linear"
            x1="75.7854"
            y1="22.2176"
            x2="82.4751"
            y2="3.78233"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AE6B1C" id="no-data-dog-stop1208" />
            <stop offset="1" stopColor="#D5B16D" id="no-data-dog-stop1210" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint37_linear"
            x1="75.1775"
            y1="22.2391"
            x2="80.7431"
            y2="7.07336"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7A4C16" id="no-data-dog-stop1213" />
            <stop offset="1" stopColor="#8C671E" id="no-data-dog-stop1215" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint38_linear"
            x1="68.4239"
            y1="71.6712"
            x2="127.558"
            y2="41.6379"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A0D7E9" id="no-data-dog-stop1218" />
            <stop offset="1" stopColor="#EDFFFF" id="no-data-dog-stop1220" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint39_linear"
            x1="118.386"
            y1="61.0953"
            x2="136.102"
            y2="49.7315"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#28224C" id="no-data-dog-stop1223" />
            <stop offset="1" stopColor="#5B5E81" id="no-data-dog-stop1225" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint40_linear"
            x1="91.8332"
            y1="56.2637"
            x2="77.5274"
            y2="86.3446"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5EC3DA" id="no-data-dog-stop1228" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1230" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint41_linear"
            x1="97.2485"
            y1="85.2559"
            x2="92.6717"
            y2="94.4151"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1233" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1235" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint42_linear"
            x1="67.1522"
            y1="132.224"
            x2="55.3245"
            y2="63.2788"
            gradientUnits="userSpaceOnUse"
          >
            <stop id="no-data-dog-stop1238" />
            <stop offset="1" stopColor="#0A3735" id="no-data-dog-stop1240" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint43_linear"
            x1="135.144"
            y1="70.0433"
            x2="127.046"
            y2="87.1868"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1088A3" id="no-data-dog-stop1243" />
            <stop offset="1" stopColor="#083F67" id="no-data-dog-stop1245" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint44_linear"
            x1="135.144"
            y1="70.0433"
            x2="127.046"
            y2="87.1868"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1088A3" id="no-data-dog-stop1248" />
            <stop offset="1" stopColor="#083F67" id="no-data-dog-stop1250" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint45_linear"
            x1="99.1157"
            y1="31.8008"
            x2="104.356"
            y2="17.8648"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AC5EF" id="no-data-dog-stop1253" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1255" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint46_linear"
            x1="62.4569"
            y1="40.8924"
            x2="80.438"
            y2="31.868"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#28224C" id="no-data-dog-stop1258" />
            <stop offset="1" stopColor="#5B5E81" id="no-data-dog-stop1260" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint47_linear"
            x1="90.7218"
            y1="28.3944"
            x2="95.5927"
            y2="14.3409"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AC5EF" id="no-data-dog-stop1263" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1265" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint48_linear"
            x1="90.3288"
            y1="45.723"
            x2="118.223"
            y2="34.0699"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DAA15E" id="no-data-dog-stop1268" />
            <stop offset="1" stopColor="#FDD2B2" id="no-data-dog-stop1270" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint49_linear"
            x1="95.1693"
            y1="56.3864"
            x2="89.0161"
            y2="71.5487"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55BADA" id="no-data-dog-stop1273" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1275" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint50_linear"
            x1="81.3273"
            y1="62.7417"
            x2="87.6234"
            y2="50.5266"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#28224C" id="no-data-dog-stop1278" />
            <stop offset="1" stopColor="#5B5E81" id="no-data-dog-stop1280" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint51_linear"
            x1="88.532"
            y1="57.7004"
            x2="93.888"
            y2="42.5182"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A0CDE9" id="no-data-dog-stop1283" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1285" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint52_linear"
            x1="89.4887"
            y1="55.341"
            x2="94.4784"
            y2="41.8025"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AC5EF" id="no-data-dog-stop1288" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1290" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint53_linear"
            x1="90.812"
            y1="48.0536"
            x2="95.8933"
            y2="38.4193"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#28224C" id="no-data-dog-stop1293" />
            <stop offset="1" stopColor="#5B5E81" id="no-data-dog-stop1295" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint54_linear"
            x1="90.812"
            y1="48.0536"
            x2="95.8933"
            y2="38.4193"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#28224C" id="no-data-dog-stop1298" />
            <stop offset="1" stopColor="#5B5E81" id="no-data-dog-stop1300" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint55_linear"
            x1="86.6727"
            y1="25.6583"
            x2="86.6727"
            y2="32.6072"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#135551" id="no-data-dog-stop1303" />
            <stop offset="1" stopColor="#053036" id="no-data-dog-stop1305" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint56_linear"
            x1="86.8351"
            y1="28.4336"
            x2="88.5529"
            y2="29.9565"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CBFFF6" id="no-data-dog-stop1308" />
            <stop offset="1" stopColor="#68E2DB" id="no-data-dog-stop1310" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint57_linear"
            x1="85.9962"
            y1="19.3738"
            x2="88.9435"
            y2="21.8523"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#135551" id="no-data-dog-stop1313" />
            <stop offset="1" stopColor="#053036" id="no-data-dog-stop1315" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint58_linear"
            x1="3.47445"
            y1="0"
            x2="3.47445"
            y2="6.9489"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#135551" id="no-data-dog-stop1318" />
            <stop offset="1" stopColor="#053036" id="no-data-dog-stop1320" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint59_linear"
            x1="-0.134541"
            y1="0.534155"
            x2="1.53251"
            y2="1.80083"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CBFFF6" id="no-data-dog-stop1323" />
            <stop offset="1" stopColor="#68E2DB" id="no-data-dog-stop1325" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint60_linear"
            x1="118.103"
            y1="32.421"
            x2="114.267"
            y2="32.09"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#135551" id="no-data-dog-stop1328" />
            <stop offset="1" stopColor="#053036" id="no-data-dog-stop1330" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint61_linear"
            x1="125.668"
            y1="65.2303"
            x2="143.44"
            y2="56.0147"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CD9149" id="no-data-dog-stop1333" />
            <stop offset="1" stopColor="#FFE2AA" id="no-data-dog-stop1335" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint62_linear"
            x1="123.834"
            y1="62.1811"
            x2="144.073"
            y2="23.8585"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CD9149" id="no-data-dog-stop1338" />
            <stop offset="1" stopColor="#FFE2AA" id="no-data-dog-stop1340" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint63_linear"
            x1="121.64"
            y1="70.0966"
            x2="132.323"
            y2="29.7907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EBAE67" id="no-data-dog-stop1343" />
            <stop offset="1" stopColor="#FFF9ED" id="no-data-dog-stop1345" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint64_linear"
            x1="56.6135"
            y1="37.2043"
            x2="50.2896"
            y2="18.2101"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CD9149" id="no-data-dog-stop1348" />
            <stop offset="1" stopColor="#FFE2AA" id="no-data-dog-stop1350" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint65_linear"
            x1="60.0518"
            y1="36.2969"
            x2="72.2428"
            y2="-5.29176"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CD9149" id="no-data-dog-stop1353" />
            <stop offset="1" stopColor="#FFE2AA" id="no-data-dog-stop1355" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint66_linear"
            x1="56.1104"
            y1="43.5031"
            x2="76.5372"
            y2="7.15173"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EBAE67" id="no-data-dog-stop1358" />
            <stop offset="1" stopColor="#FFF9ED" id="no-data-dog-stop1360" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint67_linear"
            x1="78.6465"
            y1="105.544"
            x2="77.3443"
            y2="112.28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1363" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1365" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint68_linear"
            x1="73.1086"
            y1="114.177"
            x2="74.8849"
            y2="106.966"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#222B4C" id="no-data-dog-stop1368" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1370" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint69_linear"
            x1="78.6465"
            y1="105.544"
            x2="77.3443"
            y2="112.28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1373" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1375" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint70_linear"
            x1="73.1086"
            y1="114.177"
            x2="74.8849"
            y2="106.966"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#222B4C" id="no-data-dog-stop1378" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1380" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint71_linear"
            x1="78.6465"
            y1="105.544"
            x2="77.3443"
            y2="112.28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1383" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1385" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint72_linear"
            x1="73.1086"
            y1="114.177"
            x2="74.8849"
            y2="106.966"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#222B4C" id="no-data-dog-stop1388" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1390" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint73_linear"
            x1="113.975"
            y1="185.134"
            x2="128.337"
            y2="145.622"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1393" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1395" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint74_linear"
            x1="120.576"
            y1="180.713"
            x2="134.316"
            y2="120.394"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1398" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1400" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint75_linear"
            x1="109.79"
            y1="188.651"
            x2="131.917"
            y2="139.695"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1403" />
            <stop offset="1" stopColor="#5B97D3" id="no-data-dog-stop1405" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint76_linear"
            x1="124.349"
            y1="112.678"
            x2="102.034"
            y2="161.027"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1408" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1410" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint77_linear"
            x1="104.879"
            y1="185.14"
            x2="132.932"
            y2="137.433"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#222B4C" id="no-data-dog-stop1413" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1415" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint78_linear"
            x1="103.775"
            y1="241.26"
            x2="71.8779"
            y2="221.148"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5D9EF" id="no-data-dog-stop1418" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1420" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint79_linear"
            x1="108.232"
            y1="223.586"
            x2="107.668"
            y2="227.934"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1423" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1425" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint80_linear"
            x1="103.004"
            y1="229.048"
            x2="103.782"
            y2="224.337"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#222B4C" id="no-data-dog-stop1428" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1430" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint81_linear"
            x1="108.232"
            y1="223.586"
            x2="107.668"
            y2="227.934"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#10A3A3" id="no-data-dog-stop1433" />
            <stop offset="1" stopColor="#085A67" id="no-data-dog-stop1435" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint82_linear"
            x1="103.004"
            y1="229.048"
            x2="103.782"
            y2="224.337"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#222B4C" id="no-data-dog-stop1438" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1440" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint83_linear"
            x1="86.8893"
            y1="232.425"
            x2="123.333"
            y2="218.214"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#95C9EA" id="no-data-dog-stop1443" />
            <stop offset="1" stopColor="#B2E0FD" id="no-data-dog-stop1445" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint84_linear"
            x1="77.3405"
            y1="232.917"
            x2="99.5906"
            y2="228.499"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#59708A" id="no-data-dog-stop1448" />
            <stop offset="1" stopColor="#C5ECFF" id="no-data-dog-stop1450" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint85_linear"
            x1="91.5007"
            y1="225.427"
            x2="89.363"
            y2="241.53"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#106EA3" id="no-data-dog-stop1453" />
            <stop offset="1" stopColor="#0F4A80" id="no-data-dog-stop1455" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint86_linear"
            x1="218.109"
            y1="227.543"
            x2="208.655"
            y2="169.166"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1458" />
            <stop offset="1" stopColor="#5B97D3" id="no-data-dog-stop1460" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint87_linear"
            x1="172.334"
            y1="213.602"
            x2="163.578"
            y2="156.434"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1463" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1465" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint88_linear"
            x1="154.058"
            y1="173.222"
            x2="178.916"
            y2="173.222"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AAE7E3" id="no-data-dog-stop1468" />
            <stop offset="0.237754" stopColor="#DBFBF9" id="no-data-dog-stop1470" />
            <stop offset="0.358119" stopColor="#DBFCFA" id="no-data-dog-stop1472" />
            <stop offset="0.442028" stopColor="#E9FFFE" id="no-data-dog-stop1474" />
            <stop offset="0.532106" stopColor="#D1FBF9" id="no-data-dog-stop1476" />
            <stop offset="0.733588" stopColor="#BCFAF6" id="no-data-dog-stop1478" />
            <stop offset="1" stopColor="#71E7E0" id="no-data-dog-stop1480" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint89_linear"
            x1="172.685"
            y1="204.313"
            x2="180.691"
            y2="204.313"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AAE7E3" id="no-data-dog-stop1483" />
            <stop offset="0.237754" stopColor="#DBFBF9" id="no-data-dog-stop1485" />
            <stop offset="0.358119" stopColor="#DBFCFA" id="no-data-dog-stop1487" />
            <stop offset="0.442028" stopColor="#E9FFFE" id="no-data-dog-stop1489" />
            <stop offset="0.532106" stopColor="#D1FBF9" id="no-data-dog-stop1491" />
            <stop offset="0.733588" stopColor="#BCFAF6" id="no-data-dog-stop1493" />
            <stop offset="1" stopColor="#71E7E0" id="no-data-dog-stop1495" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint90_linear"
            x1="172.685"
            y1="204.313"
            x2="180.691"
            y2="204.313"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AAE7E3" id="no-data-dog-stop1498" />
            <stop offset="0.237754" stopColor="#DBFBF9" id="no-data-dog-stop1500" />
            <stop offset="0.358119" stopColor="#DBFCFA" id="no-data-dog-stop1502" />
            <stop offset="0.442028" stopColor="#E9FFFE" id="no-data-dog-stop1504" />
            <stop offset="0.532106" stopColor="#D1FBF9" id="no-data-dog-stop1506" />
            <stop offset="0.733588" stopColor="#BCFAF6" id="no-data-dog-stop1508" />
            <stop offset="1" stopColor="#71E7E0" id="no-data-dog-stop1510" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint91_linear"
            x1="172.685"
            y1="204.313"
            x2="180.691"
            y2="204.313"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AAE7E3" id="no-data-dog-stop1513" />
            <stop offset="0.237754" stopColor="#DBFBF9" id="no-data-dog-stop1515" />
            <stop offset="0.358119" stopColor="#DBFCFA" id="no-data-dog-stop1517" />
            <stop offset="0.442028" stopColor="#E9FFFE" id="no-data-dog-stop1519" />
            <stop offset="0.532106" stopColor="#D1FBF9" id="no-data-dog-stop1521" />
            <stop offset="0.733588" stopColor="#BCFAF6" id="no-data-dog-stop1523" />
            <stop offset="1" stopColor="#71E7E0" id="no-data-dog-stop1525" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint92_linear"
            x1="151.38"
            y1="241.394"
            x2="165.886"
            y2="212.059"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5D9EF" id="no-data-dog-stop1528" />
            <stop offset="1" stopColor="#EDF8FF" id="no-data-dog-stop1530" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint93_linear"
            x1="154.057"
            y1="230.697"
            x2="154.825"
            y2="239.11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#106EA3" id="no-data-dog-stop1533" />
            <stop offset="1" stopColor="#0F4A80" id="no-data-dog-stop1535" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint94_linear"
            x1="161.136"
            y1="220.99"
            x2="160.846"
            y2="211.392"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="no-data-dog-stop1538" />
            <stop offset="1" stopColor="#4980B7" id="no-data-dog-stop1540" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint95_linear"
            x1="162.093"
            y1="231.413"
            x2="139.568"
            y2="227.57"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#59708A" id="no-data-dog-stop1543" />
            <stop offset="1" stopColor="#C5ECFF" id="no-data-dog-stop1545" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint96_linear"
            x1="136.663"
            y1="222.313"
            x2="138.77"
            y2="239.502"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#106EA3" id="no-data-dog-stop1548" />
            <stop offset="1" stopColor="#0F4A80" id="no-data-dog-stop1550" />
          </linearGradient>
          <linearGradient
            id="no-data-dog-paint97_linear"
            x1="131.426"
            y1="234.325"
            x2="152.288"
            y2="234.325"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AAE7E3" id="no-data-dog-stop1553" />
            <stop offset="0.237754" stopColor="#DBFBF9" id="no-data-dog-stop1555" />
            <stop offset="0.358119" stopColor="#DBFCFA" id="no-data-dog-stop1557" />
            <stop offset="0.442028" stopColor="#E9FFFE" id="no-data-dog-stop1559" />
            <stop offset="0.532106" stopColor="#D1FBF9" id="no-data-dog-stop1561" />
            <stop offset="0.733588" stopColor="#BCFAF6" id="no-data-dog-stop1563" />
            <stop offset="1" stopColor="#71E7E0" id="no-data-dog-stop1565" />
          </linearGradient>
        </defs>
      </svg>

      {dialog && <div className={styles.dialogWrapper}>{dialog}</div>}
    </div>
  );
}
