import { useClaimSessionMutation } from 'generated/graphql';
import { useIsGuestSession } from 'hooks/useIsAnonymousSession';
import { useWhyLabsSnackbar } from 'hooks/useWhyLabsSnackbar';
import LogRocket from 'logrocket';
import { createContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const SessionClaimContext = createContext<unknown>({});

const sessionTokenCookie = 'sessionToken';

export function SessionClaimContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [cookies, , removeCookie] = useCookies([sessionTokenCookie]);
  const sessionToken = cookies[sessionTokenCookie] || null;
  const { enqueueSnackbar } = useWhyLabsSnackbar();
  const { loading, userIsGuest } = useIsGuestSession();

  const [claimSession] = useClaimSessionMutation();

  useEffect(() => {
    if (loading || userIsGuest || !sessionToken) {
      return;
    }

    async function claim() {
      const { data, errors } = await claimSession({ variables: { sessionToken } });
      if (data?.claimSession !== true || errors) {
        LogRocket.error(`Failed to claim session ${sessionToken}`, errors);
        enqueueSnackbar({ title: 'Error while attempting to claim an anonymous session', variant: 'warning' });
      } else {
        removeCookie(sessionTokenCookie);
        enqueueSnackbar({ title: `Anonymous session imported as project ${sessionToken}` });
      }
    }

    claim();
  }, [sessionToken, claimSession, enqueueSnackbar, removeCookie, userIsGuest, loading]);

  return <SessionClaimContext.Provider value={{}}>{children}</SessionClaimContext.Provider>;
}
