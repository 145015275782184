import { createStyles } from '@mantine/core';
import { Colors } from '@whylabs/observatory-lib';
import { WhyLabsText } from 'components/design-system';
import WhyLabsLinkButton from 'components/design-system/button/WhyLabsLinkButton';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import leftSquares from 'ui/Squares-Left.svg';
import rightSquares from 'ui/Squares-Right.svg';

const useStyles = createStyles(() => ({
  rootA: {
    textDecoration: 'none',
  },
  root: {
    background: 'linear-gradient(90.04deg, #BB2500 0%, #FF8300 100%);',
    height: 44,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Colors.white,
  },
  text: {
    fontFamily: `'Baloo 2', 'Asap', sans-serif`,
    fontWeight: 1000,
    fontSize: 18,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  signUp: {
    marginLeft: 15,
  },
  signUpButton: {
    color: Colors.white,
    borderColor: Colors.white,
  },
  leftSquares: {
    height: '100%',
  },
  rightSquares: {
    height: '100%',
  },
}));

export function GuestSessionBanner(): JSX.Element | null {
  const { classes } = useStyles();
  const { sessionToken } = usePageTypeWithParams();

  const href = `/claim?sessionToken=${sessionToken}`;
  return (
    <a className={classes.rootA} href={href} target="_blank" rel="noopener noreferrer">
      <div className={classes.root}>
        <img className={classes.leftSquares} src={leftSquares} alt="decorative, futuristic squares" />
        <div className={classes.content}>
          <WhyLabsText inherit className={classes.text}>
            Save and monitor your data with a free WhyLabs account
          </WhyLabsText>
          <div className={classes.signUp}>
            <WhyLabsLinkButton
              className={classes.signUpButton}
              target="_blank"
              href={href}
              variant="outline"
              id="guest-banner-sign-up-button"
            >
              Sign up →
            </WhyLabsLinkButton>
          </div>
        </div>
        <img className={classes.rightSquares} src={rightSquares} alt="decorative, futuristic squares" />
      </div>
    </a>
  );
}
