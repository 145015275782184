import { useEffect, useMemo } from 'react';
import LogRocket from 'logrocket';
import camelCase from 'camelcase';
import { FeatureFlag, useGetUserFlagsQuery } from 'generated/graphql';
import { useRecoilState } from 'recoil';
import { differenceInHours } from 'date-fns';
import { flagsAtom, WhyLabsFlagSet } from 'atoms/flagsAtom';

// converts flat array of flag key/value pairs from our backend into a feature flag map, to mimic LD SDK (and for faster flag lookups)
const toFeatureFlagSet = (whylabsFlags: FeatureFlag[]): WhyLabsFlagSet =>
  whylabsFlags.reduce((flagMap, flag) => {
    // Convert all the flag names to camelCase for compatibility with React SDK
    // https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys
    const key = camelCase(flag.key);
    // disabling the rule for a more performant reduce
    // eslint-disable-next-line no-param-reassign
    flagMap[key] = flag.value;
    return flagMap;
  }, {} as WhyLabsFlagSet);

export const useFlags = (forceFetch?: boolean): { flags: WhyLabsFlagSet; loading: boolean } => {
  const [featureFlags, setFeatureFlags] = useRecoilState(flagsAtom);
  const expiredQuery = differenceInHours(new Date(), featureFlags?.lastUpdated ?? 0) > 24;
  const skip = forceFetch ? false : !expiredQuery;
  const { data: fetchedFlags, error, loading } = useGetUserFlagsQuery({ skip });

  useEffect(() => {
    if (fetchedFlags) {
      const whylabsFeatureFlags = toFeatureFlagSet(fetchedFlags.featureFlags ?? []);
      setFeatureFlags(() => {
        return { lastUpdated: new Date(), flags: whylabsFeatureFlags, loading };
      });
    }
  }, [fetchedFlags, loading, setFeatureFlags]);

  return useMemo(() => {
    const fallback = { flags: {}, loading: false };
    if (error) {
      LogRocket.error(`Failed to fetch feature flags from Dashbird: ${error.message}`);
      return fallback;
    }

    return featureFlags ?? { flags: {}, loading: true };
  }, [error, featureFlags]);
};
