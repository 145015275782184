import { createStyles, Skeleton } from '@mantine/core';
import { Colors, createTabBarComponents } from '@whylabs/observatory-lib';
import useTypographyStyles from 'styles/Typography';

import { useGetUserOrganizationsQuery } from 'generated/graphql';
import { WhyLabsText } from 'components/design-system';
import OrganizationSelect from './components/OrganizationSelect';
import { DashboardPageTabs, useDashboardPageTab, useDashboardPageTabs } from './useDashboardPageTabs';

const useTabHeaderStyles = createStyles({
  skeleton: {
    margin: '10px',
    opacity: 0.2,
  },
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    backgroundColor: Colors.night1,
  },
  selectDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: '10px',
    height: '100%',
  },
  title: {
    color: Colors.white,
    fontSize: 24,
    padding: ' 10px 10px 0px 10px',
  },
  label: {
    color: Colors.white,
    fontFamily: 'Asap',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    marginRight: 8,
  },
  layoutSwitch: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 8,
    paddingRight: 8,
  },
  layoutIconButton: {
    minWidth: 0,
    padding: 0,
    borderRadius: 4,
    color: Colors.brandSecondary800,
    '&:hover': {
      backgroundColor: Colors.secondaryLight1000,
      color: Colors.white,
    },
  },
  layoutIconButtonActive: {
    color: Colors.brandPrimary200,
    '&:hover': {
      color: Colors.brandPrimary100,
    },
  },
});

const { TabBarArea, TabLink, TabContent: TabContentComponent } = createTabBarComponents<DashboardPageTabs>();
export const TabContent = TabContentComponent;

export function ResourceOverviewPageTabBarArea(): JSX.Element {
  const { classes: styles, cx } = useTabHeaderStyles();
  const { classes: typography } = useTypographyStyles();
  const { data: orgsData, loading } = useGetUserOrganizationsQuery();

  const tabs = useDashboardPageTabs();
  const activeTab = useDashboardPageTab();
  return (
    <div className={styles.root}>
      {loading ? (
        <Skeleton className={styles.skeleton} width={500} height={40} />
      ) : (
        <div className={styles.selectDiv}>
          <OrganizationSelect orgsData={orgsData} />
          <WhyLabsText inherit className={cx(typography.headerLight, styles.title)}>
            Project Dashboard
          </WhyLabsText>
        </div>
      )}
      <TabBarArea activeTab={activeTab}>
        {tabs
          .filter(({ isHidden }) => !isHidden)
          .map((tab) => (
            <TabLink key={tab.value} {...tab} />
          ))}
      </TabBarArea>
    </div>
  );
}
