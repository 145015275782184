import { useIsGuestSession } from 'hooks/useIsAnonymousSession';
import { useTokensContext } from 'hooks/useTokensContext';
import { GuestSessionBanner } from './GuestSessionBanner';
import { StaleTokensBanner } from './StaleTokensBanner';

export function GlobalBanner(): JSX.Element | null {
  const { userIsGuest } = useIsGuestSession();
  const { tokensState } = useTokensContext();

  if (userIsGuest) {
    return <GuestSessionBanner />;
  }
  const staleTokenCount = tokensState?.staleTokens?.length ?? 0;
  if (staleTokenCount > 0) {
    return <StaleTokensBanner tokenCount={staleTokenCount} />;
  }
  return null;
}
