// Monitor Manager sub-tabs
export const MONITORS_TAB = 'monitors-tab';
export const ANOMALIES_FEED_TAB = 'anomalies-feed-tab';
export const MONITOR_RUNS_TAB = 'monitor-runs-tab';
export const PRESETS_TAB = 'presets-tab';
export const CONFIG_INVESTIGATOR_TAB = 'config-investigator-tab';

// Model tabs
export const MONITOR_MANAGER_TAB = 'monitor-manager-tab';
export const EXPLAINABILITY_TAB = 'explainability-tab';
export const LLM_DASHBOARDS_TAB = 'llm-dashboards-tab';
export const TRACING_TAB = 'tracing-tab';
export const SEGMENT_ANALYSIS_TAB = 'segment-analysis-tab';
export const SUMMARY_TAB = 'summary-tab';
export const PROFILES_TAB = 'profiles-tab';
export const INPUTS_TAB = 'inputs-tab';
export const OUTPUTS_TAB = 'outputs-tab';
export const PERFORMANCE_TAB = 'performance-tab';
export const SEGMENTS_TAB = 'segments-tab';
export const RESOURCE_DASHBOARDS = 'resource-dashboards-tab';
export const CONSTRAINTS_TAB = 'constraints-tab';
export const LLM_SECURE_TAB = 'llm-secure-tab';
export const TRIAL_LLM_SECURE_BUTTON = 'trial-llm-secure-button';
export const LLM_PERFORMANCE_TAB = 'llm-performance-dashboard-tab';
export const LLM_SECURITY_TAB = 'llm-security-dashboard-tab';
