export const PAGE_TEXTS = {
  pageTitle: 'Welcome to the WhyLabs Observability Platform!',
  sectionTitle: 'We need a few details to finish setting up your account:',
  organizationNameLabel: 'Organization name',
  requiredPlaceholder: 'Required',
  yourRoleLabel: 'Your role',
  pickOnePlaceholder: 'Pick one',
  pickManyPlaceholder: 'Pick as many as you like',
  pickFromOptionsPlaceholder: 'Pick from options',
  problemLabel: 'Problem',
  dataMonitoringCheckbox: 'Data monitoring',
  modelMonitoringCheckbox: 'Model monitoring',
  dataMonitoringFocusLabel: 'Data monitoring focus:',
  whatInfrastructureDoYouUseLabel: 'What infrastructure do you use?',
  modelsCountLabel: 'How many models does your company run in production?',
  modelMonitoringFocusLabel: 'Model monitoring focus:',
  continueButtonLabel: 'Continue',
  continueButtonLoadingLabel: 'Loading...',
  cancelButtonLabel: 'Cancel and log out',
  submitDisabledTooltip: 'Select at least one problem area to continue',
};

export const ROLE_OPTIONS = [
  'Data Scientist',
  'ML Engineer',
  'Data Engineer',
  'Software Engineer',
  'Product Manager',
  'Manager',
  'Other',
];

export const DATA_MONITORING_OPTIONS = [
  'Data lake',
  'Data streams',
  'SQL warehouse',
  'Feature Store',
  '3P data sources',
  'Object storage',
  'Other',
];

export const MODEL_COUNT_OPTIONS = ['None', '1-5', '6-10', '11-20', '20+', 'Unknown'];

export const MODEL_MONITORING_OPTIONS = [
  'Audio',
  'Classification',
  'Clustering',
  'LLM',
  'Regression',
  'Recommendation',
  'Tabular',
  'Vision',
  'Other',
];

export const INFRASTRUCTURE_OPTIONS = ['AWS', 'Azure', 'GCP', 'Databricks', 'Snowflake', 'On-prem', 'Hybrid'];
