import { useNavLinkHandler } from 'hooks/usePageLinkHandler';
import { usePageType } from 'pages/page-types/usePageType';
import { createTabBarComponents, Colors, Spacings } from '@whylabs/observatory-lib';
import { createStyles } from '@mantine/core';
import { useUserContext } from 'hooks/useUserContext';
import { canManageOrg } from 'utils/permissionUtils';
import { SubscriptionTier } from 'generated/graphql';
import { WhyLabsText } from 'components/design-system';
import { getSettingsTabTitle } from './utils/settingsPageUtils';

export type SettingsTabBarAreaTypes =
  | 'accessToken'
  | 'modelSettings'
  | 'notifications'
  | 'userSettings'
  | 'integrations'
  | 'billing';

const { TabBarArea, TabLink, TabContent: TabContentComponent } = createTabBarComponents<SettingsTabBarAreaTypes>();
export const TabContent = TabContentComponent;

export function useSettingsPageTab(): SettingsTabBarAreaTypes {
  const pt = usePageType();
  switch (pt) {
    case 'notifications':
    case 'notificationAction':
      return 'notifications';
    case 'accessToken':
      return 'accessToken';
    case 'modelSettings':
      return 'modelSettings';
    case 'userSettings':
      return 'userSettings';
    case 'integrationSettings':
      return 'integrations';
    case 'billingSettings':
      return 'billing';
    default:
      return 'accessToken';
  }
}
const useStyles = createStyles({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    backgroundColor: Colors.night1,
  },
  title: {
    color: Colors.white,
    fontFamily: 'Asap, sans-serif',
    fontSize: 24,
    padding: `10px ${Spacings.pageLeftPaddingLarge}px`,
  },
});

export const SETTINGS_TAB_BAR_HEIGHT = 70;

export const SettingsTabBarAreaBlank: React.FC = () => {
  const { classes: styles } = useStyles();
  return (
    <div className={styles.root}>
      <WhyLabsText inherit className={styles.title}>
        Settings
      </WhyLabsText>
    </div>
  );
};

const VALID_BILLING_TIERS: (SubscriptionTier | undefined)[] = [SubscriptionTier.Free, SubscriptionTier.Free];

const SettingsTabBarArea: React.FC = () => {
  const { classes: styles } = useStyles();
  const { getNavUrl } = useNavLinkHandler();
  const pt = usePageType();
  const activeTab = useSettingsPageTab();
  const { getCurrentUser } = useUserContext();
  const canManageTheOrg = canManageOrg(getCurrentUser());
  const shouldShowBilling =
    canManageTheOrg && VALID_BILLING_TIERS.includes(getCurrentUser()?.organization?.subscriptionTier ?? undefined);

  return (
    <div className={styles.root}>
      <WhyLabsText inherit className={styles.title}>
        {getSettingsTabTitle(pt)}
      </WhyLabsText>

      <TabBarArea activeTab={activeTab}>
        <TabLink
          value="integrations"
          to={getNavUrl({ page: 'settings', settings: { path: 'integrations' } })}
          name={getSettingsTabTitle('integrationSettings')}
        />
        {canManageTheOrg && (
          <TabLink
            value="modelSettings"
            to={getNavUrl({ page: 'settings', settings: { path: 'model-management' } })}
            name={getSettingsTabTitle('modelSettings')}
          />
        )}
        {canManageTheOrg && (
          <TabLink
            value="notifications"
            to={getNavUrl({ page: 'settings', settings: { path: 'notifications' } })}
            name={getSettingsTabTitle('notifications')}
          />
        )}
        {canManageTheOrg && (
          <TabLink
            value="userSettings"
            to={getNavUrl({ page: 'settings', settings: { path: 'user-management' } })}
            name={getSettingsTabTitle('userSettings')}
          />
        )}
        {canManageTheOrg && (
          <TabLink
            value="accessToken"
            to={getNavUrl({ page: 'settings', settings: { path: 'access-tokens' } })}
            name={getSettingsTabTitle('accessToken')}
          />
        )}
        {shouldShowBilling && (
          <TabLink
            value="billing"
            to={getNavUrl({ page: 'settings', settings: { path: 'billing' } })}
            name={getSettingsTabTitle('billingSettings')}
          />
        )}
      </TabBarArea>
    </div>
  );
};

export default SettingsTabBarArea;
