import { Center, Stack, createStyles } from '@mantine/core';
import { useUserContext } from 'hooks/useUserContext';
import { EnvironmentType, useEnvironment } from 'hooks/useEnvironment';
import { SubscriptionTier } from 'generated/graphql';
import { WhyLabsButton, WhyLabsTypography } from 'components/design-system';
import { useSemiModalPageStyles } from 'pages/semi-modal/SemiModalPageCSS';
import { WhyLabsLogo } from 'components/controls/widgets/WhyLabsLogo';
import SidebarMenuButton from 'components/sidebar/SidebarMenuButton';
import { IconX } from '@tabler/icons';
import whybot from 'ui/whybot.svg';
import checkmarkIcon from 'ui/checkmark.svg';
import rocketGraphic from 'ui/rocketGraphic.svg';
import backgroundSquares from 'ui/background-squares.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavLinkHandler } from 'hooks/usePageLinkHandler';
import { SafeLink } from '@whylabs/observatory-lib';
import { useKeyboardEventListener } from 'hooks/useKeyboardEventListener';

const useStyles = createStyles(() => ({
  pageContainer: {
    position: 'relative',
    background: '#FFFFFF',
    minHeight: '100vh',
  },
  container: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    background: '#FFFFFF',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    flexGrow: 1,
    paddingTop: '24px',
  },
  textBalooTwo: {
    fontFamily: `"Baloo 2"`,
  },
  biggerTitle: {
    fontSize: '36px',
  },
  subTitle: {
    fontSize: '20px',
  },
  botImageBackground: {
    top: '88vh',
    left: '0%',
    position: 'absolute',
    zIndex: 10,
  },
  squaresBackgroundLeft: {
    top: '60vh',
    left: '-6%',
    position: 'absolute',
  },
  squaresBackgroundRight: {
    top: '-70px',
    right: '-325px',
    position: 'absolute',
  },
  withSpace: {
    marginTop: '24px',
  },
  plansContainer: {
    alignItems: 'self-start',
    marginTop: '30px',
  },
  allPlansPricingColumn: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '10px',
    fontFamily: 'Inter',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: '80px',
    maxWidth: '220px',
    margin: '0px 36px 28px',
  },
  pricingTitle: {
    fontWeight: 600,
    fontSize: '20px',
    marginRight: 'auto',
    width: '100%',
  },
  pricingItem: {
    display: 'flex',
    gridGap: '15px',
    alignItems: 'flex-start',
    width: '100%',

    img: {
      width: '15px',
      marginTop: '2px',
    },
  },
}));

function stripeEmbed(orgId: string, envType: EnvironmentType) {
  if (orgId === '' || envType === 'unknown') {
    return undefined; // required to return undefined because of the way dangerouslySetInnerHTML works
  }

  if (envType === 'dev' || envType === 'local') {
    return {
      __html: `<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
    <stripe-pricing-table
    pricing-table-id="prctbl_1Nv81FKyRQvKp2zMuWn7AnKl"
    publishable-key="pk_test_51N5dQ0KyRQvKp2zMLIhgwKgY8RtYO3NQjVBWdcpqckNMNqeRXJuJRHMmKaSgH3VGWhd0oZ11EbGuVIKxu72dELtB00jR5k280s"
    client-reference-id="${orgId}"
    />`,
    };
  }
  return {
    __html: `<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      <stripe-pricing-table
      pricing-table-id="prctbl_1NGUC9KyRQvKp2zMgkCmoM9m"
      publishable-key="pk_live_51N5dQ0KyRQvKp2zMgsfkhv7Rt3OQNizrNyI7fUJ07GWdIjcr5Op4qqUP3CgZSvZTWGkczJ46QdWHWsJfSp5dieTJ00xlETry07"
      client-reference-id="${orgId}"
      />`,
  };
}

const listOfAllPlansBenefits = [
  'Flexible monitoring for datasets and models, including LLMs',
  'Monitoring 100% of the data, without sampling',
  'Support for structured and unstructured data',
  'Access to the full Observatory platform',
  '6+ months of data retention',
  'Privacy-preserving integration',
];

export const PaymentsPage: React.FC = () => {
  const { classes, cx } = useStyles();
  const { classes: semimodalClasses } = useSemiModalPageStyles();
  const { getCurrentUser } = useUserContext();
  const environmentType = useEnvironment();
  const user = getCurrentUser();
  const isAws = !!(
    user?.organization?.isAWSMarketplace || user?.organization?.subscriptionTier === SubscriptionTier.AwsMarketplace
  );
  const isPaid = !!(
    user?.organization?.subscriptionTier === SubscriptionTier.Paid ||
    user?.organization?.subscriptionTier === SubscriptionTier.Subscription
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { handleNavigation } = useNavLinkHandler();

  const handleGoBack = () => {
    if (location.state) {
      navigate(-1);
    } else {
      handleNavigation({ page: 'home' });
    }
  };

  useKeyboardEventListener({ keydown: { Escape: handleGoBack } });

  const renderCentered = (children: React.ReactNode) => <Center>{children}</Center>;

  const renderAwsMessage = () => (
    <Center className={classes.withSpace}>
      <Stack spacing="md">
        {renderCentered(<WhyLabsTypography order={3}>Thank you for using WhyLabs!</WhyLabsTypography>)}
        {renderCentered(
          <WhyLabsTypography order={3}>Your subscription is managed through the AWS Marketplace.</WhyLabsTypography>,
        )}
      </Stack>
    </Center>
  );

  const renderEnterpriseUpgradeText = (textClasses?: string) => (
    <WhyLabsTypography order={3} className={textClasses ?? ''}>
      <span>Do you need enterprise scale monitoring? </span>
      <span>
        <SafeLink sameTab href="contactUs">
          Contact us
        </SafeLink>
      </span>
      <span> to discuss custom pricing options.</span>
    </WhyLabsTypography>
  );

  const renderAlreadyPaidMessage = () => (
    <Center className={classes.withSpace}>
      <Stack spacing="md">
        {renderCentered(<WhyLabsTypography order={3}>You are already a subscriber.</WhyLabsTypography>)}
        {renderEnterpriseUpgradeText()}
      </Stack>
    </Center>
  );

  const renderPricingColumn = () => {
    return (
      <Center className={classes.allPlansPricingColumn}>
        <img src={rocketGraphic} alt="Rocket flying icon" />
        <WhyLabsTypography order={3} className={classes.pricingTitle}>
          All plans include:
        </WhyLabsTypography>
        {listOfAllPlansBenefits.map((benefit) => (
          <WhyLabsTypography order={6} className={classes.pricingItem}>
            <img src={checkmarkIcon} alt="Green checkmark icon" />
            {benefit}
          </WhyLabsTypography>
        ))}
      </Center>
    );
  };

  /* eslint-disable react/no-danger */
  const renderContent = () => {
    if (isAws) {
      return renderAwsMessage();
    }
    if (isPaid) {
      return renderAlreadyPaidMessage();
    }
    const orgId = user?.organization?.id;
    if (!orgId) {
      return (
        <Center>
          <WhyLabsTypography>You must be logged in to an organization to purchase a subscription</WhyLabsTypography>
        </Center>
      );
    }
    return (
      <Center className={classes.plansContainer}>
        {renderPricingColumn()}
        <div dangerouslySetInnerHTML={stripeEmbed(orgId, environmentType)} />
      </Center>
    );
  };

  const renderColoredTitle = (title: string) => (
    <WhyLabsTypography
      order={1}
      className={cx(semimodalClasses.modelTextGradient, classes.textBalooTwo, classes.biggerTitle)}
    >
      {title}
    </WhyLabsTypography>
  );

  const renderTitles = () => {
    const isAlreadyUser = isAws || isPaid;
    const title = isAlreadyUser ? 'Thank you for using WhyLabs' : 'Fixed costs for unlimited data';
    return (
      <Center pos="relative">
        <img className={classes.squaresBackgroundRight} src={backgroundSquares} alt="background decoration" />

        <Stack>
          {renderCentered(renderColoredTitle(title))}
          {!isAlreadyUser &&
            renderCentered(
              <WhyLabsTypography
                order={3}
                className={cx(semimodalClasses.sectionTitle, classes.textBalooTwo, classes.subTitle)}
              >
                Monitor your LLM, ML model, data pipeline, data stream, or dataset - any volume, any where
              </WhyLabsTypography>,
            )}
        </Stack>
      </Center>
    );
  };

  const renderFooter = () => {
    if (isAws || isPaid) {
      return null;
    }
    return (
      <Center pos="relative">
        {renderEnterpriseUpgradeText(cx(semimodalClasses.sectionTitle, classes.textBalooTwo, classes.subTitle))}
      </Center>
    );
  };

  return (
    <div className={cx(classes.pageContainer)}>
      <header className={semimodalClasses.header}>
        <div className={semimodalClasses.headerLogoContainer}>
          <SidebarMenuButton isDark />
          <WhyLabsLogo isDark />
        </div>
        <div /> {/* spacer */}
        <div className={semimodalClasses.closeButtonContainer}>
          <WhyLabsButton
            aria-label="close"
            className={semimodalClasses.closeButton}
            onClick={handleGoBack}
            variant="outline"
            id="upgrade-close-button"
          >
            <IconX size={24} />
          </WhyLabsButton>
        </div>
      </header>
      <div className={cx(classes.container)}>
        <img className={classes.botImageBackground} src={whybot} alt="background decoration" />
        <img className={classes.squaresBackgroundLeft} src={backgroundSquares} alt="background decoration" />

        {renderTitles()}
        {renderContent()}
        {renderFooter()}
      </div>
    </div>
  );
};
