import { ButtonProps, createStyles } from '@mantine/core';
import { Colors } from '@whylabs/observatory-lib';

export type ButtonWidth = 'full' | 'fit-content';

export type DefaultWhyLabsButtonProps = Pick<
  ButtonProps,
  'children' | 'className' | 'disabled' | 'leftIcon' | 'loading' | 'rightIcon' | 'size' | 'type' | 'style'
> & {
  'aria-label'?: string;
  color?: 'primary' | 'danger' | 'gray' | 'unset' | 'white';
  id?: string;
  variant: 'filled' | 'outline' | 'subtle';
  // If the component is disabled, whether to display a tooltip explaining why it's disabled
  disabledTooltip?: string;
  // A tooltip to show when the component is enabled
  enabledTooltip?: string;
  width?: ButtonWidth;
};

export const useButtonStyles = createStyles((_, width?: ButtonWidth) => ({
  root: {
    fontFamily: 'Asap',
    fontWeight: 600,
    lineHeight: '20px',
    padding: '4px 12px',
    width: width === 'full' ? '100%' : 'fit-content',
    transition: 'background 0.25s ease',
  },
  dangerOutline: {
    borderColor: Colors.red,
    color: Colors.red,
  },
  danger: {
    borderColor: Colors.red,
    background: Colors.red,
    color: Colors.white,
    '&:hover': {
      background: `${Colors.brandRed4} !important`,
    },
  },
  gray: {
    background: Colors.white,
    borderColor: '#DDDDDD',
    color: `${Colors.secondaryLight1000} !important`,
    '&:hover': {
      background: '#DDDDDD',
    },
  },
  grayOutline: {
    background: Colors.transparent,
    borderColor: Colors.grey,
    color: Colors.secondaryLight1000,
  },
  white: {
    background: Colors.white,
    borderColor: Colors.white,
    color: `${Colors.gray900} !important`,
    '&:hover': {
      background: '#DDDDDD',
    },
  },
  whiteOutline: {
    background: Colors.transparent,
    borderColor: Colors.white,
    color: Colors.white,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.15)',
    },
  },
  withoutBorder: {
    borderColor: 'transparent',
  },
}));
