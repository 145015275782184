import { dateConstructorToReadableISOString } from 'components/super-date-picker/utils';
import {
  DATE_RANGE_SEPARATOR,
  DATE_RANGE_TAG,
  LLM_PRIMARY_BATCH,
  LLM_SECONDARY_BATCH,
  NEW_GLOBAL_END_RANGE,
  NEW_GLOBAL_START_RANGE,
  TEMP_COMPARED_END_DATE_RANGE,
  TEMP_COMPARED_START_DATE_RANGE,
  TEMP_END_DATE_RANGE,
  TEMP_START_DATE_RANGE,
} from 'types/navTags';

const getReadableISOFromLLMRangeString = (batchRange: string) => {
  const [from, to] = batchRange.split(DATE_RANGE_SEPARATOR);
  const startDate = dateConstructorToReadableISOString(from, { includeHours: false });
  const endDate = dateConstructorToReadableISOString(to, { includeHours: false });
  return [startDate, endDate];
};

export const newDateRangeParamsTranslator = (
  searchParams: URLSearchParams,
  setSearchParams: (callback: (prev: URLSearchParams) => URLSearchParams) => void,
): void => {
  const rawLegacyGlobalRange = searchParams.get(DATE_RANGE_TAG);
  const llmPrimaryBatch = searchParams.get(LLM_PRIMARY_BATCH);
  const llmSecondaryBatch = searchParams.get(LLM_SECONDARY_BATCH);
  let llmStartDate: string | null = null;
  let llmEndDate: string | null = null;
  let secondaryStartDate: string | null = null;
  let secondaryEndDate: string | null = null;
  const [legacyStart, legacyEnd] = rawLegacyGlobalRange?.split(DATE_RANGE_SEPARATOR) ?? [];
  if (llmPrimaryBatch && llmPrimaryBatch.includes(DATE_RANGE_SEPARATOR)) {
    [llmStartDate, llmEndDate] = getReadableISOFromLLMRangeString(llmPrimaryBatch);
  }
  if (llmSecondaryBatch && llmSecondaryBatch.includes(DATE_RANGE_SEPARATOR)) {
    [secondaryStartDate, secondaryEndDate] = getReadableISOFromLLMRangeString(llmSecondaryBatch);
  }
  const shouldTranslateGlobalRange = !!(
    legacyStart &&
    legacyEnd &&
    (!searchParams.get(NEW_GLOBAL_START_RANGE) || !searchParams.get(NEW_GLOBAL_END_RANGE))
  );
  const shouldTranslatePrimaryRange = !!(
    (!searchParams.get(TEMP_START_DATE_RANGE) || !searchParams.get(TEMP_END_DATE_RANGE)) &&
    llmStartDate &&
    llmEndDate
  );
  const shouldTranslateSecondaryRange = !!(
    (!searchParams.get(TEMP_COMPARED_START_DATE_RANGE) || !searchParams.get(TEMP_COMPARED_END_DATE_RANGE)) &&
    secondaryStartDate &&
    secondaryEndDate
  );
  if (!shouldTranslateGlobalRange && !shouldTranslatePrimaryRange && !shouldTranslateSecondaryRange) return;
  setSearchParams((prev) => {
    if (shouldTranslateGlobalRange) {
      prev.set(NEW_GLOBAL_START_RANGE, legacyStart ?? '');
      prev.set(NEW_GLOBAL_END_RANGE, legacyEnd ?? '');
    }
    if (shouldTranslatePrimaryRange) {
      prev.set(TEMP_START_DATE_RANGE, llmStartDate ?? '');
      prev.set(TEMP_END_DATE_RANGE, llmEndDate ?? '');
    }
    if (shouldTranslateSecondaryRange) {
      prev.set(TEMP_COMPARED_START_DATE_RANGE, secondaryStartDate ?? '');
      prev.set(TEMP_COMPARED_END_DATE_RANGE, secondaryEndDate ?? '');
    }
    return prev;
  });
};
