import { useEffect, useMemo, useState } from 'react';
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { Colors } from '@whylabs/observatory-lib';
import {
  GetUserOrganizationsQuery,
  MembershipType,
  JoinedOrganizationFragment,
  useGetUserQuery,
  useSelectDefaultOrganizationMutation,
} from 'generated/graphql';
import LogRocket from 'logrocket';
import { clearHeapSession } from 'components/routes/ClearHeapSession';
import { TARGET_ORG_QUERY_NAME } from 'graphql/apollo';
import { useQueryParams } from 'utils/queryUtils';
import { SelectItem } from '@mantine/core';
import { WhyLabsSelect } from 'components/design-system';

const useSelectStyles = makeStyles(() =>
  createStyles({
    dropdown: {
      color: Colors.white,
      border: `1px solid ${Colors.brandSecondary900}`,
      borderRadius: 4,
      height: 40,
      fontSize: '14px',
      '& .MuiSelect-select': {
        padding: '10px 29px 10px 10px',
      },
    },
    select: {
      padding: 0,
      '&:focus': {
        background: 'none',
      },
    },
    selectIcon: {
      color: Colors.white,
    },
    selectItem: {
      fontSize: '14px',
      fontFamily: 'Asap',
      '&:hover': {
        backgroundColor: Colors.brandSecondary100,
      },
    },
    selectedItem: {
      background: `${Colors.brandSecondary100} !important`,
    },
    selectMenu: {
      background: Colors.white,
      borderRadius: '3px',
      border: '1px solid rgba(0, 0, 0, 0.25)',
      padding: 0,
    },
    modelDropdownLabel: {
      fontSize: '12px',
      paddingBottom: '4px',
      color: Colors.white,
    },
    loadingOverlay: {
      position: 'absolute',
      zIndex: 1000,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    organizationDropdown: {
      width: '280px',
      marginLeft: '10px',
    },
    circular: {
      position: 'relative',
      top: '50%',
      left: '50%',
    },
  }),
);

/**
 * Determines if the target org is a demo org, which requires special handling in the dropdown
 * @param orgs All orgs
 * @param targetOrgId The selected org
 */
const isDemoOrg = (orgs: JoinedOrganizationFragment[], targetOrgId: string): boolean => {
  return orgs.find((o) => o.orgId === targetOrgId)?.membershipType === MembershipType.Demo;
};
type OrganizationSelectProps = {
  orgsData?: GetUserOrganizationsQuery;
};
const OrganizationSelect: React.FC<OrganizationSelectProps> = ({ orgsData }) => {
  const { data: loggedInUserData } = useGetUserQuery();
  const [setDefaultOrganization] = useSelectDefaultOrganizationMutation();

  const { setQueryParam } = useQueryParams();

  const styles = useSelectStyles();
  const [orgLoading, setOrgLoading] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState<string>('');

  const sortedOrganizations = useMemo(
    () =>
      orgsData?.user.joinedOrganizations?.slice()?.sort((org1, org2) => {
        if (org1.membershipType === MembershipType.Demo) {
          // move demo orgs to the bottom of the list, regardless of their name
          return 1;
        }

        // sort orgs alphabetically otherwise
        return org1.name.localeCompare(org2.name);
      }) ?? [],
    [orgsData?.user.joinedOrganizations],
  );

  const hasMoreThanOneOrg = sortedOrganizations.length > 1;

  useEffect(() => {
    if (loggedInUserData) {
      const selectedOrg = sortedOrganizations.find((org) => org.orgId === loggedInUserData.user.organization?.id);
      setSelectedOrganization(selectedOrg?.orgId ?? '');
    }
  }, [loggedInUserData, sortedOrganizations]);

  const handleChange = (newOrg: string) => {
    if (selectedOrganization && newOrg) {
      setSelectedOrganization(newOrg);
      if (isDemoOrg(sortedOrganizations, newOrg)) {
        // demo org can be accessed directly by its membership type to allow the URLs to demo resources
        // to remain stable across deployment environments
        setQueryParam(TARGET_ORG_QUERY_NAME, MembershipType.Demo.toLocaleLowerCase());
        clearHeapSession();
        // for the demo org, just reload the page, no need to change default org (plus it isn't possible)
        window.location.reload();
        return;
      }

      setOrgLoading(true);
      setDefaultOrganization({ variables: { orgId: newOrg } })
        .catch((error) => LogRocket.error(error))
        .finally(() => {
          setOrgLoading(false);
          setQueryParam(TARGET_ORG_QUERY_NAME, newOrg);
          clearHeapSession();
          window.location.reload();
        });
    }
  };

  const selectItems: SelectItem[] = useMemo(
    () =>
      sortedOrganizations.map(({ name, orgId }) => {
        return {
          value: orgId,
          label: `${name} (${orgId})`,
        };
      }),
    [sortedOrganizations],
  );

  if (!hasMoreThanOneOrg) {
    return <> </>;
  }

  return (
    <div className={styles.organizationDropdown}>
      {orgLoading && (
        <div className={styles.loadingOverlay}>
          <CircularProgress className={styles.circular} size={50} />
        </div>
      )}
      <WhyLabsSelect
        darkBackground
        data={selectItems}
        label="Select organization"
        placeholder="Select an organization"
        clearable={false}
        value={selectedOrganization}
        loading={orgLoading}
        onChange={(value) => handleChange(value ?? '')}
      />
    </div>
  );
};
export default OrganizationSelect;
