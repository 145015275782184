import {
  Button,
  createStyles,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Colors } from '@whylabs/observatory-lib';
import WhyRadio from 'components/controls/widgets/WhyRadio';
import { useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import cx from 'classnames';
import LogRocket from 'logrocket';
import ExternalLink from 'components/link/ExternalLink';
import { useUserContext } from 'hooks/useUserContext';
import { useSendGraphFeedbackMutation, FeedbackCategory } from 'generated/graphql';
import { useWhyLabsSnackbar } from 'hooks/useWhyLabsSnackbar';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: 'white',
      width: '510px',
      padding: '25px',
      borderRadius: '4px',
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '12px',
    },
    title: {
      color: Colors.secondaryLight1000,
      fontFamily: 'Asap',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
    },
    text: {
      fontFamily: 'Asap',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '250px',
    },
    footerText: {
      fontFamily: 'Asap',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '20px',
    },
    formControlLabel: {
      margin: '3px 0px',
    },
    categorySection: {
      marginBottom: '12px',
    },
    tagsSection: {
      marginBottom: '12px',
    },
    textAreaSection: {
      marginBottom: '12px',
    },
    textArea: {
      width: '100%',
      resize: 'none',
      height: '80px !important',
      border: `1px solid ${Colors.brandSecondary300}`,
      fontFamily: 'Asap',
      borderRadius: '4px',
      '&:focus': {
        outline: 'none',
      },
    },
    textAreaCharCount: {
      textAlign: 'right',
      fontFamily: 'Asap',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '20px',
      color: Colors.brandSecondary600,
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    footerButton: {
      background: Colors.white,
      boxShadow: 'unset',
      marginRight: '3px',
    },
    footerControls: {
      display: 'flex',
      '& .MuiButton-contained.Mui-disabled': {
        backgroundColor: 'unset !important',
      },
    },
    autocompleteInputRoot: {
      padding: '4px !important',
    },
    autocompleteInputDropdown: {
      background: 'white',
    },
    textAreaBottomText: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    errorText: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Asap',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '20px',
      marginLeft: '3px',
      visibility: 'hidden',
      color: Colors.red,
    },
    showErrorText: {
      visibility: 'visible',
    },
    borderError: {
      borderColor: Colors.red,
    },
    footerSendButton: {
      color: Colors.chartBlue,
      fontFamily: 'Asap',
      fontStyle: 'Normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
    },
    informationalMessageContainer: {
      padding: '10px',
      display: 'flex',
      justifyContent: 'center',
      border: `1px solid ${Colors.chartBlue}`,
      background: Colors.brandSecondary100,
      marginBottom: '5px',
    },
    informationalMessage: {
      fontFamily: 'Asap',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '20px',
    },
    informationalMessageLink: {
      color: Colors.linkColor,
    },
  }),
);

interface TagOption {
  title: string;
  value: string;
}

export const feedbackModalAtom = atom({
  key: 'feedbackModal',
  default: {
    isOpen: false,
    componentName: '',
    cardType: '',
    modelId: '',
    featureId: '',
  },
});

const options: TagOption[] = [
  {
    title: 'Chart functionality',
    value: 'ChartFunctionality',
  },
  {
    title: 'Content',
    value: 'Content',
  },
  {
    title: 'Data accuracy',
    value: 'DataAccuracy',
  },
  {
    title: 'Monitor',
    value: 'Monitor',
  },
  {
    title: 'Usability',
    value: 'Usability',
  },
  {
    title: 'Visual',
    value: 'Visual',
  },
];

const TEXT_AREA_CHAR_LIMIT = 500;
const FEEDBACK_SENT_SUCCESSFULLY = 'Feedback submitted successfully';
const FEEDBACK_SENT_UNSUCCESSFULLY = 'Feedback submission failed';

export default function FeedbackForm(): JSX.Element {
  const styles = useStyles();
  const [categoryValue, setCategoryValue] = useState<FeedbackCategory | ''>('');
  const [tags, setTags] = useState<TagOption[]>([]);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [modalInfo, setModalInfo] = useRecoilState(feedbackModalAtom);
  const [categoryError, setCategoryError] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const [textAreaError, setTextAreaError] = useState(false);
  const [sendFeedback] = useSendGraphFeedbackMutation();
  const { getCurrentUser } = useUserContext();
  const user = getCurrentUser();
  const { enqueueSnackbar } = useWhyLabsSnackbar();

  function handleSendFeedback() {
    if (!categoryValue) return;
    if (tags.length < 1) return;
    if (!textAreaValue) return;
    const trackID = `${user?.whyLabsId ?? 'unknown'}--${new Date().getTime()}`;
    const feedback = {
      tags: tags.map((tag) => tag.value),
      category: categoryValue,
      component: modalInfo.componentName,
      message: textAreaValue,
      featureName: modalInfo.featureId,
      datasetId: modalInfo.modelId,
      url: window.location.href,
      trackID,
    };
    LogRocket.track('user-feedback', { [trackID]: JSON.stringify(feedback) });
    sendFeedback({
      variables: {
        feedback,
        submitAnonymously: false,
      },
    })
      .then(() => {
        enqueueSnackbar({
          title: FEEDBACK_SENT_SUCCESSFULLY,
        });
        setModalInfo({ ...modalInfo, isOpen: false });
      })
      .catch((error) => {
        LogRocket.error(`Error sending feedback form: ${error}`);
        enqueueSnackbar({
          variant: 'error',
          title: FEEDBACK_SENT_UNSUCCESSFULLY,
        });
      });
  }

  function checkForErrors() {
    let hasError = false;

    if (tags.length < 1) {
      setTagsError(true);
      hasError = true;
    }
    if (!categoryValue) {
      setCategoryError(true);
      hasError = true;
    }
    if (textAreaValue.length < 1) {
      setTextAreaError(true);
      hasError = true;
    }

    return hasError;
  }

  function generateUrgentMessage() {
    if (user === undefined) return null;

    return (
      <div className={styles.informationalMessageContainer}>
        <Typography className={styles.informationalMessage}>
          For urgent issues and requests, please create an issue in our{' '}
          <ExternalLink className={styles.informationalMessageLink} to="support">
            Support Portal
          </ExternalLink>
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Typography className={styles.title}>Send feedback</Typography>
        <Typography className={styles.text}>{modalInfo.componentName}</Typography>
      </div>
      <div className={styles.categorySection}>
        <Typography style={{ marginBottom: '5px' }}>
          <span className={styles.title}>Category </span>
          <span>(required)</span>
        </Typography>
        <RadioGroup
          name="Category"
          value={categoryValue}
          onChange={(element) => {
            const value = element.target.value as FeedbackCategory;
            setCategoryValue(value);
            if (categoryError && value) setCategoryError(false); // Removes error message
          }}
        >
          <FormControlLabel
            className={styles.formControlLabel}
            value={FeedbackCategory.Bug}
            control={<WhyRadio style={{ paddingLeft: 0 }} />}
            label={<span>Issue or bug</span>}
          />
          <FormControlLabel
            className={styles.formControlLabel}
            value={FeedbackCategory.Request}
            control={<WhyRadio style={{ paddingLeft: 0 }} />}
            label={<span>Feature request</span>}
          />
          <FormControlLabel
            className={styles.formControlLabel}
            value={FeedbackCategory.General}
            control={<WhyRadio style={{ paddingLeft: 0 }} />}
            label={<span>General suggestion</span>}
          />
        </RadioGroup>
        <span className={cx(styles.errorText, categoryError && styles.showErrorText)}>
          <ErrorOutlineIcon style={{ height: 16, width: 16, marginRight: '5px' }} />
          Category is required
        </span>
      </div>
      <div className={styles.tagsSection}>
        <Typography style={{ marginBottom: '5px' }}>
          <span className={styles.title}>Tags </span>
          <span>(required)</span>
        </Typography>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={options}
          getOptionLabel={(option) => option.title}
          onChange={(_, selectedTags) => {
            setTags(selectedTags);
            if (tagsError && selectedTags.length > 0) setTagsError(false);
          }}
          filterSelectedOptions
          classes={{
            inputRoot: styles.autocompleteInputRoot,
            paper: styles.autocompleteInputDropdown,
          }}
          renderInput={(inputParams) => (
            <TextField
              {...inputParams}
              InputLabelProps={{ shrink: false }}
              error={tagsError}
              variant="outlined"
              placeholder={tags.length >= 1 ? '' : 'Select at least one tag'}
            />
          )}
        />
        <span className={cx(styles.errorText, tagsError ? styles.showErrorText : null)}>
          <ErrorOutlineIcon style={{ height: 16, width: 16, marginRight: '5px' }} />
          You must add at least one tag
        </span>
      </div>
      <div className={styles.textAreaSection}>
        <Typography style={{ marginBottom: '5px' }}>
          <span className={styles.title}>Help us understand your feedback </span>
          <span>(required)</span>
        </Typography>
        <TextareaAutosize
          rowsMax={4}
          className={cx(styles.textArea, textAreaError && styles.borderError)}
          value={textAreaValue}
          onChange={(element) => {
            const newVal = element.target.value;
            const charLimit = newVal.length <= TEXT_AREA_CHAR_LIMIT;
            if (charLimit) setTextAreaValue(newVal);
            if (textAreaValue && newVal.length > 0) setTextAreaError(false); // Removes error message
          }}
        />
        <Typography className={styles.textAreaBottomText}>
          <span className={cx(styles.errorText, textAreaError && styles.showErrorText)}>
            <ErrorOutlineIcon style={{ height: 16, width: 16, marginRight: '5px' }} />
            You must add some feedback text
          </span>
          <span className={styles.textAreaCharCount}>
            {TEXT_AREA_CHAR_LIMIT - textAreaValue.length} characters remaining
          </span>
        </Typography>
      </div>
      {generateUrgentMessage()}
      <div className={styles.footer}>
        <Typography className={styles.footerText}>Your email address will be included with the feedback</Typography>
        <div className={styles.footerControls}>
          <Button
            className={styles.footerButton}
            variant="contained"
            onClick={() => {
              setModalInfo({ ...modalInfo, isOpen: false });
            }}
          >
            CANCEL
          </Button>
          <Button
            className={cx(styles.footerButton, styles.footerSendButton)}
            variant="contained"
            onClick={() => {
              const hasError = checkForErrors();

              if (!hasError) handleSendFeedback();
            }}
          >
            SEND
          </Button>
        </div>
      </div>
    </div>
  );
}
