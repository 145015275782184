import { atom } from 'recoil';

export type WhyLabsFlagSet = {
  [key: string]: boolean;
};

interface FlagsAtom {
  lastUpdated: Date;
  flags: WhyLabsFlagSet;
  loading: boolean;
}
export const flagsAtom = atom<FlagsAtom | null>({
  key: 'FeatureFlags',
  default: null,
});
