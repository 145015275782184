import { useUserContext } from './useUserContext';

/**
 * Find out whether the current browser session belongs to an anonymous user/session.
 */
export function useIsGuestSession(): {
  loading: boolean;
  userIsGuest: boolean;
} {
  const { getCurrentUser, loading } = useUserContext();
  const userIsGuest = getCurrentUser()?.organization?.anonymousSession?.enabled === true;
  return { loading, userIsGuest };
}
