import { createStyles } from '@mantine/core';
import React, { KeyboardEventHandler, MouseEventHandler } from 'react';

const useStyles = createStyles(() => ({
  invisibleButton: {
    border: 'none',
    background: 'transparent',
    textAlign: 'start',
    margin: 0,
    padding: 0,
    lineHeight: 1,
    cursor: 'pointer',
  },
}));

type InvisibleButtonProps = {
  onClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
  disabled?: boolean;
  className?: string;
};
export const InvisibleButton: React.FC<InvisibleButtonProps> = ({
  children,
  onClick,
  onKeyDown,
  disabled,
  className,
}) => {
  const { classes, cx } = useStyles();

  if (!onClick || disabled) {
    return <div className={cx(classes.invisibleButton, className)}>{children}</div>;
  }

  return (
    <button onKeyDown={onKeyDown} className={cx(classes.invisibleButton, className)} onClick={onClick} type="button">
      {children}
    </button>
  );
};
