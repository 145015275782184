import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import OutputDetailPanelView from './OutputDetailPanelView';

const OutputDetailPanel: React.FC = () => {
  const urlParams = usePageTypeWithParams();

  return <OutputDetailPanelView feature={urlParams.outputName} />;
};

export default OutputDetailPanel;
