import { createStyles } from '@mantine/core';
import { Tab, WhyLabsLoadingOverlay, WhyLabsControlledTabs } from 'components/design-system';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import { LLMDashboardsPath, UniversalNavigationParams, useNavLinkHandler } from 'hooks/usePageLinkHandler';
import { llmPageTypeToLabel } from 'components/controls/widgets/breadcrumbs/Breadcrumb';
import {
  TEMP_COMPARED_END_DATE_RANGE,
  TEMP_COMPARED_RANGE_PRESET,
  TEMP_COMPARED_START_DATE_RANGE,
} from 'types/navTags';
import { LLM_PERFORMANCE_TAB, LLM_SECURITY_TAB, SEGMENT_ANALYSIS_TAB } from 'constants/analyticsIds';
import { useFlags } from 'hooks/flags/useFlags';
import { CUSTOM_DASHBOARD_IN_RESOURCES } from 'constants/flags';
import { useGetCustomDashboardsByUsedOnMetadataQuery } from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { MainStackEvents } from 'hooks/useStackCustomEventListeners';
import { useCallback, useEffect } from 'react';
import { useWhyLabsSnackbar } from 'hooks/useWhyLabsSnackbar';
import { LLMSecurityDashboard } from './components/tab-contents/security/SecurityPanel';
import { DashboardTabsContextProvider } from './DashboardTabsContext';
import { LLMPerformanceDashboard } from './components/tab-contents/performance/PerformancePanel';
import { InsightsAndEventsDrawer } from './components/InsightsAndEventsDrawer';
import { ResourceCustomDashboard } from './components/tab-contents/custom/ResourceCustomDashboard';
import { SegmentAnalysisPage } from '../segment-analysis/SegmentAnalysis';
import { NewCustomDashboardButton } from './components/tab-contents/custom/NewCustomDashboardButton';

const TABS_HEIGHT = 56;
const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabsParent: {
    height: '100%',
    position: 'relative',
  },
  tabsList: {
    padding: '20px 0 0 16px',
    width: `100%`,
  },
  tabsPanel: {
    height: `calc(100% - ${TABS_HEIGHT}px)`,
    padding: 0,
    width: '100%',
  },
  loadingRoot: {
    position: 'relative',
  },
  newDashboardButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    height: TABS_HEIGHT,
    position: 'absolute',
    right: 15,
    top: 0,
  },
}));

const mapTabToNavigationPath = new Map<string, LLMDashboardsPath>([
  ['Security', 'security'],
  ['Performance', 'performance'],
  ['Custom', ':dashboardId'],
  ['Segment Analysis', 'segment-analysis'],
]);

export const LLMDashboardsView: React.FC = () => {
  const { classes } = useStyles();
  const { pageType, modelId, segment } = usePageTypeWithParams();
  const params = useParams();
  const { handleNavigation } = useNavLinkHandler();
  const { flags } = useFlags();
  const { enqueueSnackbar } = useWhyLabsSnackbar();

  const usedOn = `resource|${modelId}`;
  const {
    data,
    loading: loadingCustomDashboard,
    refetch: refetchCustomDashboards,
  } = useGetCustomDashboardsByUsedOnMetadataQuery({
    variables: { usedOn },
  });

  const dashboardDeletionEventHandler = useCallback(() => {
    enqueueSnackbar({ title: 'Dashboard deleted' });

    refetchCustomDashboards({ usedOn });

    handleNavigation({
      modelId,
      page: 'dashboards',
      segmentTags: segment,
      saveParams: [TEMP_COMPARED_START_DATE_RANGE, TEMP_COMPARED_END_DATE_RANGE, TEMP_COMPARED_RANGE_PRESET],
    });
  }, [enqueueSnackbar, handleNavigation, modelId, segment, refetchCustomDashboards, usedOn]);

  useEffect(() => {
    window.document.addEventListener(MainStackEvents.DashboardDeletion, dashboardDeletionEventHandler);
    return () => {
      window.document.removeEventListener(MainStackEvents.DashboardDeletion, dashboardDeletionEventHandler);
    };
  }, [dashboardDeletionEventHandler]);

  const customDashboardsNames = data?.customDashboards?.map((dashboard) => dashboard.displayName) ?? [];

  const activeTab = (() => {
    if (!loadingCustomDashboard && ['resourceCustomDashboard', 'segmentResourceCustomDashboard'].includes(pageType)) {
      const selectedDashboardName = params['*'];
      if (selectedDashboardName && customDashboardsNames.includes(selectedDashboardName)) return selectedDashboardName;
    }

    return llmPageTypeToLabel.get(pageType) ?? 'Security';
  })();

  const navToTab = (value: string) => {
    if (activeTab === value) return;

    const commonParams: UniversalNavigationParams = {
      modelId,
      page: 'dashboards',
      segmentTags: segment,
      saveParams: [TEMP_COMPARED_START_DATE_RANGE, TEMP_COMPARED_END_DATE_RANGE, TEMP_COMPARED_RANGE_PRESET],
    };

    // Special handler for custom dashboards
    if (customDashboardsNames.includes(value)) {
      handleNavigation({
        ...commonParams,
        // @ts-expect-error - We are using the dashboard name as dynamic key here
        dashboards: { path: value },
      });
      return;
    }

    const path = mapTabToNavigationPath.get(value);
    handleNavigation({
      ...commonParams,
      dashboards: path ? { path } : undefined,
    });
  };

  if (loadingCustomDashboard) {
    return (
      <div className={classes.loadingRoot}>
        <WhyLabsLoadingOverlay visible />
      </div>
    );
  }

  const tabs = (() => {
    let tabsList: Tab[] = [
      {
        children: <LLMSecurityDashboard />,
        label: 'Security',
        id: LLM_SECURITY_TAB,
      },
      {
        children: <LLMPerformanceDashboard />,
        label: 'Performance',
        id: LLM_PERFORMANCE_TAB,
      },
      {
        children: <SegmentAnalysisPage />,
        label: 'Segment Analysis',
        id: SEGMENT_ANALYSIS_TAB,
      },
    ];

    if (flags[CUSTOM_DASHBOARD_IN_RESOURCES] && data?.customDashboards?.length) {
      tabsList = [
        ...tabsList,
        ...data.customDashboards.map((dashboard) => ({
          children: <ResourceCustomDashboard dashboard={dashboard} />,
          label: dashboard.displayName,
          id: dashboard.id,
        })),
      ];
    }

    return tabsList;
  })();

  return (
    <DashboardTabsContextProvider>
      <div className={classes.tabsParent}>
        <WhyLabsControlledTabs
          activeTab={activeTab}
          onTabChange={navToTab}
          classNames={{
            tabsList: classes.tabsList,
            tabsPanel: classes.tabsPanel,
          }}
          tabs={tabs}
        />
        {flags[CUSTOM_DASHBOARD_IN_RESOURCES] && (
          <div className={classes.newDashboardButtonContainer}>
            <NewCustomDashboardButton usedOn={usedOn} />
          </div>
        )}
      </div>
      <InsightsAndEventsDrawer />
    </DashboardTabsContextProvider>
  );
};
