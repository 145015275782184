import {
  convertReadableParamToDate,
  CUSTOM_RANGE,
  CustomRangeSearchParams,
  handleURLSearchParamUpdate,
  isInvalidDateRange,
} from 'components/super-date-picker/utils';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  defaultWindowSize,
  mapPresetGranularityToTimePeriod,
  mapTimePeriodToPresetGranularity,
  PRESET_SEPARATOR,
  RELATIVE_PRESET_TOKEN,
  useDynamicTrailingRangePresets,
} from 'hooks/useDynamicTrailingRangePresets';
import { parseDateWithFallback } from 'utils/dateUtils';
import { TimePeriod } from 'generated/graphql';
import {
  NEW_GLOBAL_END_RANGE,
  NEW_GLOBAL_START_RANGE,
  NEW_GLOBAL_RANGE_PRESET,
  TEMP_START_DATE_RANGE,
  TEMP_END_DATE_RANGE,
  TEMP_RANGE_PRESET,
} from 'types/navTags';

type GlobalDateRangeProps = {
  timePeriod?: TimePeriod;
  loading?: boolean;
} & CustomRangeSearchParams;

type DateRangeParamsReturnType = {
  startTimestamp?: number;
  endTimestamp?: number;
  rawStartTimestamp?: number;
  rawEndTimestamp?: number;
  appliedPreset: string;
  setRangeParams: ({
    start,
    end,
    presetWindow,
  }: {
    start?: string | null;
    end?: string | null;
    presetWindow?: string;
  }) => void;
  loading?: boolean;
  isUsingFallbackRange: boolean;
};
/**
 * This hook should be used only inside the date picker component.
 * To get and manipulate the picker externally use useSuperGlobalDateRange.
 */
export const useDateRangeParams = ({
  startDateSearchParamKey = NEW_GLOBAL_START_RANGE,
  endDateSearchParamKey = NEW_GLOBAL_END_RANGE,
  presetSizeSearchParamKey = NEW_GLOBAL_RANGE_PRESET,
  timePeriod = TimePeriod.P1D,
  loading,
}: GlobalDateRangeProps = {}): DateRangeParamsReturnType => {
  const isGlobalPicker =
    startDateSearchParamKey === NEW_GLOBAL_START_RANGE && endDateSearchParamKey === NEW_GLOBAL_END_RANGE;
  const [searchParams, setSearchParams] = useSearchParams();
  const rawStartSearchParam = searchParams.get(startDateSearchParamKey);
  const rawEndSearchParam = searchParams.get(endDateSearchParamKey);
  const startDateFromISOSearchParam = useMemo(
    () => convertReadableParamToDate(rawStartSearchParam, 'start'),
    [rawStartSearchParam],
  );
  const endDateFromISOSearchParam = useMemo(
    () => convertReadableParamToDate(rawEndSearchParam, 'end'),
    [rawEndSearchParam],
  );
  const rawPresetSearchParam = searchParams.get(presetSizeSearchParamKey);
  const relativePresetWindowSize = (() => {
    const separatorIndex = rawPresetSearchParam?.lastIndexOf(PRESET_SEPARATOR) ?? -1;
    const possibleWindowSize = rawPresetSearchParam?.substring(separatorIndex + 1);
    if (Number(possibleWindowSize) > 0) return Number(possibleWindowSize);
    return undefined;
  })();
  const relativePresetBatchFrequency = (() => {
    // to handle cases when we navigate from different date picker granularity with relative presets
    if (!relativePresetWindowSize || !rawPresetSearchParam) return undefined;
    const separator = rawPresetSearchParam.indexOf(PRESET_SEPARATOR);
    const granularityToken = rawPresetSearchParam.substring(0, separator);
    return mapPresetGranularityToTimePeriod.get(granularityToken);
  })();
  const { getTrailingWindow } = useDynamicTrailingRangePresets(relativePresetBatchFrequency ?? timePeriod);
  const fallbackWindowSize = defaultWindowSize.get(timePeriod);
  const [startFallback, endFallback] = getTrailingWindow(relativePresetWindowSize ?? fallbackWindowSize);

  const isInvalidRange = (() =>
    startDateFromISOSearchParam && endDateFromISOSearchParam
      ? isInvalidDateRange(startDateFromISOSearchParam.getTime(), endDateFromISOSearchParam.getTime())
      : false)();

  const parsedEnd = useMemo(() => {
    if (loading) return undefined;
    if (isInvalidRange) {
      return endFallback;
    }
    return parseDateWithFallback(endDateFromISOSearchParam?.toISOString() ?? null, endFallback);
  }, [endDateFromISOSearchParam, endFallback, isInvalidRange, loading]);

  const parsedStart = useMemo(() => {
    if (loading) return undefined;
    if (isInvalidRange) {
      return startFallback;
    }
    return parseDateWithFallback(startDateFromISOSearchParam?.toISOString() ?? null, startFallback);
  }, [isInvalidRange, loading, startDateFromISOSearchParam, startFallback]);

  const setRangeParams = useCallback(
    ({
      start,
      end,
      presetWindow = CUSTOM_RANGE,
    }: {
      start?: string | null;
      end?: string | null;
      presetWindow?: string;
    }) => {
      setSearchParams((params) => {
        handleURLSearchParamUpdate(params, startDateSearchParamKey, start);
        handleURLSearchParamUpdate(params, endDateSearchParamKey, end);
        handleURLSearchParamUpdate(params, presetSizeSearchParamKey, presetWindow);
        if (isGlobalPicker && (params.get(TEMP_START_DATE_RANGE) || params.get(TEMP_END_DATE_RANGE))) {
          // Sync temporary pickers on global picker changes
          handleURLSearchParamUpdate(params, TEMP_START_DATE_RANGE, start);
          handleURLSearchParamUpdate(params, TEMP_END_DATE_RANGE, end);
          handleURLSearchParamUpdate(params, TEMP_RANGE_PRESET, presetWindow);
        }
        return params;
      });
    },
    [endDateSearchParamKey, isGlobalPicker, presetSizeSearchParamKey, setSearchParams, startDateSearchParamKey],
  );

  const appliedPreset = useMemo(() => {
    if (rawPresetSearchParam) return rawPresetSearchParam;
    const defaultRelativeWindow = fallbackWindowSize?.toString();
    const defaultPresetGranularityToken = mapTimePeriodToPresetGranularity.get(timePeriod);
    if ((!rawStartSearchParam || !rawEndSearchParam) && defaultRelativeWindow && defaultPresetGranularityToken)
      return `${defaultPresetGranularityToken}${RELATIVE_PRESET_TOKEN}${defaultRelativeWindow}`;
    return CUSTOM_RANGE;
  }, [fallbackWindowSize, rawEndSearchParam, rawPresetSearchParam, rawStartSearchParam, timePeriod]);

  const isUsingFallbackRange = !(rawStartSearchParam || rawEndSearchParam) && !rawPresetSearchParam;

  return {
    startTimestamp: parsedStart?.getTime(),
    endTimestamp: parsedEnd?.getTime(),
    isUsingFallbackRange,
    setRangeParams,
    loading,
    appliedPreset,
  };
};
