import { createStyles, TextInput, TextInputProps } from '@mantine/core';
import { useRef, useState } from 'react';
import { IconX, IconSearch } from '@tabler/icons';
import { Colors } from '@whylabs/observatory-lib';
import { isString } from 'utils/typeGuards';
import { TooltipWrapper } from '../tooltip/TooltipWrapper';
import { GenericInputProps } from '../types/designSystemTypes';

export type WhyLabsSearchInputProps = Pick<TextInputProps, 'disabled' | 'placeholder' | 'value' | 'defaultValue'> &
  GenericInputProps & {
    onChange?: (value: string) => void;
    variant?: 'borderless' | 'default';
  };

const useSearchStyles = createStyles((_, variant: WhyLabsSearchInputProps['variant']) => ({
  input:
    variant === 'borderless'
      ? {
          borderBottom: `1px solid ${Colors.brandSecondary700}`,
          '&:focus': {
            borderBottom: `2px solid ${Colors.brandPrimary700}`,
          },
        }
      : {},
  clearButton: {
    cursor: 'pointer',
  },
}));

const WhyLabsSearchInput = ({
  disabledTooltip,
  hideLabel,
  label,
  onChange,
  tooltipProps,
  defaultValue,
  variant = 'default',
  ...rest
}: WhyLabsSearchInputProps): JSX.Element => {
  const {
    classes: { input, clearButton },
  } = useSearchStyles(variant);
  const ref = useRef<HTMLInputElement>(null);
  const [hasData, setHasData] = useState(!!defaultValue);

  return (
    <TooltipWrapper {...tooltipProps} displayTooltip={rest.disabled} label={disabledTooltip}>
      <TextInput
        data-testid="WhyLabsSearchInput"
        {...rest}
        aria-label={hideLabel && isString(label) ? label : undefined}
        label={hideLabel ? undefined : label}
        onChange={({ target }) => handleOnChange(target.value)}
        variant={variant === 'borderless' ? 'unstyled' : 'default'}
        size="sm"
        ref={ref}
        icon={<IconSearch size={18} stroke={3} color={Colors.brandSecondary700} />}
        defaultValue={defaultValue}
        rightSection={
          hasData && (
            <IconX
              color={Colors.brandSecondary700}
              size={18}
              className={clearButton}
              onClick={() => {
                if (ref.current?.value) ref.current.value = '';
                handleOnChange('');
              }}
            />
          )
        }
        classNames={{ input }}
      />
    </TooltipWrapper>
  );

  function handleOnChange(value: string) {
    if (hasData !== !!value) {
      setHasData(!!value);
    }
    onChange?.(value);
  }
};

export default WhyLabsSearchInput;
