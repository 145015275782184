import { ApolloError } from '@apollo/client';
import { Spacings } from '@whylabs/observatory-lib';
import { OverviewAnomalyCountWidget, OverviewAnomalyGraphContainer } from 'components/controls/widgets';
import { HeaderEmptyFillWidget } from 'components/controls/widgets/HeaderEmptyFillWidget';
import ResourcesCountWidget from 'components/controls/widgets/ResourcesCountWidget';
import { ModelsNotConfiguredWidget } from 'components/controls/widgets/ModelsNotConfiguredWidget';
import { AssetCategory } from 'generated/graphql';
import { LayoutToggleWidget } from 'pages/resource-overview-page/components/LayoutToggleWidget';
import { useMemo } from 'react';
import { createStyles } from '@mantine/core';
import { ResourceOverviewData } from 'pages/resource-overview-page/layoutHelpers';

const useStyles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: `calc(100% - ${Spacings.leftColumnWidth}px)`,
  },
  widgetRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
  },
});

interface OverviewHeaderPanelProps {
  isOverSubscriptionLimit: boolean;
  loading: boolean;
  error: ApolloError | undefined;
  addResource: () => void;
  modelsHaveBeenConfigured: boolean;
  userCanManageDatasets: boolean;
  hasLayoutToggle: boolean;
  filteredData: ResourceOverviewData[];
}

const OverviewHeaderPanel = ({
  addResource,
  loading,
  modelsHaveBeenConfigured,
  hasLayoutToggle,
  filteredData,
  ...rest
}: OverviewHeaderPanelProps): JSX.Element => {
  const { classes: styles } = useStyles();
  const resourceCounts = useMemo(() => {
    const datasetsCount = filteredData?.filter((r) => r.assetCategory === AssetCategory.Data)?.length ?? 0;
    const modelsCount = (filteredData?.length ?? 0) - datasetsCount;
    return { modelsCount, datasetsCount };
  }, [filteredData]);

  return (
    <div className={styles.root}>
      <div className={styles.widgetRow}>
        <ResourcesCountWidget
          loading={loading}
          modelsCount={resourceCounts.modelsCount}
          datasetsCount={resourceCounts.datasetsCount}
          addResource={addResource}
          {...rest}
        />
        {!loading && !modelsHaveBeenConfigured && <ModelsNotConfiguredWidget />}
        {!loading && modelsHaveBeenConfigured && <OverviewAnomalyCountWidget />}
        {modelsHaveBeenConfigured ? <OverviewAnomalyGraphContainer /> : <HeaderEmptyFillWidget />}
      </div>
      {hasLayoutToggle && (
        <div>
          <LayoutToggleWidget useLightStyles />
        </div>
      )}
    </div>
  );
};

export default OverviewHeaderPanel;
