import { UnifiedHistogramWithMetadata } from './types';

type VisAndOpacity = {
  visibility: boolean;
  opacity: number;
};

type ProfileVisibilityState = {
  totalProfiles: number;
  totalVisible: number;
  p1: VisAndOpacity;
  p2: VisAndOpacity;
  p3: VisAndOpacity;
};

type ProfileVisibilityAction = {
  profileNum: number;
};

export function toPvsKey(profileNum: number): keyof ProfileVisibilityState | null {
  if (profileNum < 1 || profileNum > 3) {
    return null;
  }
  return `p${profileNum}` as keyof ProfileVisibilityState;
}

export function profileVisibilityReducer(
  state: ProfileVisibilityState,
  action: ProfileVisibilityAction,
): ProfileVisibilityState {
  const profileKey = toPvsKey(action.profileNum);
  if (!profileKey || profileKey === 'totalProfiles' || profileKey === 'totalVisible') {
    return state;
  }
  const originalVisibility = state[profileKey].visibility;
  const updatedVisibilityCount = originalVisibility ? state.totalVisible - 1 : state.totalVisible + 1;
  const updatedVisibilityState = {
    ...state,
    totalVisible: updatedVisibilityCount,
    [profileKey]: { visibility: !originalVisibility, opacity: state[profileKey].opacity },
  };

  if (updatedVisibilityCount === 0) {
    // This will indicate that all of them are invisible.
    return updatedVisibilityState;
  }

  switch (state.totalProfiles) {
    case 1:
      return {
        ...updatedVisibilityState,
        p1: {
          visibility: !originalVisibility,
          opacity: 1,
        },
      };
    case 2:
      if (updatedVisibilityCount === 1) {
        return {
          ...updatedVisibilityState,
          p1: {
            visibility: updatedVisibilityState.p1.visibility,
            opacity: updatedVisibilityState.p1.visibility ? 1 : 0,
          },
          p2: {
            visibility: updatedVisibilityState.p2.visibility,
            opacity: updatedVisibilityState.p2.visibility ? 1 : 0,
          },
        };
      }
      return {
        ...updatedVisibilityState,
        p1: {
          visibility: true,
          opacity: 0.85,
        },
        p2: {
          visibility: true,
          opacity: 0.6,
        },
      };
    case 3:
      if (updatedVisibilityCount === 1) {
        return {
          ...updatedVisibilityState,
          p1: {
            visibility: updatedVisibilityState.p1.visibility,
            opacity: updatedVisibilityState.p1.visibility ? 1 : 0,
          },
          p2: {
            visibility: updatedVisibilityState.p2.visibility,
            opacity: updatedVisibilityState.p2.visibility ? 1 : 0,
          },
          p3: {
            visibility: updatedVisibilityState.p3.visibility,
            opacity: updatedVisibilityState.p3.visibility ? 1 : 0,
          },
        };
      }
      if (updatedVisibilityCount === 2) {
        let p2Opacity = updatedVisibilityState.p2.opacity;
        if (updatedVisibilityState.p2.visibility) {
          p2Opacity = updatedVisibilityState.p1.visibility ? 0.6 : 0.85;
        } else {
          p2Opacity = 0;
        }
        return {
          ...updatedVisibilityState,
          p1: {
            visibility: updatedVisibilityState.p1.visibility,
            opacity: updatedVisibilityState.p1.visibility ? 0.85 : 0,
          },
          p2: {
            visibility: updatedVisibilityState.p2.visibility,
            opacity: p2Opacity,
          },
          p3: {
            visibility: updatedVisibilityState.p3.visibility,
            opacity: updatedVisibilityState.p3.visibility ? 0.5 : 0,
          },
        };
      }
      // updatedVisibilityCount === 3
      return {
        ...updatedVisibilityState,
        p1: {
          visibility: true,
          opacity: 0.7,
        },
        p2: {
          visibility: true,
          opacity: 0.5,
        },
        p3: {
          visibility: true,
          opacity: 0.3,
        },
      };

    default:
      return updatedVisibilityState;
  }
}

export function getOpacityByProfileCountAndProfileNumber(profileCount: number, profileNum: number): number {
  switch (profileCount) {
    case 1:
      return 1;
    case 2:
      // Note that this does not cover all cases
      return profileNum === 1 ? 0.85 : 0.6;
    case 3:
      if (profileNum === 1) {
        return 0.7;
      }
      if (profileNum === 2) {
        return 0.5;
      }
      // profileNum === 3
      return 0.3;

    default:
      return 1;
  }
}

export function generateProfileVisibilityState(histograms: UnifiedHistogramWithMetadata[]): ProfileVisibilityState {
  return histograms.reduce(
    (acc, histogram) => {
      const profileKey = toPvsKey(histogram.profileNum);
      if (!profileKey) {
        return acc;
      }
      return {
        ...acc,
        [profileKey]: {
          visibility: true,
          opacity: getOpacityByProfileCountAndProfileNumber(histograms.length, histogram.profileNum),
        },
      };
    },
    {
      totalProfiles: histograms.length,
      totalVisible: histograms.length,
      p1: { visibility: false, opacity: 1 },
      p2: { visibility: false, opacity: 1 },
      p3: { visibility: false, opacity: 1 },
    },
  );
}

export function getValidOpacityForPvsKey(
  key: keyof ProfileVisibilityState | null,
  state: ProfileVisibilityState,
): number {
  if (!key || key === 'totalProfiles' || key === 'totalVisible') {
    return 0;
  }
  return state[key].opacity;
}
