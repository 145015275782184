import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeat from 'highcharts/modules/heatmap';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsMore from 'highcharts/highcharts-more';
import { createStyles } from '@mantine/core';
import { WhyLabsLoadingOverlay } from '../design-system';

HighchartsMore(Highcharts);
HighchartsHeat(Highcharts);
HighchartsAccessibility(Highcharts);

export const useStyles = createStyles(() => ({
  root: {
    position: 'relative',
  },
  chart: {
    fontFamily: 'Asap',

    '& .echLegendList': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .echLegendItem__label': {
      fontSize: 12,
    },
  },
}));

export type HighchartsHeatmapWrapperProps = {
  isLoading?: boolean;
  options: Highcharts.Options;
};

export const HighchartsHeatMapWrapper = ({ isLoading, options }: HighchartsHeatmapWrapperProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <WhyLabsLoadingOverlay visible={!!isLoading} />
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...options, credits: { enabled: false } }}
        updateArgs={[true, true, false]}
      />
    </div>
  );
};
