import { useEffect, useState } from 'react';
import {
  Chip,
  Tooltip,
  withStyles,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import { Colors } from '@whylabs/observatory-lib';
import { createStyles } from '@mantine/core';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { TableFeatureDataType } from 'components/controls/table/profiles-table/types';
import { useUtilityStyles } from 'styles/UtilityStyles';
import { WhyLabsDrawer, WhyLabsText } from 'components/design-system';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import FeaturePanelHistogramStack from './FeaturePanelHistogramStack';
import FeaturePanelChart, { ChartSingleDatum } from './FeaturePanelChart';
import FeaturePanelBoxPlots from './FeaturePanelBoxPlots';
import CustomTooltip from './CustomTooltip';
import { useUnifiedBinsTools } from './useUnifiedBinsTools';

const useContentAreaStyles = createStyles({
  root: {
    background: Colors.white,
    padding: '10px 0',
    overflow: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    zIndex: 1,
  },
  title: {
    fontFamily: 'Asap, sans-serif',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  content: {
    marginVertical: '20px',
  },
  closeBox: {
    width: '33px',
    minWidth: '33px',
    height: '33px',
    padding: 0,
    backgroundColor: Colors.white,
    color: Colors.brandSecondary700,
  },
  chartContainer: {
    marginBottom: '30px',
  },
  boxplotsContainer: {
    height: 300,
  },
  frequentItemsContainer: {
    marginTop: '20px',
  },
  chartLegend: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendBox: {
    height: '11px',
    width: '11px',
    marginRight: '5px',
  },
  sectionTitle: {
    fontFamily: 'Asap, sans-serif',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  chipStyle: {
    fontWeight: 'bold',
    marginRight: '5px',
    marginBottom: '5px',
    '& span': {
      padding: '5px 15px',
      fontFamily: 'Inconsolata',
    },
    '&:hover': {
      backgroundColor: Colors.brandSecondary100,
    },
  },
  chartTitle: {
    marginBottom: '15px',
    fontFamily: 'Asap, sans-serif',
    fontSize: '14px',
    color: Colors.black,
  },
  chipContainer: {
    position: 'relative',
  },
  chipTooltipContainer: {
    position: 'absolute',
  },
  tableContainer: {},
  tableHeaderCell: {
    fontSize: '13px',
    fontFamily: 'Inconsolata',
    fontWeight: 'bold',
  },
  tableBodyCell: {
    fontSize: '13px',
    fontFamily: 'Inconsolata',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: Colors.brandSecondary100,
    },
  },
  tableCell: {
    borderBottom: 'none',
  },
  toggleChipDataLink: {
    cursor: 'pointer',
    color: Colors.linkColor,
    fontSize: '14px',
    fontFamily: 'Asap, Roboto, sans-serif',
    textDecoration: 'underline',
  },
  loader: {
    opacity: 0,
    transition: 'opacity 400ms 200ms',
    marginLeft: 8,
  },
  loaderOn: {
    transition: 'opacity 400ms',
    opacity: 1,
  },
  titleWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface ProfilesFeaturePanelProps {
  visible: boolean;
  content: TableFeatureDataType[];
  onCloseSidePanel: () => void;
}

interface IChartData {
  [key: string]: ChartSingleDatum;
}

interface IChipTableData {
  columns: string[];
  rows: {
    [key: string]: { [key: string]: string | number };
  };
}
interface ILoader {
  chart: boolean;
  chipTable: boolean;
}

export default function ProfilesFeaturePanel({
  visible,
  onCloseSidePanel,
  content,
}: ProfilesFeaturePanelProps): JSX.Element | null {
  const { classes: styles, cx } = useContentAreaStyles();
  const { classes: utilityStyles } = useUtilityStyles();

  const {
    amountOfBins,
    setAmountOfBins,
    setData,
    unifiedBinsLoading,
    unifiedCommonYRange,
    commonXDomain,
    unifiedHistograms,
  } = useUnifiedBinsTools();

  useDeepCompareEffect(() => {
    setData(content);
  }, [content]);

  const [loading, setLoading] = useState<ILoader>({
    chart: false,
    chipTable: false,
  });
  const [discreteChartData, setDiscreteChartData] = useState<ChartSingleDatum[]>([]);

  const [chipTableData, setChipTableData] = useState<IChipTableData>({
    columns: [],
    rows: {},
  });
  const [chipTableType, setChipTableType] = useState<'Frequent' | 'Histogram'>('Frequent');
  const [hasBoxPlotData, setHasBoxPlotData] = useState(false);

  const UnstyledTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: 'unset',
      padding: 'unset',
      fontSize: '14px',
      fontFamily: 'Inconsolata',
    },
  }))(Tooltip);

  function isConvertableToNumber(value: string): boolean {
    return parseFloat(value).toString() === value;
  }

  useEffect(() => {
    const counts = content.reduce((arr: number[][], item) => {
      if (item && item.numberSummary?.quantiles && item.numberSummary.quantiles.counts.length > 0)
        arr.push(item.numberSummary.quantiles.counts);
      return arr;
    }, []);

    setHasBoxPlotData(counts.length > 0);
  }, [content]);

  function formatDecimalNumbers(toFormat: number): number {
    const isFloat = Number(toFormat) === toFormat && toFormat % 1 !== 0;

    if (isFloat) return parseFloat(toFormat.toFixed(3));

    return toFormat;
  }

  /**
   * Used for generating chartData.
   */
  useEffect(() => {
    const generateChartData = () => {
      const tempDiscreteChartData: IChartData = {};

      content.forEach((_, profileIndex: number) => {
        const featureData = content[profileIndex]; // featureData for each profile
        if (!featureData) return;

        if (featureData['inferred-discretion'].toLowerCase() === 'discrete') {
          // Structures frequent items for discrete profile
          featureData.featurePanelData.frequentItems.forEach((item) => {
            if (item.value) {
              if (typeof tempDiscreteChartData[item.value] === 'undefined') {
                tempDiscreteChartData[item.value] = {
                  name: isConvertableToNumber(item.value) ? formatDecimalNumbers(parseFloat(item.value)) : item.value,
                };
              }

              tempDiscreteChartData[item.value] = {
                ...tempDiscreteChartData[item.value],
                [`profile-${profileIndex + 1}`]: item.estimate || '',
              };
            }
          });
        }
      });

      const tempDiscreteChartDataVal: ChartSingleDatum[] = Object.values(tempDiscreteChartData);

      setDiscreteChartData(tempDiscreteChartDataVal);
      setLoading((prevState) => ({ ...prevState, chart: false }));
    };
    setLoading((prevState) => ({ ...prevState, chart: true }));
    setTimeout(generateChartData, 0);
    // We are just referencing the `content`, and we do not manipulate with its data
    // because of that we do not want this hook to depend on it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, setLoading]);

  /**
   * Used for creating chipTableData
   */
  useEffect(() => {
    if (!content) return;

    setLoading((prevState) => ({ ...prevState, chipTable: true }));
    setTimeout(() => {
      if (chipTableType === 'Frequent') {
        const tempChipTableData: IChipTableData = {
          columns: [],
          rows: {},
        };

        content.forEach((profile, i) => {
          if (!profile) return;
          tempChipTableData.columns.push(`Profile ${i + 1}`);

          profile.frequentItemsRaw.forEach((item) => {
            if (!item.value?.toString()) return;

            tempChipTableData.rows[item.value] = { value: item.value };
          });
        });

        content.forEach((profile, i) => {
          if (!profile) return;

          Object.keys(tempChipTableData.rows).forEach((key) => {
            const item = profile.frequentItemsRaw.find((entry) => entry.value === key);
            if (item && item.value) tempChipTableData.rows[key][`profile-${i + 1}`] = item.estimate as number;
            else tempChipTableData.rows[key][`profile-${i + 1}`] = '-';
          });
        });

        setChipTableData(tempChipTableData);
      } else if (chipTableType === 'Histogram') {
        const tempChipTableData: IChipTableData = {
          columns: [],
          rows: {},
        };
        unifiedHistograms?.forEach((histogram) => {
          if (!histogram?.data) return;
          tempChipTableData.columns.push(`Profile ${histogram.profileNum}`);
          histogram.data.bins.forEach((bin, i) => {
            if (i >= histogram.data!.counts.length) return;
            const indexKey = i.toString();
            if (!(indexKey in tempChipTableData.rows)) {
              tempChipTableData.rows[indexKey] = { value: bin };
            }
            tempChipTableData.rows[indexKey][`profile-${histogram.profileNum}`] = histogram.data!.counts[i];
          });
        });
        setChipTableData(tempChipTableData);
      }
      setLoading((prevState) => ({ ...prevState, chipTable: false }));
    }, 0);
  }, [content, chipTableType, unifiedHistograms]);

  function generateChipValue(value: string): string {
    if (isConvertableToNumber(value)) {
      const toFormat = parseFloat(value);
      if (toFormat % 1 === 0) {
        return toFormat.toFixed(0);
      }
      return toFormat.toFixed(2);
    }
    return value;
  }

  function formatDataForTooltip(row: { [key: string]: string | number }) {
    const tempRow = { ...row };
    delete tempRow.value;

    Object.keys(tempRow).forEach((key) => {
      if (tempRow[key] === '-') delete tempRow[key];
    });

    return Object.keys(tempRow).map((key) => {
      const [profile, count] = key.split('-');

      return {
        label: `${profile} ${count}`,
        color: Colors.profilesColorPool[parseInt(count, 10) - 1],
      };
    });
  }

  /**
   *  Controls chart order
   */
  function generateGraphs() {
    const showFrequentItemsChartFirst = discreteChartData.length > 0 && discreteChartData[0]['profile-1'];
    const shouldRenderHistograms =
      content.filter((_, ind) => content?.[ind]?.['inferred-discretion']?.toLowerCase() === 'non-discrete').length > 0;

    return (
      <>
        {!showFrequentItemsChartFirst && shouldRenderHistograms && (
          <FeaturePanelHistogramStack
            amountOfBins={amountOfBins}
            setAmountOfBins={setAmountOfBins}
            unifiedBinsLoading={unifiedBinsLoading}
            unifiedCommonYRange={unifiedCommonYRange}
            commonXDomain={commonXDomain}
            unifiedHistograms={unifiedHistograms}
          />
        )}
        {discreteChartData.length > 0 && (
          <div className={styles.chartContainer}>
            <WhyLabsText inherit className={styles.chartTitle}>
              <span style={{ fontWeight: 'bold' }}>Frequent items data</span>
            </WhyLabsText>
            <FeaturePanelChart chartData={discreteChartData} shouldToggleLegend />
          </div>
        )}
        {showFrequentItemsChartFirst && shouldRenderHistograms && (
          <FeaturePanelHistogramStack
            amountOfBins={amountOfBins}
            setAmountOfBins={setAmountOfBins}
            unifiedBinsLoading={unifiedBinsLoading}
            unifiedCommonYRange={unifiedCommonYRange}
            commonXDomain={commonXDomain}
            unifiedHistograms={unifiedHistograms}
          />
        )}
      </>
    );
  }

  if (!visible) return null;

  const title = (
    <div className={styles.titleWrap}>
      <WhyLabsText inherit className={styles.title}>
        {content?.length && content[0] && content[0]['feature-name']} Distribution analysis
      </WhyLabsText>
      <CircularProgress
        size="16px"
        className={cx(styles.loader, (loading.chart || loading.chipTable) && styles.loaderOn)}
      />
    </div>
  );

  return (
    <WhyLabsDrawer isOpen onClose={onCloseSidePanel} size={500} lockScroll={false} title={title} withOverlay={false}>
      <div className={styles.root}>
        <div className={styles.content}>
          {generateGraphs()}

          {hasBoxPlotData && (
            <div className={styles.boxplotsContainer}>
              <WhyLabsText inherit className={styles.sectionTitle}>
                Box plots
              </WhyLabsText>
              <ParentSize>
                {({ width, height }) => <FeaturePanelBoxPlots width={width} height={height} content={content} />}
              </ParentSize>
            </div>
          )}

          <div className={styles.frequentItemsContainer}>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}
            >
              <WhyLabsText inherit className={styles.sectionTitle}>
                {chipTableType === 'Frequent' ? 'Frequent items' : 'Histogram data'}
              </WhyLabsText>
              <button
                className={utilityStyles.invisibleButton}
                type="button"
                onClick={() => setChipTableType(chipTableType === 'Frequent' ? 'Histogram' : 'Frequent')}
              >
                <WhyLabsText inherit className={styles.toggleChipDataLink}>
                  {chipTableType === 'Frequent' ? 'Show Histogram data' : 'Show Frequent items'}
                </WhyLabsText>
              </button>
            </div>

            <TableContainer className={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={cx(styles.tableHeaderCell, styles.tableCell)}
                      style={{ borderTop: `2px solid ${Colors.brandSecondary200}` }}
                    >
                      Item
                    </TableCell>
                    {chipTableData.columns.map((column, i) => (
                      <TableCell
                        align="right"
                        key={column}
                        className={cx(styles.tableHeaderCell, styles.tableCell)}
                        style={{ borderTop: `2px solid ${Colors.profilesColorPool[i]}` }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(chipTableData.rows).map((row, i) => (
                    // eslint-disable-next-line
                    <TableRow key={i} className={styles.tableRow}>
                      {Object.keys(row).map((profile, z) => {
                        const isFirst = z === 0;

                        if (isFirst)
                          return (
                            <TableCell className={styles.tableCell} key={`key-${profile}`}>
                              <UnstyledTooltip
                                title={
                                  <CustomTooltip
                                    data={formatDataForTooltip(row)}
                                    label={generateChipValue(row.value.toString())}
                                  />
                                }
                              >
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label={generateChipValue(row[profile].toString())}
                                  className={styles.chipStyle}
                                />
                              </UnstyledTooltip>
                            </TableCell>
                          );

                        return (
                          <TableCell align="right" key={profile} className={cx(styles.tableBodyCell, styles.tableCell)}>
                            {row[profile]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </WhyLabsDrawer>
  );
}
