import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { useMemo, useState, useEffect, useCallback, PropsWithChildren } from 'react';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Text } from '@visx/text';
import { useTooltip, defaultStyles, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Colors } from '@whylabs/observatory-lib';
import { HistogramFieldsFragment } from 'generated/graphql';
import { friendlyFormat } from 'utils/numberUtils';
import { Slider, Tooltip, ValueLabelProps, debounce } from '@material-ui/core';
import { LegendOrdinal } from '@visx/legend';
import { HistogramDomain } from 'components/visualizations/inline-histogram/histogramUtils';
import useTypographyStyles from 'styles/Typography';
import { InvisibleButton } from 'components/buttons/InvisibleButton';
import { createStyles } from '@mantine/core';
import { WhyLabsText } from 'components/design-system';
import { UnifiedHistogramWithMetadata } from './types';

const useStyles = createStyles({
  tooltipHeaderText: {
    fontWeight: 'bold',
  },
  tooltipText: {
    fontFamily: 'Asap',
    fontSize: 12,
    color: Colors.brandSecondary900,
  },
  tooltipTextWithSpace: {
    marginRight: '4px',
  },
  bar: {
    transformOrigin: 'center calc(100% - 30px)',
  },
  railBackgroundOverride: {
    backgroundColor: Colors.brandSecondary200,
  },
  slideRootOverride: {
    color: Colors.brandPrimary700,
  },
  sliderTooltip: {
    fontSize: '12px',
  },
  legendRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 10px',
  },
  legendRootDisabled: {
    cursor: 'not-allowed',
  },
});

function getHistogramDisplayName(histogram: UnifiedHistogramWithMetadata) {
  return histogram.profileName ?? `Profile ${histogram.profileNum}`;
}

interface OverlaidHistogramsViewProps {
  graphHeight: number;
  graphWidth: number;
  allUnifiedBins: number[];
  graphVerticalBuffer: number;
  graphHorizontalBuffer: number;
  unifiedHistograms: UnifiedHistogramWithMetadata[];
  histogramDomain: HistogramDomain;
  histogramRange: HistogramDomain;
  compactView?: boolean;
}

const OverlaidHistogramsView: React.FC<OverlaidHistogramsViewProps> = ({
  graphHeight,
  graphWidth,
  graphHorizontalBuffer,
  graphVerticalBuffer,
  unifiedHistograms,
  histogramDomain,
  histogramRange,
  allUnifiedBins,
  compactView = false,
}) => {
  const [sliderXMin, setSliderXMin] = useState(histogramDomain.min);
  const [sliderXMax, setSliderXMax] = useState(histogramDomain.max);
  const [xMin, setXMin] = useState(histogramDomain.min);
  const [xMax, setXMax] = useState(histogramDomain.max);
  const [yMax, setYMax] = useState(histogramRange.max);
  const [excludedProfiles, setExcludedProfiles] = useState<number[]>([]);
  const [hoveredBarIndex, setHoveredBarIndex] = useState(-1);
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<number>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });
  const { classes: typography } = useTypographyStyles();
  const { classes: styles, cx } = useStyles();
  const histograms = getHistograms();
  const unifiedBins = getUnifiedBins();

  /**
   * Updates Y max value when profile gets toggeled off
   */
  useEffect(() => {
    const allCounts = histograms.reduce((acc, curr) => {
      if (curr.data) return acc.concat(curr.data.counts);

      return acc;
    }, [] as number[]);
    const newYMax = Math.max(...allCounts);

    setYMax(newYMax);
  }, [excludedProfiles, histograms]);

  useEffect(() => {
    setYMax(histogramRange.max);
    setXMin(histogramDomain.min);
    setXMax(histogramDomain.max);
    setSliderXMin(histogramDomain.min);
    setSliderXMax(histogramDomain.max);
  }, [histogramRange, histogramDomain]);

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: Colors.white,
    color: Colors.brandSecondary900,
    padding: 8,
    border: `1px solid ${Colors.brandSecondary900}`,
    zIndex: 2,
  };
  const axisBuffer = compactView ? 20 : 30;

  const graphXMax = compactView
    ? graphWidth - graphHorizontalBuffer - axisBuffer
    : graphWidth - graphHorizontalBuffer / 2;
  const graphXMin = compactView ? graphHorizontalBuffer + axisBuffer : graphHorizontalBuffer;
  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [graphXMin, graphXMax],
        round: true,
        domain: [xMin, xMax],
      }),
    [xMin, xMax, graphXMin, graphXMax],
  );

  const rangeMax = graphVerticalBuffer;
  const rangeMin = graphHeight - graphVerticalBuffer - axisBuffer;
  const yScale = useMemo(
    () =>
      // const graphMaxY = Math.round(yMax);
      scaleLinear<number>({
        range: [rangeMin, rangeMax],
        round: true,
        domain: [0, yMax],
      }).nice(),
    [rangeMin, rangeMax, yMax],
  );

  const legendColorScale = scaleOrdinal({
    range: [histograms.map((histogram) => histogram.color)],
    domain: [histograms.map(getHistogramDisplayName)],
  });

  /**
   * Adjusts unified bins according to xAxis domain
   */
  function getUnifiedBins() {
    const { bins } = excludeBinsAndCounts({ bins: allUnifiedBins, counts: [] });

    return bins;
  }

  /**
   * Filters out and adjusts histograms bins and counts based on xAxis domain
   */
  function getHistograms(): UnifiedHistogramWithMetadata[] {
    // Filter out toggled histograms;
    const toggledHistograms = unifiedHistograms.filter((histogram) => !excludedProfiles.includes(histogram.profileNum));
    const adjustedHistograms = toggledHistograms.map((histogram) => {
      let newBinsAndCounts: HistogramFieldsFragment | undefined;
      if (histogram.data) newBinsAndCounts = excludeBinsAndCounts(histogram.data);

      return {
        ...histogram,
        data: newBinsAndCounts,
      };
    });

    return adjustedHistograms;
  }

  /**
   * Removes bins and counts which are no longer on the x axis.
   */
  function excludeBinsAndCounts(histogram: HistogramFieldsFragment): HistogramFieldsFragment {
    let excludeFrom = 0;
    let excludeToIndex = 0;

    histogram.bins.find((bin, i) => {
      excludeFrom = i;
      return bin >= xMin;
    });

    [...histogram.bins].reverse().find((bin, i) => {
      excludeToIndex = i;
      return bin <= xMax;
    });

    const excludeTo = histogram.bins.length - excludeToIndex;
    return {
      bins: histogram.bins.slice(excludeFrom, excludeTo),
      counts: histogram.counts.slice(excludeFrom, excludeTo - 1), // -1 Because we must always have 1 more bin in order to determine the last edge.
    };
  }

  const handleMouseOver = (event: React.MouseEvent, index: number) => {
    const coords = localPoint(event);
    if (!coords) {
      return;
    }
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: index,
    });
  };

  /**
   * Gets opactiy based on https://www.figma.com/file/7eSwTJw0nxbYToj8e9FLVk/Self-Service?node-id=1660%3A377
   */
  function getOpactiy(profileNum: number): number {
    if (histograms.length === 3) {
      switch (profileNum) {
        case 1:
          return 0.7;
        case 2:
          return 0.5;
        case 3:
          return 0.3;
      }
    }

    if (histograms.length === 2) {
      const hasFirstProfileHistogram = !!histograms.find((histogram) => histogram.profileNum === 1);
      const hasSecondProfileHistogram = !!histograms.find((histogram) => histogram.profileNum === 2);
      const hasThirdProfileHistogram = !!histograms.find((histogram) => histogram.profileNum === 3);

      if (hasFirstProfileHistogram && hasSecondProfileHistogram) {
        switch (profileNum) {
          case 1:
            return 0.85;
          case 2:
            return 0.6;
        }
      }

      if (hasFirstProfileHistogram && hasThirdProfileHistogram) {
        switch (profileNum) {
          case 1:
            return 0.85;
          case 3:
            return 0.5;
        }
      }

      if (hasSecondProfileHistogram && hasThirdProfileHistogram) {
        switch (profileNum) {
          case 2:
            return 0.85;
          case 3:
            return 0.5;
        }
      }
    }

    return 1;
  }

  /**
   * Removes last bin since the last bin is only used to determine where the last edge is.
   */
  function prepareBinsForRender(bins: number[]) {
    return [...bins].slice(0, bins.length - 1);
  }

  const renderBars = (histogram: HistogramFieldsFragment, color: string, profileNum: number) => {
    const excludeBars = excludedProfiles.includes(profileNum);
    const renderBins = prepareBinsForRender(unifiedBins);

    return renderBins.map((_, index) => {
      const count = histogram.counts[index];
      const barX = xScale(histogram.bins[index]) + 1;
      const barXend = index < histogram.bins.length - 1 ? xScale(histogram.bins[index + 1]) : barX + 5; // HACK DEFAULT FOR NOW
      const barY = yScale(count);
      const barHeight = rangeMin - barY;
      const opacity = getOpactiy(profileNum);

      return (
        <Bar
          className={styles.bar}
          style={{ opacity }}
          key={`histogram-bar-profile${profileNum}-${barX}`}
          x={barX}
          y={barY}
          width={barXend - barX}
          height={excludeBars ? 0 : barHeight}
          fill={color}
        />
      );
    });
  };

  const renderShadowBars = () => {
    const renderBins = prepareBinsForRender(unifiedBins);

    return renderBins.map((bin, index) => {
      const barX = xScale(bin) + 1;
      const barXend = index < unifiedBins.length - 1 ? xScale(unifiedBins[index + 1]) : barX + 5; // HACK DEFAULT FOR NOW

      return (
        <Bar
          className="shadow-bar"
          key={`histogram-bar-${barX}`}
          x={barX}
          y={rangeMax}
          width={barXend - barX}
          height={rangeMin - rangeMax} // Note: min and max are inverted on the y axis.
          pointerEvents="none"
          fill={hoveredBarIndex === index ? Colors.brandSecondary300 : Colors.transparent}
        />
      );
    });
  };

  const renderHoverBars = () => {
    const renderBins = prepareBinsForRender(unifiedBins);

    return renderBins.map((bin, index) => {
      const barX = xScale(bin) + 1;
      const barXend = index < unifiedBins.length - 1 ? xScale(unifiedBins[index + 1]) : barX + 5; // HACK DEFAULT FOR NOW

      /* eslint-disable jsx-a11y/mouse-events-have-key-events */
      // TODO: figure out how to do tooltips for key events to fix accessibility.
      return (
        <Bar
          className="hover-bar"
          key={`histogram-bar-${barX}`}
          x={barX}
          y={rangeMax}
          width={barXend - barX}
          height={rangeMin - rangeMax}
          pointerEvents="visible"
          onMouseEnter={() => {
            setHoveredBarIndex(index);
          }}
          onMouseOver={(event) => {
            handleMouseOver(event, index);
          }}
          fill={Colors.transparent}
        />
      );
    });
  };

  const formatNumber = (val: number): string => {
    if (Number.isInteger(val)) {
      return val.toFixed(0);
    }
    return val > 100 ? val.toFixed(1) : val.toFixed(2);
  };

  const renderTooltip = () => {
    if (!tooltipOpen || tooltipData === undefined || hoveredBarIndex < 0 || hoveredBarIndex >= unifiedBins.length - 1)
      return null;

    const binMin = formatNumber(unifiedBins[hoveredBarIndex]);
    const binMax = formatNumber(unifiedBins[hoveredBarIndex + 1]);

    const counts = histograms.map((histogram) => ({
      profile: getHistogramDisplayName(histogram),
      count: histogram.data ? formatNumber(histogram.data.counts[hoveredBarIndex]) : 'No data',
      color: histogram.color,
    }));

    const displayLabel = counts.length === 1 ? 'Count' : 'Counts';
    return (
      <TooltipInPortal key={Math.random()} top={tooltipTop} left={tooltipLeft} style={tooltipStyles} offsetTop={-10}>
        <WhyLabsText inherit className={cx(styles.tooltipText, styles.tooltipHeaderText)}>
          Bin data
        </WhyLabsText>
        <WhyLabsText inherit className={styles.tooltipText}>{`Min: ${binMin}`}</WhyLabsText>
        <WhyLabsText inherit className={styles.tooltipText}>{`Max: ${binMax}`}</WhyLabsText>

        <WhyLabsText inherit className={cx(styles.tooltipText, styles.tooltipHeaderText)}>
          {displayLabel}
        </WhyLabsText>
        {counts.map(({ count, profile, color }) => (
          <div key={`${count}-${profile}`} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ height: '11px', width: '11px', backgroundColor: color, marginRight: '5px' }} />
            {profile && (
              <WhyLabsText inherit className={cx(styles.tooltipText, styles.tooltipTextWithSpace)}>
                {profile}
              </WhyLabsText>
            )}
            <WhyLabsText inherit className={styles.tooltipText} style={{ color }}>
              {count}
            </WhyLabsText>
          </div>
        ))}
      </TooltipInPortal>
    );
  };

  function toggleProfile(profileKey: number) {
    if (excludedProfiles.includes(profileKey))
      setExcludedProfiles((prev) => prev.filter((excludedKey) => excludedKey !== profileKey));
    else setExcludedProfiles((prev) => [...prev, profileKey]);
  }

  const handleXAxisChange = (range: number[]) => {
    const [min, max] = range;
    const stopVariable = histogramDomain.max * 0.1; // 10% Percent stop padding
    if (min + stopVariable >= max) return;
    const allCounts = histograms.reduce((acc, curr) => {
      if (curr.data) return acc.concat(curr.data.counts);

      return acc;
    }, [] as number[]);
    const newYMax = Math.max(...allCounts);

    setSliderXMin(min);
    setSliderXMax(max);
    updateAxis(min, max, newYMax);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateAxis = useCallback(
    debounce((min: number, max: number, newYMax: number) => {
      setXMin(min);
      setXMax(max);
      setYMax(newYMax);
    }, 300),
    [],
  );

  function CustomSliderLabel(props: PropsWithChildren<ValueLabelProps>) {
    const { children, open, value } = props;

    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value}
        classes={{ tooltip: styles.sliderTooltip }}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <div style={{ position: 'relative' }} ref={containerRef}>
      <div
        style={{
          top: 0,
          width: graphWidth - graphHorizontalBuffer * 2,
          height: graphVerticalBuffer,
          left: graphHorizontalBuffer,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 400,
          fontSize: 12,
          fontFamily: 'Asap',
          position: 'relative',
          margin: `${compactView ? '0px' : '25px'} 0px 5px 0px`,
        }}
      >
        <span style={{ marginRight: '10px' }}>Click to toggle:</span>
        <LegendOrdinal
          scale={legendColorScale}
          // eslint-disable-next-line react/no-children-prop
          children={() => {
            return unifiedHistograms.map((histogram) => {
              const isToggledOff = excludedProfiles.includes(histogram.profileNum);
              const hasData = !!histogram.data;
              const displayName = getHistogramDisplayName(histogram);

              return (
                <InvisibleButton
                  key={histogram.profileName ?? histogram.profileNum}
                  onClick={() => hasData && toggleProfile(histogram.profileNum)}
                  className={cx(styles.legendRoot, !hasData && styles.legendRootDisabled)}
                >
                  <div
                    style={{
                      height: '11px',
                      width: '11px',
                      backgroundColor: isToggledOff ? Colors.brandSecondary900 : histogram.color,
                    }}
                  />
                  {hasData ? (
                    <span style={{ margin: '0px 5px' }}>{displayName}</span>
                  ) : (
                    <span style={{ margin: '0px 5px', fontStyle: 'italic' }}>{displayName}(no data)</span>
                  )}
                </InvisibleButton>
              );
            });
          }}
          direction="row"
          labelMargin="0 12px 0 0"
          shapeHeight={10}
          shapeWidth={10}
          shapeMargin={5}
        />
      </div>
      <svg width={graphWidth} height={graphHeight - graphVerticalBuffer}>
        <Group
          top={0}
          left={0}
          onMouseLeave={() => {
            setHoveredBarIndex(-1);
            hideTooltip();
          }}
        >
          {!compactView && (
            <>
              <rect
                x={graphHorizontalBuffer}
                y={rangeMax}
                width={graphWidth - graphHorizontalBuffer - graphHorizontalBuffer / 2}
                height={rangeMin - rangeMax}
                fill={Colors.transparent}
                radius={2}
                stroke={Colors.brandSecondary200}
              />
              <Text
                fill={Colors.black}
                fontSize={14}
                x={graphHorizontalBuffer / 4}
                y={graphVerticalBuffer + (graphHorizontalBuffer - graphVerticalBuffer / 2)}
                textAnchor="middle"
                fontFamily="Asap"
                fontWeight={700}
                angle={270}
              >
                Count
              </Text>
            </>
          )}
          <AxisLeft
            scale={yScale}
            left={graphXMin}
            stroke={Colors.brandSecondary200}
            tickFormat={(v) => friendlyFormat(v.valueOf(), 2)}
            numTicks={4}
            tickStroke={Colors.brandSecondary200}
            tickLabelProps={() => ({
              fill: Colors.brandSecondary900,
              fontSize: 12,
              fontFamily: 'Asap',
              textAnchor: 'end',
              verticalAnchor: 'middle',
            })}
          />
          <AxisBottom
            scale={xScale}
            top={rangeMin}
            stroke={Colors.brandSecondary200}
            numTicks={graphWidth > 520 ? 8 : 5}
            tickFormat={(v) => friendlyFormat(v.valueOf(), 2)}
            tickStroke={Colors.brandSecondary200}
            tickLabelProps={() => ({
              fill: Colors.brandSecondary900,
              fontSize: 12,
              fontFamily: 'Asap',
              textAnchor: 'middle',
            })}
          />
          {renderShadowBars()}
          <svg>
            {histograms.map((histogram) => {
              if (histogram.data) return renderBars(histogram.data, histogram.color, histogram.profileNum);

              return null;
            })}
          </svg>
          {renderHoverBars()}
          {renderTooltip()}
        </Group>
      </svg>

      {!compactView && (
        <div style={{ width: '374px', margin: '0px 60px' }}>
          <div>
            <Slider
              min={histogramDomain.min}
              max={histogramDomain.max}
              value={[sliderXMin, sliderXMax]}
              onChange={(event, newRange) => {
                if (typeof newRange === 'number') return;
                handleXAxisChange(newRange);
              }}
              valueLabelFormat={(val) => {
                return friendlyFormat(val, 2);
              }}
              valueLabelDisplay="auto"
              classes={{
                root: styles.slideRootOverride,
                rail: styles.railBackgroundOverride,
              }}
              ValueLabelComponent={CustomSliderLabel}
            />
            <WhyLabsText
              inherit
              className={typography.helperTrendText}
              style={{ textAlign: 'center', marginTop: '-12px' }}
            >
              Adjustable x-range
            </WhyLabsText>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverlaidHistogramsView;
