import { useCallback, useMemo } from 'react';
import { createStyles } from '@mantine/core';
import OverviewHeaderPanel from 'components/panels/OverviewHeaderPanel';
import { FilterArea } from 'components/filter-area/FilterArea';
import {
  ModelOverviewInfoFragment,
  ModelType,
  SortDirection,
  useGetModelOverviewInformationQuery,
} from 'generated/graphql';
import ResourceOverviewCardLayout from 'pages/resource-overview-page/components/ResourceOverviewCardLayout/ResourceOverviewCardLayout';
import { Colors, Spacings } from '@whylabs/observatory-lib';
import { VIEW_TYPE } from 'types/navTags';
import { useNavLinkHandler } from 'hooks/usePageLinkHandler';
import useSort from 'hooks/useSort';
import { AllAccessors, ModelSortBy, SortByKeys, SortDirectionKeys, SortDirectionType } from 'hooks/useSort/types';
import { FilterKeys } from 'hooks/useFilterQueryString';
import { canManageDatasets } from 'utils/permissionUtils';
import { useUserContext } from 'hooks/useUserContext';
import { isItOverSubscriptionLimit } from 'utils/subscriptionUtils';
import { useDeepCompareMemo } from 'use-deep-compare';
import { DATASET_TYPES, getLabelForModelType, MODEL_TYPES, OTHER_TYPES, SecuredLLM } from 'utils/modelTypeUtils';
import { useSearchParams } from 'react-router-dom';
import { CheckBoxSection } from 'components/filter-area/utils';
import { WhyLabsRadioGroupOptions } from 'components/design-system/radio/WhyLabsRadioGroup';
import { useSuperGlobalDateRange } from 'components/super-date-picker/hooks/useSuperGlobalDateRange';
import { useFlags } from 'hooks/flags/useFlags';
import { LLM_SECURE_OVERALL } from 'constants/flags';
import { useSetHtmlTitle } from 'hooks/useSetHtmlTitle';
import DashboardLayout from './components/TableDashboard/DashboardLayout';
import { asLayoutTypeOrDefault, LayoutType, ResourceOverviewData } from './layoutHelpers';

const accessorsMapper = new Map<ModelSortBy, AllAccessors<ResourceOverviewData>>([
  ['Name', ['name']],
  ['Freshness', ['dataAvailability', 'latestTimestamp']],
  ['LatestAlert', ['latestAnomalyTimestamp']],
  ['AnomaliesInRange', ['totalAnomaliesInRange']],
  ['ResourceType', ['modelType']],
  ['CreationTime', ['creationTime']],
]);

const RESOURCE_SORT_COLUMNS: WhyLabsRadioGroupOptions[] = [
  { label: 'Resource name', value: 'Name' },
  { label: 'Freshness', value: 'Freshness' },
  { label: 'Last profiles with anomalies', value: 'LatestAlert' },
  { label: 'Anomalies in range', value: 'AnomaliesInRange' },
  { label: 'Resource type', value: 'ResourceType' },
  { label: 'Creation date', value: 'CreationTime' },
];

const useContentAreaStyles = createStyles({
  panelRoot: {
    display: 'flex',
    flexBasis: Spacings.tabContentHeaderHeight,
  },
  panelCardRoot: {
    borderBottom: `1px solid ${Colors.brandSecondary200}`,
  },
});

export function ResourceOverviewPageContentArea(): JSX.Element {
  const { classes, cx } = useContentAreaStyles();
  const { flags } = useFlags();
  const { dateRange, loading: loadingDateRange } = useSuperGlobalDateRange();
  const { handleNavigation } = useNavLinkHandler();
  const [searchParams] = useSearchParams();
  const activeFilters = useMemo(() => searchParams.getAll(FilterKeys.modelFilter), [searchParams]);

  useSetHtmlTitle('Project dashboard');

  const RESOURCE_TYPE_FILTERS: CheckBoxSection[] = [
    {
      sectionLabel: 'Filter by model type',
      key: 'model_type_filters',
      items: MODEL_TYPES.flatMap((option) => {
        if (option === ModelType.Llm && flags[LLM_SECURE_OVERALL]) {
          return [option, SecuredLLM as ModelType];
        }
        return [option];
      }).map((d) => ({
        label: getLabelForModelType(d),
        value: d.toString(),
      })),
    },
    {
      sectionLabel: 'Filter by dataset type',
      key: 'dataset_type_filters',
      items: DATASET_TYPES.map((d) => ({
        label: getLabelForModelType(d),
        value: d.toString(),
      })),
    },
    {
      key: 'other_type_filters',
      items: OTHER_TYPES.map((d) => ({
        label: getLabelForModelType(d),
        value: d.toString(),
      })),
    },
  ];

  const { data, loading, error, refetch } = useGetModelOverviewInformationQuery({
    variables: {
      ...dateRange,
    },
    skip: loadingDateRange,
  });

  const { getCurrentUser } = useUserContext();
  const user = getCurrentUser();
  const userCanManageDatasets = canManageDatasets(user);
  const isOverSubscriptionLimit = isItOverSubscriptionLimit({
    modelCount: data?.models?.length || 0,
    tier: user?.organization?.subscriptionTier,
  });

  const { sortDirection, sortBy, handleSort, setSort } = useSort<ModelSortBy>(
    SortByKeys.sortModelBy,
    SortDirectionKeys.sortModelDirection,
  );

  const modelsHaveBeenConfigured = useCallback(() => {
    if (data) {
      return data.models.some((model) => model.dataLineage?.oldestProfileTimestamp);
    }
    return false;
  }, [data]);

  const mapResources = (resourcesData?: ModelOverviewInfoFragment[]): ResourceOverviewData[] =>
    resourcesData?.map((r) => {
      const totalAnomaliesInRange =
        r.anomalyCounts?.timeseries?.reduce((acc, curr) => {
          const dayCount = curr.counts.reduce((dayTotal, current) => dayTotal + current.count, 0);
          return acc + dayCount;
        }, 0) ?? 0;
      return {
        ...r,
        totalAnomaliesInRange,
      };
    }) ?? [];

  const sortData = useCallback(
    (
      nextSortDirection: SortDirectionType,
      newSortBy: ModelSortBy = 'LatestAlert',
      usedData?: ResourceOverviewData[],
    ) => {
      const models = usedData || mapResources(data?.models);
      const accessors = accessorsMapper.get(newSortBy) ?? [];
      return handleSort<ResourceOverviewData>(models, nextSortDirection, newSortBy, accessors);
    },
    [data?.models, handleSort],
  );

  const filteredResources = useMemo(() => {
    const filterSecuredLLM = (model: ResourceOverviewData) =>
      model.tracesSummary?.hasTraces &&
      model.modelType === ModelType.Llm &&
      flags[LLM_SECURE_OVERALL] &&
      activeFilters.includes(SecuredLLM);
    const isFilterEmpty = activeFilters.length === 0;
    const resources = mapResources(data?.models);
    if (!resources || isFilterEmpty) {
      return sortData(sortDirection ?? SortDirection.Desc, sortBy, resources);
    }
    const sorted = resources.filter((model) => activeFilters.includes(model.modelType) || filterSecuredLLM(model));
    return sortData(sortDirection ?? SortDirection.Desc, sortBy, sorted);
  }, [activeFilters, data?.models, flags, sortBy, sortData, sortDirection]);

  const searchTerm = searchParams.get(FilterKeys.searchString) ?? undefined;
  const normalizedTerm = searchTerm?.toLowerCase();

  const filteredData = (() => {
    if (!normalizedTerm) return filteredResources;

    return filteredResources?.filter(
      (searchModel) =>
        searchModel.name.toLowerCase().includes(normalizedTerm) ||
        searchModel.id.toLowerCase().includes(normalizedTerm),
    );
  })();

  function generateCardLayout() {
    return (
      <ResourceOverviewCardLayout
        searchTerm={searchParams.get(FilterKeys.searchString) ?? undefined}
        loading={loading}
        error={error}
        sortByDirection={sortDirection}
        sortBy={sortBy}
        userCanManageDatasets={userCanManageDatasets}
        filteredData={filteredData}
      />
    );
  }

  function generateTableLayout() {
    return (
      <DashboardLayout
        searchTerm={searchParams.get(FilterKeys.searchString) ?? undefined}
        models={filteredResources}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={setSort}
        loading={loading}
        error={error}
        refetchData={refetch}
        setIsOpen={navigateToAddResource}
        userCanManageDatasets={userCanManageDatasets}
      />
    );
  }

  const layoutType = useDeepCompareMemo(() => {
    return asLayoutTypeOrDefault(searchParams.get(VIEW_TYPE));
  }, [searchParams]);

  function generateLayout(layout: LayoutType) {
    return layout === 'card' ? generateCardLayout() : generateTableLayout();
  }

  return (
    <>
      <div className={cx(classes.panelRoot, layoutType === 'card' && classes.panelCardRoot)}>
        <FilterArea<ModelSortBy>
          titleText="Filter resources"
          placeholder="Filter by name or ID"
          tooltipContent="The list of all datasets and models which can be searched and filtered"
          checkboxFilterList={RESOURCE_TYPE_FILTERS}
          filterKey={FilterKeys.modelFilter}
          sortBy={sortBy}
          sortSetter={setSort}
          sortByDirection={sortDirection}
          sortFieldsList={RESOURCE_SORT_COLUMNS}
        />

        <OverviewHeaderPanel
          isOverSubscriptionLimit={isOverSubscriptionLimit}
          loading={loading}
          error={error}
          modelsHaveBeenConfigured={modelsHaveBeenConfigured()}
          addResource={navigateToAddResource}
          userCanManageDatasets={userCanManageDatasets}
          filteredData={filteredData}
          hasLayoutToggle
        />
      </div>
      <>{generateLayout(layoutType)}</>
    </>
  );

  function navigateToAddResource() {
    handleNavigation({ page: 'settings', settings: { path: 'model-management' } });
  }
}
