import { createStyles, makeStyles } from '@material-ui/core';
import { WhyLabsButton } from 'components/design-system';
import { useIsGuestSession } from 'hooks/useIsAnonymousSession';
import LogRocket from 'logrocket';
import { useWhyLabsSnackbar } from 'hooks/useWhyLabsSnackbar';

const useShareStyles = makeStyles((theme) =>
  createStyles({
    button: {
      position: 'relative',
      display: 'inline-block',
      padding: '10px 20px',
      marginLeft: 10,
      fontSize: 16,
      textTransform: 'uppercase',
      overflow: 'hidden',
      backgroundImage: 'linear-gradient(100deg, transparent 40%, #fff 50%, transparent 60%)',
      backgroundPosition: '-80%',
      backgroundSize: '150%',
      animation: '$shimmer 5s ease-in-out 3s 2',
    },
    '@keyframes shimmer': {
      '0%': {
        backgroundPosition: '-80%',
      },
      '10%': {
        backgroundPosition: '180%',
      },
      '100%': {
        backgroundPosition: '180%',
      },
    },
  }),
);

export function ShareButton(): JSX.Element | null {
  const { userIsGuest } = useIsGuestSession();
  const { button } = useShareStyles();
  const { enqueueSnackbar } = useWhyLabsSnackbar();

  if (!userIsGuest) {
    return null;
  }

  const handleCopyURL = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        enqueueSnackbar({ title: 'URL copied to clipboard!' });
      })
      .catch((error) => {
        LogRocket.error('Failed to copy URL to clipboard:', error);
      });
  };

  return (
    <WhyLabsButton onClick={handleCopyURL} className={button} variant="outline">
      Share Session
    </WhyLabsButton>
  );
}
