import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { createStyles } from '@mantine/core';
import { AccountCircle } from '@material-ui/icons';
import { Colors } from '@whylabs/observatory-lib';
import { LabelCopyAction } from 'components/label-copy-action/LabelCopyAction';
import { SubscriptionTier } from 'generated/graphql';
import { useIsGuestSession } from 'hooks/useIsAnonymousSession';
import { useAuthNavigationHandler, useNavLinkHandler } from 'hooks/usePageLinkHandler';
import { useUserContext } from 'hooks/useUserContext';
import { ReactNode, useRef, useState } from 'react';
import { CurrentUser } from 'types/userTypes';
import { canManageOrg } from 'utils/permissionUtils';
import { WhyLabsText } from 'components/design-system';

const useStyles = createStyles({
  menuButton: {
    color: Colors.white,
    backgroundColor: Colors.secondaryLight1000,
    border: `1px solid ${Colors.secondaryLight1000}`,
    height: '29px', // with border, total will be 30px
    width: '29px',
    borderRadius: 4,
  },
  clickable: {
    '&:hover': {
      backgroundColor: Colors.brandSecondary100,
    },
    cursor: 'pointer',
  },
  unclickable: {
    '&:hover': {
      backgroundColor: Colors.transparent,
    },
    cursor: 'auto',
  },
  menuItem: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuWrapper: {
    '& .MuiPaper-root': {
      backgroundColor: Colors.white,
      border: '1px solid rgba(0, 0, 0, 0.25)',
      boxShadow: '0 10px 15px rgb(0, 0, 0, 0.15)',
      borderRadius: `4px`,
      paddingTop: '2px',
      paddingBottom: 0,
      marginTop: '4px',
      minWidth: '200px',
    },
    '& .MuiList-root': {
      paddingBottom: 0,
    },

    fontSize: 14,
    padding: '0px',
  },
  separatedItem: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  singleItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  bottomItem: {
    paddingTop: '10px',
    paddingBottom: '10px', // Because the Mui-list's default padding is 8 and inaccessible.
    paddingLeft: '16px',
  },
  nameText: {
    fontSize: 14,
    lineHeight: 1.5,
    color: Colors.secondaryLight1000,
    fontWeight: 500,
  },
  orgHeaderText: {
    fontSize: 11,
    lineHeight: 1.5,
    color: Colors.brandSecondary600,
    fontWeight: 500,
    marginBottom: 5,
  },
  orgText: {
    fontSize: 14,
    lineHeight: 1.5,
    color: Colors.secondaryLight1000,
    fontWeight: 400,
  },
  secondaryText: {
    fontSize: 12,
    color: Colors.brandSecondary600,
    lineHeight: 1.5,
    fontWeight: 400,
  },
  emailText: {
    paddingBottom: 5,
  },
});

type MenuItemType = {
  classNames: {
    label?: string;
    menu?: string;
  };
  id: string;
  label: string | ReactNode;
  onClick?: () => void;
  withDivider?: boolean;
};

const WhoAmI = (): JSX.Element => {
  const { classes: styles, cx } = useStyles();
  const { userIsGuest } = useIsGuestSession();
  const { getCurrentUser, loading } = useUserContext();
  const { triggerLogout, triggerLogin } = useAuthNavigationHandler();
  const { handleNavigation } = useNavLinkHandler();
  const user = getCurrentUser();

  const menuRef = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <IconButton ref={menuRef} className={styles.menuButton} disableRipple onClick={toggleMenuOpen} disabled={loading}>
        <AccountCircle />
      </IconButton>
      {renderMenu()}
    </>
  );

  function renderMenu() {
    if (loading) {
      return null;
    }

    return (
      <Menu
        open={isMenuOpen}
        className={styles.menuWrapper}
        onClose={toggleMenuOpen}
        anchorEl={menuRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={4}
        getContentAnchorEl={null}
      >
        {getMenuItems().map(renderMenuItem)}
      </Menu>
    );
  }

  function canSeeBilling(currentUser: CurrentUser | null): boolean {
    if (!currentUser?.organization?.subscriptionTier) {
      return false;
    }
    return currentUser.organization.subscriptionTier === SubscriptionTier.Subscription && canManageOrg(currentUser);
  }

  function getMenuItems() {
    const menus: MenuItemType[] = [
      {
        classNames: {
          label: styles.nameText,
          menu: cx(styles.menuItem, styles.unclickable),
        },
        id: 'user-name',
        label: user?.isAuthenticated ? user.name ?? '-' : 'Not logged in',
      },
    ];

    if (user?.isAuthenticated) {
      menus.push({
        classNames: {
          label: cx(styles.emailText, styles.secondaryText),
          menu: cx(styles.menuItem, styles.unclickable),
        },
        id: 'user-email',
        label: user.email,
        withDivider: true,
      });

      if (user.organization) {
        menus.push({
          classNames: {
            menu: cx(styles.menuItem, styles.unclickable, styles.separatedItem),
          },
          id: 'user-org',
          label: renderUserOrgText(user),
          withDivider: true,
        });
        if (canSeeBilling(user)) {
          menus.push({
            classNames: {
              label: styles.orgText,
              menu: cx(styles.menuItem, styles.singleItem, styles.clickable),
            },
            id: 'billing-icon-click',
            label: 'Billing',
            onClick: () => {
              toggleMenuOpen();
              handleNavigation({ page: 'settings', settings: { path: 'billing' } });
            },
          });
        }
      }

      if (userIsGuest) {
        menus.push({
          classNames: {
            label: styles.orgText,
            menu: cx(styles.bottomItem, styles.clickable),
          },
          id: 'signup-icon-click',
          label: 'Sign up',
          onClick: triggerLogout,
        });
      } else {
        menus.push({
          classNames: {
            label: styles.orgText,
            menu: cx(styles.bottomItem, styles.clickable),
          },
          id: 'logout-icon-click',
          label: 'Sign out',
          onClick: triggerLogout,
        });
      }
    } else {
      menus.push({
        classNames: {
          label: styles.orgText,
          menu: cx(styles.bottomItem, styles.clickable),
        },
        id: 'login-icon-click',
        label: 'Sign in',
        onClick: () => triggerLogin(undefined),
      });
    }

    return menus;
  }

  function renderMenuItem({ classNames, id, label, onClick, withDivider }: MenuItemType) {
    const menuElement = (
      <MenuItem className={classNames.menu} disableRipple id={id} key={id} onClick={onClick}>
        {typeof label === 'string' ? (
          <WhyLabsText inherit className={classNames.label}>
            {label}
          </WhyLabsText>
        ) : (
          label
        )}
      </MenuItem>
    );

    if (withDivider) {
      return [menuElement, <Divider key={`${id}-divider`} />];
    }

    return menuElement;
  }

  function toggleMenuOpen() {
    setIsMenuOpen((prevState) => !prevState);
  }

  function renderUserOrgText(person: CurrentUser): JSX.Element | null {
    if (!person?.organization) {
      return null;
    }

    const orgName = person.organization.name;
    const orgId = person.organization.id;
    const personRole = person.role ? ` (${person.role})` : '';
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <WhyLabsText inherit className={styles.orgHeaderText}>
          CURRENT ORG
        </WhyLabsText>
        <WhyLabsText inherit className={styles.orgText}>{`${orgName}${personRole}`}</WhyLabsText>
        <LabelCopyAction label={`Org ID: ${orgId}`} value={orgId} overrideToastText="Org ID copied to clipboard!" />
      </div>
    );
  }
};

export default WhoAmI;
