import { useEffect, useState } from 'react';
import { BoxPlot } from '@visx/stats';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { createStyles } from '@mantine/core';
import { Colors } from '@whylabs/observatory-lib';
import { withTooltip, Tooltip, defaultStyles as defaultTooltipStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { AxisLeft } from '@visx/axis';
import { graphTooltipStyles } from 'components/visualizations/vizutils/styleUtils';
import { friendlyFormat } from 'utils/numberUtils';
import { TableFeatureDataType } from 'components/controls/table/profiles-table/types';
import { WhyLabsText } from 'components/design-system';

interface BoxPlotData {
  x: string;
  min: number;
  firstQuartile: number;
  median: number;
  thirdQuartile: number;
  max: number;
  fill: string;
  stroke: string;
  // outliers: number[];
}

interface IFeaturePanelBoxPlotsProps {
  content: TableFeatureDataType[];
  width: number;
  height: number;
}

interface TooltipData {
  name?: string;
  min?: string;
  median?: string;
  max?: string;
  firstQuartile?: string;
  thirdQuartile?: string;
}

const tooltipStyles = {
  ...defaultTooltipStyles,
  ...graphTooltipStyles,
};

const usePanelBoxStyles = createStyles({
  noDataText: {
    fontFamily: 'Asap',
  },
});

export default withTooltip<IFeaturePanelBoxPlotsProps, TooltipData>(
  ({
    width,
    height,
    content,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    showTooltip,
    hideTooltip,
  }: IFeaturePanelBoxPlotsProps & WithTooltipProvidedProps<TooltipData>) => {
    const mockBins = [0.0, 0.01, 0.05, 0.25, 0.5, 0.75, 0.95, 0.99, 1.0];
    const [featureCounts, setFeatureCounts] = useState<Array<number[] | undefined>>([]);
    const { classes: styles } = usePanelBoxStyles();
    useEffect(() => {
      if (!content) {
        return;
      }

      const counts = content.map((profile) => {
        if (profile && profile.numberSummary && profile.numberSummary.quantiles.counts.length > 0)
          return profile.numberSummary.quantiles.counts;

        return undefined;
      });

      setFeatureCounts(counts);
    }, [content]);

    function truncateIfNeeded(displayNumber?: number): string | undefined {
      if (displayNumber === undefined) {
        return undefined;
      }
      const isFloat = Number(displayNumber) === displayNumber && displayNumber % 1 !== 0;
      return isFloat ? displayNumber.toFixed(3) : displayNumber.toFixed(0);
    }

    function generateData(bins: number[], counts: number[], index: number): BoxPlotData {
      let medianIndex = bins.indexOf(0.5);
      if (medianIndex < 0) {
        medianIndex = 4; // default
      }
      let firstQuartileIndex = bins.indexOf(0.25);
      if (firstQuartileIndex < 0) {
        firstQuartileIndex = 3;
      }
      let thirdQuartileIndex = bins.indexOf(0.75);
      if (thirdQuartileIndex < 0) {
        thirdQuartileIndex = 5;
      }

      return {
        x: `Profile ${index + 1}`,
        min: counts[0],
        max: counts[counts.length - 1],
        median: counts[medianIndex],
        firstQuartile: counts[firstQuartileIndex],
        thirdQuartile: counts[thirdQuartileIndex],
        fill: Colors.profilesColorPool[index],
        stroke: Colors.black,
      };
    }

    function makeStringBoxPlotData(d: BoxPlotData): TooltipData {
      const convertedData: TooltipData = {};
      if (d.firstQuartile !== undefined) {
        convertedData.firstQuartile = truncateIfNeeded(d.firstQuartile);
      }
      if (d.max !== undefined) {
        convertedData.max = truncateIfNeeded(d.max);
      }
      if (d.median !== undefined) {
        convertedData.median = truncateIfNeeded(d.median);
      }
      if (d.median !== undefined) {
        convertedData.min = truncateIfNeeded(d.min);
      }
      if (d.thirdQuartile !== undefined) {
        convertedData.thirdQuartile = truncateIfNeeded(d.thirdQuartile);
      }
      return convertedData;
    }

    const data: (BoxPlotData | undefined)[] = featureCounts.map((count, i) => {
      if (count) return generateData(mockBins, count, i);

      return undefined;
    });

    const values = data.reduce((allValues, boxPlot) => {
      if (boxPlot) allValues.push(boxPlot.min, boxPlot.max);
      return allValues;
    }, [] as number[]);

    const minYValue = Math.min(...values);
    const maxYValue = Math.max(...values);
    const xMax = width;
    const yMax = height - 120;

    const xScale = scaleBand<string>({
      range: [0, xMax],
      round: true,
      domain: data.filter((datum): datum is BoxPlotData => !!datum).map((d) => d.x),
      padding: 0.4,
    });
    const yScale = scaleLinear<number>({
      range: [yMax, 0],
      round: true,
      domain: [minYValue, maxYValue],
    });

    const boxWidth = xScale.bandwidth();
    const constrainedWidth = Math.min(40, boxWidth);

    const axisLeftTickLabelProps = {
      dx: '-0.25em',
      dy: '0.25em',
      fontFamily: 'Asap',
      fontSize: 10,
      textAnchor: 'end' as const,
      fill: Colors.black,
    };

    return (
      <div style={{ position: 'relative' }}>
        {data.length === 0 ? (
          <WhyLabsText inherit className={styles.noDataText} size="sm">
            Box plot data not found
          </WhyLabsText>
        ) : (
          ''
        )}
        <svg width={width} height={height}>
          <Group top={40}>
            {data.map((d, i) => {
              if (!d) return null;
              return (
                // eslint-disable-next-line
                <g key={i}>
                  <BoxPlot
                    min={d.min}
                    max={d.max}
                    firstQuartile={d.firstQuartile}
                    thirdQuartile={d.thirdQuartile}
                    median={d.median}
                    left={(xScale(d.x) as number) + 0.3 * constrainedWidth + 50}
                    boxWidth={constrainedWidth * 0.4}
                    fill={d.fill}
                    fillOpacity={0.3}
                    stroke={d.stroke}
                    strokeWidth={0.25}
                    valueScale={yScale}
                    minProps={{
                      onMouseOver: () => {
                        showTooltip({
                          tooltipTop: yScale(d.min) ?? 0 + 40,
                          tooltipLeft: (xScale(d.x) as number) + constrainedWidth + 5,
                          tooltipData: {
                            min: truncateIfNeeded(d.min),
                            name: d.x,
                          },
                        });
                      },
                      onMouseLeave: () => {
                        hideTooltip();
                      },
                    }}
                    maxProps={{
                      onMouseOver: () => {
                        showTooltip({
                          tooltipTop: yScale(d.max) ?? 0 + 40,
                          tooltipLeft: (xScale(d.x) as number) + constrainedWidth + 5,
                          tooltipData: {
                            max: truncateIfNeeded(d.max),
                            name: d.x,
                          },
                        });
                      },
                      onMouseLeave: () => {
                        hideTooltip();
                      },
                    }}
                    boxProps={{
                      onMouseOver: () => {
                        // console.log((xScale(d.x) as number), constrainedWidth + 5);
                        showTooltip({
                          tooltipTop: yScale(d.median) ?? 0 + 40,
                          tooltipLeft: (xScale(d.x) as number) + constrainedWidth + 5,
                          tooltipData: {
                            ...makeStringBoxPlotData(d),
                            name: d.x,
                          },
                        });
                      },
                      onMouseLeave: () => {
                        hideTooltip();
                      },
                    }}
                    medianProps={{
                      style: {
                        stroke: d.fill,
                        strokeWidth: 3,
                      },
                      onMouseOver: () => {
                        showTooltip({
                          tooltipTop: yScale(d.median) ?? 0 + 40,
                          tooltipLeft: (xScale(d.x) as number) + constrainedWidth + 5,
                          tooltipData: {
                            ...makeStringBoxPlotData(d),
                            name: d.x,
                          },
                        });
                      },
                      onMouseLeave: () => {
                        hideTooltip();
                      },
                    }}
                  />
                </g>
              );
            })}

            <AxisLeft
              left={50}
              scale={yScale}
              numTicks={5}
              stroke={Colors.black}
              tickLabelProps={() => axisLeftTickLabelProps}
              tickFormat={(val) => {
                const newFormat = friendlyFormat(val as number, 1);

                return newFormat.toString();
              }}
            />
          </Group>
        </svg>

        {tooltipOpen && tooltipData && (
          <Tooltip
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...tooltipStyles,
            }}
          >
            <div>
              <strong>{tooltipData.name}</strong>
            </div>
            <div style={{ marginTop: '5px', fontSize: '12px' }}>
              {tooltipData.min && <div>min: {tooltipData.min}</div>}
              {tooltipData.firstQuartile && <div>first quartile: {tooltipData.firstQuartile}</div>}
              {tooltipData.median && <div>median: {tooltipData.median}</div>}
              {tooltipData.thirdQuartile && <div>third quartile: {tooltipData.thirdQuartile}</div>}
              {tooltipData.max && <div>max: {tooltipData.max}</div>}
            </div>
          </Tooltip>
        )}
      </div>
    );
  },
);
