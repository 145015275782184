import GlobalLoader from 'components/global-loader/GlobalLoader';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';
import { useUser } from 'hooks/useUser';
import { MediaRoute, MediaRouteProps } from './MediaRoute';

export type ProtectedRouteProps = MediaRouteProps;

export const ProtectedRoute = ({ mobileFriendly = false, ...rest }: ProtectedRouteProps): JSX.Element | null => {
  const { loading, isAuthenticated, refetch } = useUser(10 * 1000); // poll user state every 10 seconds
  const location = useLocation();

  useEffect(() => {
    const updateUserState = async () => refetch();
    updateUserState().catch((err) => LogRocket.error(`Failed to refresh user state on navigation: ${err}`));
  }, [location, refetch]);

  // Wait till useUser is finished fetching user data
  // TBD Update to loading component
  if (loading) return <GlobalLoader interval={500} />;

  if (!isAuthenticated) return <Navigate to="/" />;

  return <MediaRoute mobileFriendly={mobileFriendly} {...rest} />;
};
