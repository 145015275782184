import { createStyles, keyframes } from '@mantine/core';
import { Colors } from '@whylabs/observatory-lib';
import { WhyDogProps } from './WhyDogIdle';

const aninimationInterval = 3500;

const tailWag = keyframes({
  '0%': { transform: `rotate(0deg)` },
  '7%': { transform: `rotate(-5deg)` },
  '14%': { transform: `rotate(0deg)` },
  '21%': { transform: `rotate(-5deg)` },
  '28%': { transform: `rotate(0deg)` },
  '42%': { transform: `rotate(-15deg)` },
  '84%': { transform: `rotate(-15deg)` },
  '100%': { transform: `rotate(0deg)` },
});

const alertPawBlink = keyframes({
  '28%': { opacity: 0 },
  '35%': { opacity: 1 },
  '84%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const useStyles = createStyles({
  dogWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    marginTop: 80,
  },
  dialogWrapper: {
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    transform: 'translateX(10px)',
  },
  dogTail: {
    animation: `${tailWag} ${aninimationInterval}ms ease-in-out infinite`,
  },
  alertPaw: {
    opacity: 0,
    fill: Colors.brandRed1,
    filter: `drop-shadow(0px 0px 4px ${Colors.brandRed3}) drop-shadow(0px 0px 2px rgba(0,0,0, 0.5)) drop-shadow(0px 0px 8px ${Colors.brandRed3})`,
    animation: `${alertPawBlink} ${aninimationInterval}ms ease-in-out infinite`,
  },
});

export function WhyDogStop({ dialog }: WhyDogProps): JSX.Element {
  const { classes: styles } = useStyles();

  return (
    <div className={styles.dogWrapper}>
      <svg width="258" height="270" viewBox="0 0 258 270" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
          <ellipse
            opacity="0.1"
            cx="145.358"
            cy="242.553"
            rx="111"
            ry="19.5"
            transform="rotate(0.343267 145.358 242.553)"
            fill="#9FA9AB"
            id="ellipse824"
          />
        </g>
        <path
          d="M77.714 98.2632C71.0135 106.618 67.0667 119.034 66.347 124.462C65.9623 127.363 66.1254 133.367 75.2828 135.675C86.7295 138.559 98.8214 136.746 101.507 131.593C104.192 126.439 105.506 113.559 104.863 106.293C104.219 99.0282 102.378 93.1914 97.2902 90.4019C92.2027 87.6124 86.0896 87.8196 77.714 98.2632Z"
          fill="url(#paint0_linear_656_3)"
          id="path828"
        />
        <path
          d="M90.5664 136.798C95.8917 136.17 100.06 134.365 101.505 131.592C104.19 126.438 105.504 113.558 104.86 106.292C104.217 99.0272 102.375 93.1905 97.2879 90.401C97.1945 90.3498 97.1008 90.2996 97.0068 90.2505L93.0236 91.7037C94.0077 99.5034 94.7841 118.862 90.5664 136.798Z"
          fill="url(#paint1_linear_656_3)"
          id="path830"
        />
        <path
          d="M101.854 94.9112C99.0734 93.9807 95.1805 93.8935 91.0314 96.828C84.2815 101.602 71.6842 120.915 72.7689 134.867C66.098 132.183 66.0013 127.069 66.347 124.462C67.0667 119.034 71.0135 106.618 77.714 98.2632C86.0896 87.8196 92.2027 87.6124 97.2902 90.4019C99.2575 91.4806 100.739 93.0149 101.854 94.9112Z"
          fill="url(#paint2_linear_656_3)"
          id="path832"
        />
        <path
          d="M97.4218 90.4751C94.4047 89.8982 89.7304 90.7721 84.0894 96.2107C75.8595 104.145 68.0024 120.279 68.1138 131.622C66.0795 129.102 66.1118 126.214 66.3443 124.461C67.064 119.033 71.0109 106.617 77.7114 98.2616C86.087 87.818 92.2001 87.6108 97.2876 90.4003C97.3326 90.425 97.3773 90.4499 97.4218 90.4751Z"
          fill="url(#paint3_linear_656_3)"
          id="path834"
        />
        <path
          d="M71.5394 135.98C64.0432 135.849 47.4117 128.843 43.3504 123.351C38.7077 117.073 37.5631 102.905 40.803 95.6469C43.4584 89.6976 49.4535 87.3454 54.7222 91.1538C59.991 94.9623 73.1008 109.289 75.673 115.999C78.2453 122.709 79.0355 136.111 71.5394 135.98Z"
          fill="url(#paint4_linear_656_3)"
          id="path836"
        />
        <path
          d="M77.3305 123.251C68.2891 109.936 51.375 97.6277 42.8699 92.4114C45.9341 88.9813 50.5574 88.138 54.726 91.1512C59.9948 94.9596 73.1045 109.286 75.6768 115.996C76.4442 117.998 77.053 120.595 77.3305 123.251Z"
          fill="url(#paint5_linear_656_3)"
          id="path838"
        />
        <g opacity="0.7" id="g844">
          <path
            d="M60.6636 123.387C61.0875 123.418 61.4564 123.1 61.4876 122.676C61.5188 122.252 61.2005 121.883 60.7766 121.852L55.4789 121.462C55.055 121.43 54.6861 121.749 54.6549 122.173C54.6236 122.596 54.9419 122.965 55.3658 122.997L60.6636 123.387Z"
            fill="url(#paint6_linear_656_3)"
            id="path840"
          />
          <path
            d="M60.3335 125.929C60.7574 125.96 61.1263 125.642 61.1576 125.218C61.1888 124.794 60.8705 124.425 60.4466 124.394L55.1488 124.004C54.725 123.972 54.356 124.291 54.3248 124.715C54.2936 125.139 54.6119 125.507 55.0358 125.539L60.3335 125.929Z"
            fill="url(#paint7_linear_656_3)"
            id="path842"
          />
        </g>
        <path
          d="M40.5119 117.308C38.4087 110.343 38.3823 101.067 40.8024 95.6448C41.2702 94.5969 41.8415 93.6605 42.4943 92.8498C44.9163 90.7918 47.4497 89.9087 49.2986 90.5388C51.885 91.4202 58.6573 99.5505 56.9371 109C55.2168 118.45 48.8606 123.465 45.4698 122.386C43.1792 121.656 41.4039 119.072 40.5119 117.308Z"
          fill="url(#paint8_linear_656_3)"
          id="path846"
        />
        <path
          d="M40.357 77.9562C44.4154 78.5568 50.8745 87.2926 53.4107 92.7343C56.5617 99.4947 57.464 112.093 47.4037 116.682C41.9136 119.186 31.4357 115.484 30.1924 108.072C28.2889 96.7242 31.6574 76.6686 40.357 77.9562Z"
          fill="url(#paint9_linear_656_3)"
          id="path848"
        />
        <g id="g1710">
          <path
            opacity="0.5"
            d="m 46.6258,109.623 c 3.2224,-5.041 -2.6783,-14.7275 -8.7089,-13.6202 -5.1818,0.9514 -6.0536,9.6232 -4.2733,13.0062 2.2253,4.229 9.7598,5.655 12.9822,0.614 z"
            fill="url(#paint10_linear_656_3)"
            id="path850"
          />
          <path
            opacity="0.5"
            d="m 49.0617,94.52 c 1.8103,-2.0813 -0.2264,-6.8983 -3.0518,-6.8007 -2.4276,0.0838 -3.4113,3.9802 -2.8279,5.6435 0.7293,2.0792 4.0694,3.2385 5.8797,1.1572 z"
            fill="url(#paint11_linear_656_3)"
            id="path852"
          />
          <path
            opacity="0.5"
            d="m 42.6903,88.3627 c 1.7567,-2.1268 -0.4021,-6.8903 -3.2241,-6.7208 -2.4247,0.1457 -3.3088,4.0659 -2.6832,5.7138 0.782,2.0599 4.1506,3.1337 5.9073,1.007 z"
            fill="url(#paint12_linear_656_3)"
            id="path854"
          />
          <path
            opacity="0.5"
            d="m 35.9037,93.7148 c 1.4249,-2.098 0.1169,-6.431 -1.9771,-6.1027 -1.7992,0.2821 -2.6874,4.0079 -2.3244,5.5141 0.4537,1.8829 2.8766,2.6865 4.3015,0.5886 z"
            fill="url(#paint13_linear_656_3)"
            id="path856"
          />
        </g>
        <path
          d="M108.586 201.678C115.769 212.563 129.814 217.576 135.939 218.723C140.203 219.814 154.602 218.455 156.764 205.43C158.926 192.405 153.29 169.022 143.715 159.385C134.14 149.749 115.962 147.472 110.076 156.765C104.189 166.058 99.6068 188.073 108.586 201.678Z"
          fill="url(#paint14_linear_656_3)"
          id="path858"
        />
        <path
          d="M126.503 151.089C122.672 153.694 118.909 158.504 116.918 166.892C113.371 181.833 112.996 201.067 123.251 214.163C128.216 216.726 132.993 218.172 135.939 218.723C140.203 219.814 154.602 218.456 156.764 205.431C158.925 192.406 153.289 169.022 143.715 159.386C139.171 154.812 132.689 151.897 126.503 151.089Z"
          fill="url(#paint15_linear_656_3)"
          id="path860"
        />
        <path
          d="M145.097 177.2L124.74 174.485C123.497 174.319 122.591 173.206 122.775 171.966C123.795 165.118 126.799 160.956 129.042 158.999C129.89 158.26 131.075 158.117 132.134 158.494C140.126 161.335 145.088 168.848 147.438 174.305C148.109 175.864 146.779 177.425 145.097 177.2Z"
          fill="url(#paint16_linear_656_3)"
          id="path862"
        />
        <path
          d="M139.763 196.924C140.168 196.882 140.531 197.176 140.573 197.581L141.197 203.564C141.24 203.969 140.945 204.332 140.54 204.374C140.135 204.417 139.772 204.122 139.73 203.717L139.106 197.734C139.063 197.329 139.358 196.966 139.763 196.924Z"
          fill="url(#paint17_linear_656_3)"
          id="path864"
        />
        <path
          d="M142.503 197.137C142.908 197.095 143.271 197.389 143.313 197.795L143.937 203.777C143.979 204.183 143.685 204.545 143.28 204.588C142.875 204.63 142.512 204.336 142.469 203.93L141.845 197.948C141.803 197.542 142.097 197.18 142.503 197.137Z"
          fill="url(#paint18_linear_656_3)"
          id="path866"
        />
        <path
          d="M146.053 198.008C146.011 197.603 145.648 197.308 145.243 197.351C144.837 197.393 144.543 197.756 144.585 198.161L145.209 204.144C145.252 204.549 145.615 204.843 146.02 204.801C146.425 204.759 146.719 204.396 146.677 203.991L146.053 198.008Z"
          fill="url(#paint19_linear_656_3)"
          id="path868"
        />
        <path
          d="M122.411 237.667C118.052 237.811 110.961 234.762 107.961 233.22C106.47 230.386 103.717 223.347 104.627 217.861C105.765 211.004 108.388 208.34 118.122 207.437C127.856 206.534 143.737 211.999 149.091 214.982C154.445 217.966 157.301 225.889 153.766 228.634C150.23 231.378 127.861 237.487 122.411 237.667Z"
          fill="url(#paint20_linear_656_3)"
          id="path870"
        />
        <path
          d="M113.953 235.817C116.847 236.874 120.034 237.744 122.41 237.666C123.876 237.617 126.567 237.14 129.787 236.414C129.677 232.004 128.971 228.678 128.511 227.284L119.252 224.857C117.862 227.724 115.828 231.926 113.953 235.817Z"
          fill="url(#paint21_linear_656_3)"
          id="path872"
        />
        <path
          d="M141.855 211.858C123.995 207.905 114.448 211.097 111.764 213.435L104.775 217.027C105.994 210.786 108.792 208.303 118.12 207.438C124.926 206.806 134.738 209.288 141.855 211.858Z"
          fill="url(#paint22_linear_656_3)"
          id="path874"
        />
        <path
          d="M105.75 213.689C106.848 212.769 108.317 212.003 110.245 211.655C116.677 210.496 122.105 220.28 122.975 228.501C123.562 234.051 120.598 236.414 118.316 237.154C114.446 236.241 110.132 234.336 107.961 233.22C106.471 230.386 103.717 223.347 104.628 217.862C104.895 216.249 105.245 214.869 105.75 213.689Z"
          fill="url(#paint23_linear_656_3)"
          id="path876"
        />
        <path
          d="M118.206 235.013C116.368 237.309 103.765 238.351 96.4006 238.648C95.4683 238.686 94.6666 238.072 94.668 237.139C94.6741 233.081 96.5749 223.953 100.48 219.986C105.04 215.355 108.023 212.438 114.031 214.48C120.039 216.523 120.677 231.925 118.206 235.013Z"
          fill="url(#paint24_linear_656_3)"
          id="path878"
        />
        <path
          d="M117.218 217.454C105.148 217.786 97.9288 230.121 95.1315 238.211C94.8453 237.948 94.6673 237.579 94.668 237.139C94.6741 233.081 96.5749 223.953 100.48 219.986C105.04 215.355 108.023 212.438 114.031 214.48C115.341 214.925 116.395 216.005 117.218 217.454Z"
          fill="url(#paint25_linear_656_3)"
          id="path880"
        />
        <path
          className={styles.dogTail}
          transform-origin="184 194"
          d="M212.364 136.726L190.814 198.444L177.847 174.632L206.116 133.558C208.453 130.162 213.722 132.835 212.364 136.726Z"
          fill="url(#paint26_linear_656_3)"
        />
        <path
          d="M152.441 79.6192C147.204 72.2371 145.933 64.8223 146.756 62.0376C131.306 56.7412 101.105 47.8694 97.4692 54.7543C92.9244 63.3603 85.8451 88.9624 88.0534 122.795C90.2618 156.627 101.857 172.544 109.644 181.384C117.431 190.224 125.625 200.065 132.502 211.255C139.38 222.446 149.334 231.455 167.592 230.213C185.851 228.971 200.02 205.616 196.056 188.114C192.091 170.612 188.221 170.553 180.415 143.307C172.608 116.061 158.987 88.8468 152.441 79.6192Z"
          fill="url(#paint27_linear_656_3)"
          id="path884"
        />
        <path
          d="M87.6311 109.469C87.6176 113.744 87.7462 118.193 88.0466 122.795C90.2549 156.627 101.85 172.544 109.637 181.384C117.424 190.224 125.618 200.065 132.496 211.255C139.373 222.446 149.327 231.455 167.586 230.213C173.71 229.796 179.374 226.892 184.048 222.555L182.912 183.673C169.879 187.104 140.21 183.876 125.797 143.509C118.387 122.755 103.277 113.125 87.6311 109.469Z"
          fill="url(#paint28_linear_656_3)"
          id="path886"
        />
        <path
          opacity="0.3"
          d="M178.008 226.999C174.777 228.814 171.265 229.962 167.588 230.212C149.329 231.454 139.375 222.445 132.498 211.254C125.62 200.064 117.427 190.223 109.639 181.383C101.852 172.543 90.2571 156.626 88.0488 122.794C87.7484 118.192 87.6199 113.743 87.6334 109.468C91.8241 110.447 95.9763 111.855 99.9524 113.79C100.02 115.663 100.116 117.564 100.242 119.491C102.45 153.324 114.045 169.241 121.832 178.081C129.619 186.921 137.813 196.761 144.691 207.952C151.342 218.775 160.871 227.557 178.008 226.999Z"
          fill="url(#paint29_linear_656_3)"
          id="path888"
        />
        <path
          d="M163.03 98.5171C160.285 104.975 156.021 112.971 149.638 121.779C141.74 132.677 137.823 141.492 134.614 148.714C131.208 156.379 128.6 162.25 122.875 166.916C114.362 173.855 103.61 171.25 95.9146 159.512C92.1451 150.431 89.0787 138.557 88.0498 122.794C85.8414 88.9616 92.9207 63.3595 97.4655 54.7535C101.101 47.8686 130.981 56.7403 146.431 62.0368C145.608 64.8214 147.2 72.2363 152.437 79.6184C155.091 83.3598 158.909 90.0584 163.03 98.5171Z"
          fill="url(#paint30_linear_656_3)"
          id="path890"
        />
        <path
          d="M157.911 88.607C153.586 100.802 142.579 119.56 121.15 129.973C100.121 140.19 90.9793 119.657 87.907 99.8438C89.1117 77.5858 94.0169 61.2806 97.4641 54.7531C101.1 47.8683 131.301 56.74 146.75 62.0364C145.928 64.8211 147.199 72.2359 152.436 79.618C153.939 81.7377 155.816 84.8066 157.911 88.607Z"
          fill="url(#paint31_linear_656_3)"
          id="path892"
        />
        <path
          d="M120.721 54.2832C116.549 69.2917 109.138 100.73 108.011 124.591C107.163 142.546 110.176 159.425 113.204 170.717C107.117 170.856 100.881 167.085 95.9166 159.512C92.147 150.431 89.0806 138.557 88.0517 122.795C85.8433 88.9623 92.9226 63.3601 97.4674 54.7541C99.4153 51.0657 109.449 51.8995 120.721 54.2832Z"
          fill="url(#paint32_linear_656_3)"
          id="path894"
        />
        <path
          d="M154.259 114.991C158.165 108.86 161.009 103.272 163.03 98.5182C158.909 90.0595 155.091 83.361 152.437 79.6195C147.2 72.2374 145.929 64.8226 146.752 62.0379C146.487 61.9471 146.217 61.8552 145.943 61.7623L140.614 69.7387C140.297 76.6569 142.727 94.6433 154.259 114.991Z"
          fill="url(#paint33_linear_656_3)"
          id="path896"
        />
        <path
          d="M149.346 105.332C153.415 99.3439 156.191 93.4565 157.911 88.6068C155.816 84.8064 153.939 81.7375 152.436 79.6178C152.395 79.5601 152.354 79.5024 152.314 79.4448C147.204 72.1201 146.256 66.0843 147.072 63.3214C146.807 63.2305 146.537 63.1386 146.263 63.0458L140.613 69.737C140.347 75.5355 142.011 89.1093 149.346 105.332Z"
          fill="url(#paint34_linear_656_3)"
          id="path898"
        />
        <path
          d="M88.0083 100.458C87.9753 100.254 87.9431 100.049 87.9114 99.8455C89.1162 77.5875 94.0214 61.2823 97.4685 54.7548C98.6452 52.5266 102.773 51.9487 108.341 52.366C108.41 52.3711 108.479 52.3764 108.548 52.3819C108.566 52.3833 108.584 52.3847 108.601 52.3861C102.167 62.0378 91.9643 80.8643 88.0083 100.458Z"
          fill="url(#paint35_linear_656_3)"
          id="path900"
        />
        <path
          d="M150.117 75.6456C147.199 87.0227 136.45 104.677 109.004 111.834C102.931 104.964 92.2787 88.2006 92.0967 70.3595C93.8449 63.2088 95.8216 57.8684 97.4663 54.754C101.102 47.8692 129.938 39.6954 145.387 44.9918C144.713 47.2738 147.338 69.7108 150.117 75.6456Z"
          fill="url(#paint36_linear_656_3)"
          id="path902"
        />
        <path
          d="M108.975 111.798C109.303 109.352 110.738 103.874 114.066 100.348C118.311 95.8494 120.338 89.3581 120.947 87.5361C121.361 86.2976 126.429 70.5404 126.979 54.7523C115.616 52.3322 99.4286 51.0394 97.4678 54.7523C95.8231 57.8668 93.139 67.0444 91.3907 74.1952C91.5724 92.0068 102.897 104.907 108.975 111.798Z"
          fill="url(#paint37_linear_656_3)"
          id="path904"
        />
        <path
          d="M93.8372 75.4074C94.5769 76.0833 93.3941 76.8956 94.301 77.4754C95.9923 78.5566 97.9611 79.3841 100.158 79.6337C107.97 80.5215 122.174 76.6153 136.024 63.1214C140.643 58.6205 143.08 55.4542 145.523 51.5103C145.158 48.8945 145.279 45.8796 145.319 42.3611C145.532 23.6467 88.2761 34.4953 90.0309 55.3245C90.9697 57.9174 92.1453 63.7695 91.9896 71.8352C91.9665 73.0316 93.924 74.1171 93.8372 75.4074Z"
          fill="url(#paint38_linear_656_3)"
          id="path906"
        />
        <path
          d="M107.729 36.4292L107.729 95.8266C104.452 95.3929 92.1289 76.5472 92.1289 72.2768C92.8009 62.2871 91.1085 58.3008 90.0304 55.3233C89.3566 47.325 97.4787 40.6089 107.729 36.4292Z"
          fill="url(#paint39_linear_656_3)"
          id="path908"
        />
        <path
          d="M96.1911 75.8033C94.8265 83.172 106.748 93.7842 107.885 96.0016C107.885 88.403 115.776 83.298 120.911 77.7145C129.607 68.2581 122.232 60.6791 121.265 56.6991C111.145 54.8796 91.3469 52.1277 93.1208 55.6757C95.3383 60.1105 97.8968 66.5923 96.1911 75.8033Z"
          fill="url(#paint40_linear_656_3)"
          id="path910"
        />
        <path
          d="M93.9403 57.3869L94.3807 54.2492C98.8448 53.1858 113.161 55.2416 121.268 56.6991C122.088 60.0718 122.008 67.6257 116.567 74.3366C111.29 65.8782 101.187 61.7002 95.118 60.3258C94.7517 59.2717 94.3497 58.2935 93.9403 57.3869Z"
          fill="url(#paint41_linear_656_3)"
          id="path912"
        />
        <path
          d="M145.006 44.7031C139.668 50.4072 131.589 58.5551 126.582 62.9493C126.264 64.6814 126.468 68.1193 124.051 72.8787C128.384 70.3565 131.535 67.4936 136.022 63.1213C140.641 58.6205 143.079 55.4541 145.522 51.5102C145.24 49.4942 145.057 47.2411 145.006 44.7031Z"
          fill="url(#paint42_linear_656_3)"
          id="path914"
        />
        <path
          d="M87.8076 20.1745C83.8575 26.5935 85.0302 33.6502 86.1103 36.3762C94.7512 30.2041 101.232 20.4317 103.392 16.317C102.106 14.8254 99.1951 11.6879 97.8372 11.0707C96.1399 10.2992 92.7453 12.1508 87.8076 20.1745Z"
          fill="url(#paint43_linear_656_3)"
          id="path916"
        />
        <path
          d="M100.2 12.9772C95.9685 14.9923 93.5673 18.3087 92.8956 19.715C95.8553 23.8081 127.844 50.7594 132 44.2734C136.156 37.7875 139.053 22.7376 137.604 20.9744C136.156 19.2113 130.993 14.5515 123.436 12.0957C115.88 9.63984 105.49 10.4584 100.2 12.9772Z"
          fill="url(#paint44_linear_656_3)"
          id="path918"
        />
        <path
          d="M101.962 28.1339C108.075 22.5886 121.191 13.7795 131.491 17.2125C132.621 17.5893 133.533 17.7974 134.255 17.8625C131.727 15.8886 128.022 13.5862 123.436 12.0957C115.88 9.63984 105.49 10.4584 100.2 12.9772C95.9685 14.9923 93.5673 18.3087 92.8956 19.715C93.79 20.9519 97.335 24.276 101.962 28.1339Z"
          fill="url(#paint45_linear_656_3)"
          id="path920"
        />
        <path
          d="M117.327 10.8394C105.849 12.1071 100.608 20.2364 98.9794 25.5924C95.8538 22.8663 93.5857 20.6694 92.8956 19.715C93.5673 18.3087 95.9685 14.9923 100.2 12.9772C104.16 11.0915 110.979 10.1588 117.327 10.8394Z"
          fill="url(#paint46_linear_656_3)"
          id="path922"
        />
        <path
          d="M90.3294 31.7813C85.3235 33.563 80.3954 36.4124 78.5571 37.6143C81.3146 44.5433 89.8839 58.2528 102.102 57.6589C117.374 56.9165 117.374 63.1738 124.055 58.4013C130.737 53.6288 134.555 45.7806 132.328 42.8111C130.1 39.8415 124.691 31.7813 114.934 29.8722C105.177 27.9632 96.5867 29.5541 90.3294 31.7813Z"
          fill="url(#paint47_linear_656_3)"
          id="path924"
        />
        <path
          d="M83.9548 59.3207C81.5871 57.6583 80.0716 52.457 79.6099 50.0641L113.362 45.5933C113.383 47.2725 113.286 51.1724 112.732 53.3386C112.04 56.0463 110.654 58.3133 103.035 59.3207C95.4154 60.3282 86.9144 61.3988 83.9548 59.3207Z"
          fill="url(#paint48_linear_656_3)"
          id="path926"
        />
        <path
          d="M104.843 36.4689C97.2091 35.6686 89.6064 35.6738 86.7593 35.7764C86.4258 39.983 87.7596 48.7195 95.7625 50.0122C105.766 51.6282 111.076 49.9353 112.615 48.3963C114.154 46.8573 114.228 45.0294 114.228 41.9226C114.228 39.0848 114.385 37.4693 104.843 36.4689Z"
          fill="url(#paint49_linear_656_3)"
          id="path928"
        />
        <path
          d="M75.8188 40.1806C76.4663 37.9142 79.5061 36.808 80.9451 36.5382C87.2856 35.5939 96.7289 35.459 97.8082 36.5382C98.8874 37.6175 100.102 47.7353 96.7289 50.4334C93.3563 53.1315 80.9451 53.2664 79.1914 51.3778C77.4376 49.4891 75.0093 43.0137 75.8188 40.1806Z"
          fill="url(#paint50_linear_656_3)"
          id="path930"
        />
        <path
          d="M86.2919 52.6486C85.5809 50.9523 85.3737 49.1327 85.3971 48.0051L86.0866 35.9893C91.4733 35.6021 96.9982 35.728 97.8084 36.5382C98.8877 37.6175 100.102 47.7353 96.7292 50.4334C94.8824 51.9109 90.3252 52.6198 86.2919 52.6486Z"
          fill="url(#paint51_linear_656_3)"
          id="path932"
        />
        <path
          d="M93.763 40.3167C92.2521 43.7703 87.5574 46.8821 85.3989 48.0063C78.6536 44.4987 77.4395 39.1026 78.2489 37.2139C79.0584 35.3252 82.8357 34.7856 88.5017 34.7856C94.1677 34.7856 95.6516 35.9998 93.763 40.3167Z"
          fill="url(#paint52_linear_656_3)"
          id="path934"
        />
        <path
          d="M105.365 33.9461C97.4556 31.8303 90.2728 32.3089 87.67 32.8126C88.8665 28.2787 91.5112 20.2186 92.5188 19.2741C93.5263 18.3295 95.7302 16.8811 100.957 16.5663C106.183 16.2514 110.969 16.9441 115.629 18.2665C120.289 19.5889 122.115 21.9187 121.989 24.3746C121.863 26.8304 119.218 33.9461 117.329 35.3314C115.44 36.7167 115.251 36.5908 105.365 33.9461Z"
          fill="url(#paint53_linear_656_3)"
          id="path936"
        />
        <path
          opacity="0.45"
          d="M127.678 35.4544C132.199 25.0448 130.964 17.1849 129.781 14.5562L143.056 22.1794C144.088 32.8001 143.769 40.232 145.692 53.8868C140.347 57.0412 141.268 59.0074 134.118 59.1126C125.181 59.244 122.026 48.4664 127.678 35.4544Z"
          fill="url(#paint54_linear_656_3)"
          id="path938"
        />
        <path
          d="M133.359 20.4017C133.039 18.7384 130.453 15.9084 129.171 14.4753C133.696 15.5312 140.409 19.0008 141.54 23.9033C142.671 28.8058 130.905 52.4887 129.171 51.0556C127.436 49.6226 126.908 44.3668 129.171 38.6346C131.433 32.9024 134.048 23.978 133.359 20.4017Z"
          fill="url(#paint55_linear_656_3)"
          id="path940"
        />
        <path
          d="M139.278 16.0591C136.925 14.4903 131.358 14.2489 128.869 14.3244C131.233 15.5814 135.959 18.3821 135.959 19.5286C135.959 20.9616 134.903 31.5208 132.263 37.4038C129.624 43.2869 127.587 49.9241 129.624 51.508C131.66 53.0919 137.166 52.6393 142.898 48.6419C148.63 44.6445 155.343 39.214 154.513 35.5937C153.684 31.9734 142.219 18.0201 139.278 16.0591Z"
          fill="url(#paint56_linear_656_3)"
          id="path942"
        />
        <path
          opacity="0.3"
          d="M145.882 22.9897C146.083 26.7961 145.372 31.8939 142.124 36.6845C137.79 43.078 133.608 48.1777 129.845 51.663C132.036 53.0572 137.362 52.5034 142.899 48.6422C148.631 44.6448 155.344 39.2143 154.514 35.594C154.025 33.4594 149.839 27.7326 145.882 22.9897Z"
          fill="url(#paint57_linear_656_3)"
          id="path944"
        />
        <circle cx="110.23" cy="28.2297" r="3.22997" fill="url(#paint58_linear_656_3)" id="circle946" />
        <circle cx="110.986" cy="26.9858" r="0.986036" fill="white" id="circle948" />
        <ellipse
          cx="96.461"
          cy="26.8221"
          rx="2.71966"
          ry="3.22997"
          transform="rotate(14.9535 96.461 26.8221)"
          fill="url(#paint59_linear_656_3)"
          id="ellipse950"
        />
        <circle cx="96.986" cy="25.9858" r="0.986036" fill="white" id="circle952" />
        <path
          opacity="0.5"
          d="M115.027 20.0863C117.187 21.1002 117.614 23.082 117.6 23.6494C117.766 24.694 114.979 23.4405 113.109 22.6216C111.239 21.8027 109.822 20.4657 110.683 19.7044C111.544 18.9432 112.327 18.8189 115.027 20.0863Z"
          fill="url(#paint60_linear_656_3)"
          id="path954"
        />
        <path
          opacity="0.5"
          d="M97.1968 20.2224C95.9155 20.4461 93.7057 20.1349 92.7514 19.827C90.5616 19.2914 94.6352 17.09 96.5694 17.3816C98.5036 17.6733 98.7984 19.9428 97.1968 20.2224Z"
          fill="url(#paint61_linear_656_3)"
          id="path956"
        />
        <path
          d="M93.923 154.236C92.3472 141.118 91.7709 124.666 95.2829 112.224C97.8842 118.626 107.991 128.61 127.607 117.325C128.669 123.447 131.22 135.603 134.871 148.136C134.785 148.33 134.699 148.523 134.614 148.714C131.209 156.379 128.6 162.25 122.875 166.916C114.362 173.855 103.61 171.25 95.9149 159.512C95.2246 157.849 94.5579 156.092 93.923 154.236Z"
          fill="url(#paint62_linear_656_3)"
          id="path958"
        />
        <path
          d="M94.042 154.577C94.0028 154.463 93.9637 154.35 93.9247 154.236C92.3489 141.118 91.7727 124.666 95.2846 112.224C96.8198 116.003 100.969 121.028 108.144 122.194C108.093 123 108.049 123.8 108.011 124.591C107.163 142.545 110.176 159.425 113.204 170.716C107.498 170.847 101.66 167.541 96.862 160.887C96.5421 160.444 96.2269 159.985 95.9166 159.512C95.2687 157.951 94.6416 156.308 94.042 154.577Z"
          fill="url(#paint63_linear_656_3)"
          id="path960"
        />
        <path
          opacity="0.3"
          d="M122.695 91.8365C122.695 91.8365 116.523 94.3513 110.83 95.0359C103.593 92.7853 99.5234 89.964 99.5234 89.964C99.6048 91.0098 99.6984 91.8757 99.8056 92.4903C100.631 97.2241 103.692 101.155 110.041 104.69C116.86 102.18 120.492 98.7708 122.041 94.2219C122.237 93.6442 122.458 92.8271 122.695 91.8365Z"
          fill="url(#paint64_linear_656_3)"
          id="path962"
        />
        <g opacity="0.7" id="g970">
          <path
            d="M99.0391 104.399C99.1657 104.067 99.5372 103.901 99.8687 104.028C100.2 104.155 100.366 104.526 100.24 104.858L98.7592 108.731C98.6325 109.063 98.2611 109.229 97.9296 109.102C97.5981 108.976 97.432 108.604 97.5587 108.273L99.0391 104.399Z"
            fill="url(#paint65_linear_656_3)"
            id="path964"
          />
          <path
            d="M101.663 106.259C101.331 106.132 100.96 106.298 100.833 106.63L99.3528 110.504C99.2261 110.835 99.3922 111.207 99.7237 111.333C100.055 111.46 100.427 111.294 100.553 110.963L102.034 107.089C102.16 106.757 101.994 106.386 101.663 106.259Z"
            fill="url(#paint66_linear_656_3)"
            id="path966"
          />
          <path
            d="M103.457 108.49C103.126 108.364 102.754 108.53 102.628 108.861L101.147 112.735C101.021 113.066 101.187 113.438 101.518 113.565C101.85 113.691 102.221 113.525 102.348 113.194L103.828 109.32C103.955 108.988 103.789 108.617 103.457 108.49Z"
            fill="url(#paint67_linear_656_3)"
            id="path968"
          />
        </g>
        <path
          d="M163.252 211.193C170.435 222.077 184.481 227.091 190.606 228.237C194.87 229.328 209.269 227.97 211.431 214.945C213.592 201.92 207.956 178.536 198.382 168.9C188.807 159.263 170.629 156.987 164.742 166.28C158.856 175.573 154.274 197.587 163.252 211.193Z"
          fill="url(#paint68_linear_656_3)"
          id="path972"
        />
        <path
          d="M181.172 160.604C177.341 163.209 173.578 168.019 171.586 176.407C168.039 191.348 167.664 210.582 177.919 223.678C182.884 226.241 187.662 227.687 190.607 228.238C194.871 229.329 209.271 227.971 211.432 214.946C213.594 201.921 207.958 178.537 198.383 168.901C193.839 164.327 187.358 161.412 181.172 160.604Z"
          fill="url(#paint69_linear_656_3)"
          id="path974"
        />
        <path
          opacity="0.5"
          d="M199.763 186.715L179.406 184C178.163 183.834 177.257 182.721 177.441 181.481C178.461 174.633 181.465 170.471 183.708 168.514C184.556 167.775 185.741 167.632 186.8 168.009C194.792 170.85 199.754 178.363 202.104 183.82C202.775 185.379 201.445 186.94 199.763 186.715Z"
          fill="url(#paint70_linear_656_3)"
          id="path976"
        />
        <g opacity="0.7" id="g984">
          <path
            d="M194.429 206.44C194.834 206.397 195.197 206.692 195.239 207.097L195.863 213.08C195.906 213.485 195.611 213.848 195.206 213.89C194.801 213.932 194.438 213.638 194.396 213.233L193.772 207.25C193.729 206.845 194.024 206.482 194.429 206.44Z"
            fill="url(#paint71_linear_656_3)"
            id="path978"
          />
          <path
            d="M197.169 206.653C197.574 206.611 197.937 206.905 197.979 207.31L198.603 213.293C198.646 213.698 198.351 214.061 197.946 214.103C197.541 214.146 197.178 213.851 197.135 213.446L196.511 207.463C196.469 207.058 196.763 206.695 197.169 206.653Z"
            fill="url(#paint72_linear_656_3)"
            id="path980"
          />
          <path
            d="M200.719 207.524C200.677 207.118 200.314 206.824 199.909 206.866C199.503 206.909 199.209 207.271 199.251 207.677L199.875 213.659C199.918 214.065 200.281 214.359 200.686 214.317C201.091 214.274 201.386 213.912 201.343 213.506L200.719 207.524Z"
            fill="url(#paint73_linear_656_3)"
            id="path982"
          />
        </g>
        <path
          d="M177.082 247.181C172.722 247.324 165.632 244.276 162.631 242.734C161.141 239.9 158.387 232.861 159.298 227.375C160.436 220.518 163.059 217.854 172.793 216.951C182.526 216.048 198.408 221.512 203.762 224.496C209.116 227.48 211.972 235.403 208.436 238.147C204.901 240.892 182.532 247.001 177.082 247.181Z"
          fill="url(#paint74_linear_656_3)"
          id="path986"
        />
        <path
          d="M168.622 245.331C171.516 246.388 174.703 247.258 177.079 247.18C178.545 247.132 181.236 246.654 184.456 245.928C184.346 241.518 183.64 238.192 183.18 236.798L173.921 234.371C172.531 237.239 170.497 241.441 168.622 245.331Z"
          fill="url(#paint75_linear_656_3)"
          id="path988"
        />
        <path
          d="M196.527 221.373C178.667 217.42 169.12 220.612 166.436 222.95L159.447 226.542C160.666 220.301 163.464 217.818 172.792 216.953C179.598 216.321 189.41 218.803 196.527 221.373Z"
          fill="url(#paint76_linear_656_3)"
          id="path990"
        />
        <path
          d="M160.416 223.203C161.514 222.284 162.983 221.518 164.911 221.17C171.343 220.01 176.771 229.794 177.641 238.015C178.228 243.566 175.264 245.929 172.982 246.669C169.112 245.756 164.799 243.851 162.627 242.735C161.137 239.901 158.383 232.861 159.294 227.376C159.561 225.764 159.911 224.383 160.416 223.203Z"
          fill="url(#paint77_linear_656_3)"
          id="path992"
        />
        <path
          d="M172.87 244.525C171.033 246.821 158.43 247.864 151.066 248.16C150.133 248.198 149.331 247.584 149.333 246.651C149.339 242.594 151.24 233.465 155.145 229.498C159.705 224.867 162.688 221.95 168.696 223.992C174.704 226.035 175.342 241.437 172.87 244.525Z"
          fill="url(#paint78_linear_656_3)"
          id="path994"
        />
        <path
          d="M171.883 226.966C159.813 227.298 152.594 239.633 149.796 247.724C149.51 247.46 149.332 247.091 149.333 246.651C149.339 242.594 151.24 233.465 155.145 229.498C159.705 224.867 162.688 221.95 168.696 223.992C170.006 224.438 171.06 225.517 171.883 226.966Z"
          fill="url(#paint79_linear_656_3)"
          id="path996"
        />
        <path
          d="M120.926 162.019C118.013 179.975 121.289 192.493 123.291 196.507C141.585 199.405 152.669 186.799 156.583 182.474C160.498 178.149 165.107 170.3 165.095 148.834C165.084 127.367 161.788 123.743 156.303 121.211C150.818 118.679 145.363 118.465 139.36 119.891C133.356 121.318 124.568 139.574 120.926 162.019Z"
          fill="url(#paint80_linear_656_3)"
          id="path998"
        />
        <path
          d="M156.26 121.193C143.95 120.718 135.438 147.343 132.573 163.769C129.531 181.21 133.9 189.968 139.61 192.307C141.48 193.073 143.689 192.735 145.833 191.89C150.943 188.711 154.465 184.815 156.404 182.671C156.464 182.604 156.523 182.538 156.581 182.475C160.495 178.15 165.105 170.301 165.093 148.834C165.082 127.367 161.786 123.743 156.301 121.212C156.287 121.205 156.273 121.199 156.26 121.193Z"
          fill="url(#paint81_linear_656_3)"
          id="path1000"
        />
        <path
          d="M147.826 125.276C150.384 122.626 153.205 121.075 156.26 121.193L156.301 121.212C161.786 123.743 165.082 127.367 165.093 148.834C165.105 170.301 160.495 178.15 156.581 182.475C156.523 182.538 156.464 182.604 156.404 182.671C155.436 183.741 154.075 185.247 152.319 186.882C159.139 156.088 153.109 134.721 147.826 125.276Z"
          fill="url(#paint82_linear_656_3)"
          id="path1002"
        />
        <path
          d="M130.367 196.887C122.698 188.602 125.391 162.839 133.645 139.227C137.989 126.798 142.752 121.174 146.473 119.01C144.167 118.999 141.809 119.308 139.36 119.89C133.356 121.317 124.568 139.573 120.926 162.018C118.013 179.974 121.289 192.491 123.291 196.506C125.784 196.901 128.142 197.008 130.367 196.887Z"
          fill="url(#paint83_linear_656_3)"
          id="path1004"
        />
        <path
          d="M117.968 242.807L112.696 242.396C107.377 243.384 97.3638 242.007 99.8715 228.595C103.006 211.83 112.392 194.637 119.265 188.589C126.137 182.54 133.477 184.574 133.116 196.251C132.756 207.929 129.857 226.358 128.413 230.818C127.257 234.386 120.968 240.297 117.968 242.807Z"
          fill="url(#paint84_linear_656_3)"
          id="path1006"
        />
        <g opacity="0.7" id="g1012">
          <path
            d="M122.04 227.501C121.684 227.236 121.609 226.733 121.873 226.377C122.137 226.021 122.64 225.946 122.997 226.21L126.294 228.654C126.651 228.918 126.725 229.421 126.461 229.778C126.197 230.134 125.694 230.209 125.338 229.945L122.04 227.501Z"
            fill="url(#paint85_linear_656_3)"
            id="path1008"
          />
          <path
            d="M120.931 229.601C120.574 229.337 120.5 228.834 120.764 228.478C121.028 228.121 121.531 228.047 121.887 228.311L125.185 230.755C125.541 231.019 125.616 231.522 125.352 231.878C125.088 232.235 124.585 232.31 124.228 232.045L120.931 229.601Z"
            fill="url(#paint86_linear_656_3)"
            id="path1010"
          />
        </g>
        <path
          d="M127.571 185.361C122.549 191.022 113.689 204.55 107.762 225.917C105.869 232.744 103.862 237.076 101.894 239.666C99.913 237.544 98.8477 234.074 99.8722 228.595C103.007 211.83 112.393 194.637 119.265 188.588C122.154 186.046 125.124 184.932 127.571 185.361Z"
          fill="url(#paint87_linear_656_3)"
          id="path1014"
        />
        <path
          d="M112.695 242.396L117.967 242.806C117.967 242.806 117.967 242.806 117.967 242.806C118.633 238.164 118.479 227.776 112.531 223.362C105.096 217.845 101.036 223.016 100.165 227.154C100.148 227.236 100.127 227.321 100.102 227.409C100.021 227.804 99.9437 228.199 99.8698 228.594C97.3622 242.006 107.375 243.384 112.695 242.396Z"
          fill="url(#paint88_linear_656_3)"
          id="path1016"
        />
        <path
          d="M112.229 227.881C115.277 233.568 114.872 240.859 114.289 243.794C107.913 247.768 94.0851 247.649 93.4777 244.515C92.8703 241.381 95.435 231.682 99.1101 226.859C102.785 222.036 108.418 220.772 112.229 227.881Z"
          fill="url(#paint89_linear_656_3)"
          id="path1018"
        />
        <defs id="defs1620">
          <linearGradient
            id="paint0_linear_656_3"
            x1="81.082"
            y1="133.018"
            x2="94.9228"
            y2="94.6898"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#273547" id="stop1020" />
            <stop offset="1" stopColor="#547DAC" id="stop1022" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_656_3"
            x1="90.8403"
            y1="84.0294"
            x2="80.8997"
            y2="109.471"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#18969B" id="stop1025" />
            <stop offset="1" stopColor="#166879" id="stop1027" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_656_3"
            x1="71.7874"
            y1="127.122"
            x2="73.5283"
            y2="101.638"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#273547" id="stop1030" />
            <stop offset="1" stopColor="#547DAC" id="stop1032" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_656_3"
            x1="73.3449"
            y1="140.193"
            x2="76.5007"
            y2="99.9561"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#273547" id="stop1035" />
            <stop offset="1" stopColor="#6494CA" id="stop1037" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_656_3"
            x1="101.61"
            y1="80.0183"
            x2="72.079"
            y2="129.884"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C5F0FB" id="stop1040" />
            <stop offset="1" stopColor="#FDFEFF" id="stop1042" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_656_3"
            x1="63.8491"
            y1="92.6251"
            x2="57.5037"
            y2="118.363"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C5F0FB" id="stop1045" />
            <stop offset="1" stopColor="#FDFEFF" id="stop1047" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_656_3"
            x1="54.1161"
            y1="123.368"
            x2="61.0009"
            y2="125.065"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1050" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1052" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1054" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1056" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1058" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1060" />
            <stop offset="1" stopColor="#79DFDC" id="stop1062" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_656_3"
            x1="54.1161"
            y1="123.368"
            x2="61.0009"
            y2="125.065"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1065" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1067" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1069" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1071" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1073" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1075" />
            <stop offset="1" stopColor="#79DFDC" id="stop1077" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_656_3"
            x1="51.5336"
            y1="91.0762"
            x2="43.9079"
            y2="122.007"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#51B6F7" id="stop1080" />
            <stop offset="1" stopColor="#5BB0F7" stopOpacity="0.71" id="stop1082" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_656_3"
            x1="50.807"
            y1="81.2391"
            x2="43.0308"
            y2="117.991"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5DB3D2" id="stop1085" />
            <stop offset="1" stopColor="#38678F" id="stop1087" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_656_3"
            x1="47.9018"
            y1="107.622"
            x2="32.9831"
            y2="105.358"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1090" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1092" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1094" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1096" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1098" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1100" />
            <stop offset="1" stopColor="#79DFDC" id="stop1102" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_656_3"
            x1="49.7789"
            y1="93.6933"
            x2="43.1275"
            y2="91.6529"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1105" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1107" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1109" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1111" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1113" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1115" />
            <stop offset="1" stopColor="#79DFDC" id="stop1117" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_656_3"
            x1="43.3862"
            y1="87.518"
            x2="36.685"
            y2="85.6477"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1120" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1122" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1124" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1126" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1128" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1130" />
            <stop offset="1" stopColor="#79DFDC" id="stop1132" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_656_3"
            x1="36.4683"
            y1="92.8816"
            x2="31.6323"
            y2="91.532"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1135" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1137" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1139" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1141" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1143" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1145" />
            <stop offset="1" stopColor="#79DFDC" id="stop1147" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_656_3"
            x1="132.616"
            y1="210.35"
            x2="126.093"
            y2="157.055"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#273547" id="stop1150" />
            <stop offset="1" stopColor="#547DAC" id="stop1152" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_656_3"
            x1="139.038"
            y1="152.065"
            x2="121.281"
            y2="179.344"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4D98B4" id="stop1155" />
            <stop offset="1" stopColor="#1A4367" id="stop1157" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_656_3"
            x1="136.352"
            y1="158.701"
            x2="134.973"
            y2="176.415"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#51F78F" id="stop1160" />
            <stop offset="1" stopColor="#5BF7F3" stopOpacity="0.71" id="stop1162" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_656_3"
            x1="143.178"
            y1="197.178"
            x2="142.604"
            y2="204.547"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#51F78F" id="stop1165" />
            <stop offset="1" stopColor="#5BF7F3" stopOpacity="0.71" id="stop1167" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_656_3"
            x1="143.178"
            y1="197.178"
            x2="142.604"
            y2="204.547"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#51F78F" id="stop1170" />
            <stop offset="1" stopColor="#5BF7F3" stopOpacity="0.71" id="stop1172" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_656_3"
            x1="143.178"
            y1="197.178"
            x2="142.604"
            y2="204.547"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#51F78F" id="stop1175" />
            <stop offset="1" stopColor="#5BF7F3" stopOpacity="0.71" id="stop1177" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_656_3"
            x1="146.814"
            y1="230.733"
            x2="106.711"
            y2="211.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1E699A" id="stop1180" />
            <stop offset="1" stopColor="#FDFEFF" id="stop1182" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_656_3"
            x1="119.466"
            y1="225.496"
            x2="119.039"
            y2="237.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5DB3D2" id="stop1185" />
            <stop offset="1" stopColor="#38678F" id="stop1187" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_656_3"
            x1="134.103"
            y1="211"
            x2="152.766"
            y2="209.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C5F0FB" id="stop1190" />
            <stop offset="1" stopColor="#FDFEFF" id="stop1192" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_656_3"
            x1="114.771"
            y1="211.815"
            x2="112.831"
            y2="236.727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#51F78F" id="stop1195" />
            <stop offset="1" stopColor="#5BF7F3" stopOpacity="0.71" id="stop1197" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_656_3"
            x1="100.845"
            y1="216.71"
            x2="101.619"
            y2="234.19"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#18969B" id="stop1200" />
            <stop offset="1" stopColor="#166879" id="stop1202" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_656_3"
            x1="102.635"
            y1="214.447"
            x2="102.317"
            y2="238.946"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5DB3D2" id="stop1205" />
            <stop offset="1" stopColor="#38678F" id="stop1207" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_656_3"
            x1="203.24"
            y1="134.894"
            x2="179.947"
            y2="164.059"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5DB3D2" id="stop1210" />
            <stop offset="1" stopColor="#38678F" id="stop1212" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_656_3"
            x1="96.0416"
            y1="126.208"
            x2="204.93"
            y2="189.952"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFD3F0" id="stop1215" />
            <stop offset="1" stopColor="#FEFCFF" id="stop1217" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_656_3"
            x1="99.6113"
            y1="167.913"
            x2="205.632"
            y2="187.136"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8DBBE0" id="stop1220" />
            <stop offset="1" stopColor="#FEFCFF" id="stop1222" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_656_3"
            x1="213.854"
            y1="117.393"
            x2="194.231"
            y2="197.195"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#18969B" stopOpacity="0" id="stop1225" />
            <stop offset="1" stopColor="#0D5462" stopOpacity="0.51" id="stop1227" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_656_3"
            x1="105.476"
            y1="156.47"
            x2="207.656"
            y2="108.234"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#BBDFF8" id="stop1230" />
            <stop offset="1" stopColor="#B6DDF9" id="stop1232" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_656_3"
            x1="106.347"
            y1="123.077"
            x2="185.565"
            y2="71.0213"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#92C6EB" id="stop1235" />
            <stop offset="1" stopColor="#B6DDF9" id="stop1237" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_656_3"
            x1="92.5846"
            y1="155.462"
            x2="144.059"
            y2="147.536"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#67A5D1" id="stop1240" />
            <stop offset="1" stopColor="#B6DDF9" id="stop1242" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_656_3"
            x1="169.226"
            y1="89.8033"
            x2="128.773"
            y2="156.186"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DDF1FD" id="stop1245" />
            <stop offset="1" stopColor="#9FDEF3" id="stop1247" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_656_3"
            x1="153.488"
            y1="71.2938"
            x2="131.909"
            y2="115.961"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1250" />
            <stop offset="1" stopColor="#8AD5ED" id="stop1252" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_656_3"
            x1="99.2591"
            y1="45.7548"
            x2="92.2355"
            y2="100.787"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#88D6EF" id="stop1255" />
            <stop offset="1" stopColor="#51B1CD" id="stop1257" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_656_3"
            x1="140.701"
            y1="53.6678"
            x2="130.544"
            y2="100.049"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2F94BA" id="stop1260" />
            <stop offset="1" stopColor="#165879" id="stop1262" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_656_3"
            x1="131.5"
            y1="51.3428"
            x2="117.338"
            y2="91.765"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#18969B" id="stop1265" />
            <stop offset="1" stopColor="#0D5462" id="stop1267" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_656_3"
            x1="118.239"
            y1="31.8119"
            x2="118.239"
            y2="79.7535"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#88D6EF" id="stop1270" />
            <stop offset="1" stopColor="#6BC7E1" id="stop1272" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_656_3"
            x1="96.3511"
            y1="71.9465"
            x2="104.498"
            y2="47.0759"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1275" />
            <stop offset="1" stopColor="#8AD5ED" id="stop1277" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_656_3"
            x1="100.36"
            y1="91.6666"
            x2="116.429"
            y2="63.2546"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1280" />
            <stop offset="1" stopColor="#8AD5ED" id="stop1282" />
          </linearGradient>
          <linearGradient
            id="paint41_linear_656_3"
            x1="115.092"
            y1="96.7112"
            x2="105.898"
            y2="65.4869"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E2F3FE" id="stop1285" />
            <stop offset="1" stopColor="#78C9E4" id="stop1287" />
          </linearGradient>
          <linearGradient
            id="paint42_linear_656_3"
            x1="136.448"
            y1="39.857"
            x2="126.111"
            y2="75.7908"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#88D6EF" id="stop1290" />
            <stop offset="1" stopColor="#51B1CD" id="stop1292" />
          </linearGradient>
          <linearGradient
            id="paint43_linear_656_3"
            x1="94.2297"
            y1="34.2691"
            x2="93.0925"
            y2="4.82948"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C39354" id="stop1295" />
            <stop offset="1" stopColor="#FBE3AE" id="stop1297" />
          </linearGradient>
          <linearGradient
            id="paint44_linear_656_3"
            x1="104.47"
            y1="45.2759"
            x2="134.773"
            y2="27.8801"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1300" />
            <stop offset="1" stopColor="#8AD5ED" id="stop1302" />
          </linearGradient>
          <linearGradient
            id="paint45_linear_656_3"
            x1="143.634"
            y1="-1.00052"
            x2="126.721"
            y2="14.9368"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1305" />
            <stop offset="1" stopColor="#79CDE8" id="stop1307" />
          </linearGradient>
          <linearGradient
            id="paint46_linear_656_3"
            x1="99.1676"
            y1="25.5924"
            x2="113.546"
            y2="15.2404"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1310" />
            <stop offset="1" stopColor="#8AD5ED" id="stop1312" />
          </linearGradient>
          <linearGradient
            id="paint47_linear_656_3"
            x1="92.5245"
            y1="60.2118"
            x2="123.054"
            y2="36.712"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1315" />
            <stop offset="1" stopColor="#8AD5ED" id="stop1317" />
          </linearGradient>
          <linearGradient
            id="paint48_linear_656_3"
            x1="92.0701"
            y1="60.5108"
            x2="103.89"
            y2="48.1484"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#88D6EF" id="stop1320" />
            <stop offset="1" stopColor="#50B7D4" id="stop1322" />
          </linearGradient>
          <linearGradient
            id="paint49_linear_656_3"
            x1="100.471"
            y1="35.7285"
            x2="100.471"
            y2="50.6678"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DDF1FD" id="stop1325" />
            <stop offset="1" stopColor="#9FDEF3" id="stop1327" />
          </linearGradient>
          <linearGradient
            id="paint50_linear_656_3"
            x1="76.9711"
            y1="42.1127"
            x2="87.2984"
            y2="50.9244"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1330" />
            <stop offset="1" stopColor="#8AD5ED" id="stop1332" />
          </linearGradient>
          <linearGradient
            id="paint51_linear_656_3"
            x1="80.0363"
            y1="62.8011"
            x2="96.8936"
            y2="47.8595"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E4F4FE" id="stop1335" />
            <stop offset="1" stopColor="#8AD5ED" id="stop1337" />
          </linearGradient>
          <linearGradient
            id="paint52_linear_656_3"
            x1="86.284"
            y1="34.7856"
            x2="86.284"
            y2="48.0063"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4D98B4" id="stop1340" />
            <stop offset="1" stopColor="#38678F" id="stop1342" />
          </linearGradient>
          <linearGradient
            id="paint53_linear_656_3"
            x1="106.779"
            y1="31.4576"
            x2="94.9118"
            y2="17.1204"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D1A467" id="stop1345" />
            <stop offset="1" stopColor="#F9D5B6" id="stop1347" />
          </linearGradient>
          <linearGradient
            id="paint54_linear_656_3"
            x1="135.989"
            y1="14.5562"
            x2="135.989"
            y2="59.1138"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4C8EA2" id="stop1350" />
            <stop offset="1" stopColor="#408CA1" id="stop1352" />
          </linearGradient>
          <linearGradient
            id="paint55_linear_656_3"
            x1="134.641"
            y1="28.524"
            x2="128.912"
            y2="5.52195"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C08D49" id="stop1355" />
            <stop offset="1" stopColor="#FEF9EE" id="stop1357" />
          </linearGradient>
          <linearGradient
            id="paint56_linear_656_3"
            x1="141.689"
            y1="49.2101"
            x2="139.886"
            y2="5.23568"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C39354" id="stop1360" />
            <stop offset="1" stopColor="#FBE3AE" id="stop1362" />
          </linearGradient>
          <linearGradient
            id="paint57_linear_656_3"
            x1="159.273"
            y1="52.3585"
            x2="138.805"
            y2="33.5145"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FBF2C7" id="stop1365" />
            <stop offset="1" stopColor="#FEF2EE" id="stop1367" />
          </linearGradient>
          <linearGradient
            id="paint58_linear_656_3"
            x1="110.23"
            y1="24.9998"
            x2="110.23"
            y2="31.4597"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#17504F" id="stop1370" />
            <stop offset="1" stopColor="#082C34" id="stop1372" />
          </linearGradient>
          <linearGradient
            id="paint59_linear_656_3"
            x1="96.461"
            y1="23.5921"
            x2="96.461"
            y2="30.0521"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#17504F" id="stop1375" />
            <stop offset="1" stopColor="#082C34" id="stop1377" />
          </linearGradient>
          <linearGradient
            id="paint60_linear_656_3"
            x1="113.533"
            y1="18.8695"
            x2="114.425"
            y2="24.4938"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#17504F" id="stop1380" />
            <stop offset="1" stopColor="#082C34" id="stop1382" />
          </linearGradient>
          <linearGradient
            id="paint61_linear_656_3"
            x1="96.0978"
            y1="17.1002"
            x2="94.6021"
            y2="20.637"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#17504F" id="stop1385" />
            <stop offset="1" stopColor="#082C34" id="stop1387" />
          </linearGradient>
          <linearGradient
            id="paint62_linear_656_3"
            x1="102.884"
            y1="163.34"
            x2="156.921"
            y2="132.222"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#BBDFF8" id="stop1390" />
            <stop offset="1" stopColor="#B6DDF9" id="stop1392" />
          </linearGradient>
          <linearGradient
            id="paint63_linear_656_3"
            x1="97.078"
            y1="162.888"
            x2="134.077"
            y2="152.317"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#92C6EB" id="stop1395" />
            <stop offset="1" stopColor="#B6DDF9" id="stop1397" />
          </linearGradient>
          <linearGradient
            id="paint64_linear_656_3"
            x1="129.077"
            y1="92.0354"
            x2="107.712"
            y2="98.698"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#18779B" stopOpacity="0" id="stop1400" />
            <stop offset="1" stopColor="#11859C" stopOpacity="0.51" id="stop1402" />
          </linearGradient>
          <linearGradient
            id="paint65_linear_656_3"
            x1="103.993"
            y1="110.174"
            x2="97.6038"
            y2="109.676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1405" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1407" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1409" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1411" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1413" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1415" />
            <stop offset="1" stopColor="#79DFDC" id="stop1417" />
          </linearGradient>
          <linearGradient
            id="paint66_linear_656_3"
            x1="103.993"
            y1="110.174"
            x2="97.6038"
            y2="109.676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1420" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1422" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1424" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1426" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1428" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1430" />
            <stop offset="1" stopColor="#79DFDC" id="stop1432" />
          </linearGradient>
          <linearGradient
            id="paint67_linear_656_3"
            x1="103.993"
            y1="110.174"
            x2="97.6038"
            y2="109.676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1435" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1437" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1439" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1441" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1443" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1445" />
            <stop offset="1" stopColor="#79DFDC" id="stop1447" />
          </linearGradient>
          <linearGradient
            id="paint68_linear_656_3"
            x1="193.974"
            y1="156.084"
            x2="160.053"
            y2="221.925"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4CA4C3" id="stop1450" />
            <stop offset="1" stopColor="#305E85" id="stop1452" />
          </linearGradient>
          <linearGradient
            id="paint69_linear_656_3"
            x1="192.134"
            y1="220.248"
            x2="183.105"
            y2="167.958"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="stop1455" />
            <stop offset="1" stopColor="#4980B7" id="stop1457" />
          </linearGradient>
          <linearGradient
            id="paint70_linear_656_3"
            x1="176.893"
            y1="178.137"
            x2="201.934"
            y2="180.087"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1460" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1462" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1464" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1466" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1468" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1470" />
            <stop offset="1" stopColor="#79DFDC" id="stop1472" />
          </linearGradient>
          <linearGradient
            id="paint71_linear_656_3"
            x1="193.215"
            y1="210.918"
            x2="201.28"
            y2="211.546"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1475" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1477" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1479" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1481" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1483" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1485" />
            <stop offset="1" stopColor="#79DFDC" id="stop1487" />
          </linearGradient>
          <linearGradient
            id="paint72_linear_656_3"
            x1="193.215"
            y1="210.918"
            x2="201.28"
            y2="211.546"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1490" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1492" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1494" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1496" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1498" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1500" />
            <stop offset="1" stopColor="#79DFDC" id="stop1502" />
          </linearGradient>
          <linearGradient
            id="paint73_linear_656_3"
            x1="193.215"
            y1="210.918"
            x2="201.28"
            y2="211.546"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1505" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1507" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1509" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1511" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1513" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1515" />
            <stop offset="1" stopColor="#79DFDC" id="stop1517" />
          </linearGradient>
          <linearGradient
            id="paint74_linear_656_3"
            x1="158.033"
            y1="242.998"
            x2="214.804"
            y2="235.013"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C5F0FB" id="stop1520" />
            <stop offset="1" stopColor="#FDFEFF" id="stop1522" />
          </linearGradient>
          <linearGradient
            id="paint75_linear_656_3"
            x1="174.135"
            y1="235.011"
            x2="173.708"
            y2="247.094"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5DB3D2" id="stop1525" />
            <stop offset="1" stopColor="#38678F" id="stop1527" />
          </linearGradient>
          <linearGradient
            id="paint76_linear_656_3"
            x1="188.775"
            y1="220.515"
            x2="207.438"
            y2="219.275"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C5F0FB" id="stop1530" />
            <stop offset="1" stopColor="#FDFEFF" id="stop1532" />
          </linearGradient>
          <linearGradient
            id="paint77_linear_656_3"
            x1="169.437"
            y1="221.329"
            x2="167.497"
            y2="246.242"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#51B6F7" id="stop1535" />
            <stop offset="1" stopColor="#5BB0F7" stopOpacity="0.71" id="stop1537" />
          </linearGradient>
          <linearGradient
            id="paint78_linear_656_3"
            x1="158.261"
            y1="224.052"
            x2="157.743"
            y2="248.895"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5DB3D2" id="stop1540" />
            <stop offset="1" stopColor="#38678F" id="stop1542" />
          </linearGradient>
          <linearGradient
            id="paint79_linear_656_3"
            x1="157.299"
            y1="223.96"
            x2="154.381"
            y2="240.142"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4CA4C3" id="stop1545" />
            <stop offset="1" stopColor="#305E85" id="stop1547" />
          </linearGradient>
          <linearGradient
            id="paint80_linear_656_3"
            x1="137.528"
            y1="187.966"
            x2="155.095"
            y2="149.291"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="stop1550" />
            <stop offset="1" stopColor="#4980B7" id="stop1552" />
          </linearGradient>
          <linearGradient
            id="paint81_linear_656_3"
            x1="144.523"
            y1="184.032"
            x2="168.122"
            y2="133.755"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="stop1555" />
            <stop offset="1" stopColor="#4980B7" id="stop1557" />
          </linearGradient>
          <linearGradient
            id="paint82_linear_656_3"
            x1="152.082"
            y1="178.758"
            x2="178.261"
            y2="139.783"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#273547" id="stop1560" />
            <stop offset="1" stopColor="#547DAC" id="stop1562" />
          </linearGradient>
          <linearGradient
            id="paint83_linear_656_3"
            x1="106.32"
            y1="202.278"
            x2="138.423"
            y2="145.935"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#22364C" id="stop1565" />
            <stop offset="1" stopColor="#5B97D3" id="stop1567" />
          </linearGradient>
          <linearGradient
            id="paint84_linear_656_3"
            x1="97.3755"
            y1="152.294"
            x2="131.192"
            y2="218.252"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C5F0FB" id="stop1570" />
            <stop offset="1" stopColor="#FDFEFF" id="stop1572" />
          </linearGradient>
          <linearGradient
            id="paint85_linear_656_3"
            x1="126.754"
            y1="230.081"
            x2="120.723"
            y2="229.611"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1575" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1577" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1579" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1581" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1583" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1585" />
            <stop offset="1" stopColor="#79DFDC" id="stop1587" />
          </linearGradient>
          <linearGradient
            id="paint86_linear_656_3"
            x1="126.754"
            y1="230.081"
            x2="120.723"
            y2="229.611"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AFE2E0" id="stop1590" />
            <stop offset="0.237754" stopColor="#DCF9F8" id="stop1592" />
            <stop offset="0.358119" stopColor="#DDFAFA" id="stop1594" />
            <stop offset="0.442028" stopColor="#EAFEFD" id="stop1596" />
            <stop offset="0.532106" stopColor="#D4F9F8" id="stop1598" />
            <stop offset="0.733588" stopColor="#BFF7F5" id="stop1600" />
            <stop offset="1" stopColor="#79DFDC" id="stop1602" />
          </linearGradient>
          <linearGradient
            id="paint87_linear_656_3"
            x1="115.41"
            y1="184.386"
            x2="111.049"
            y2="240.379"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C5F0FB" id="stop1605" />
            <stop offset="1" stopColor="#FDFEFF" id="stop1607" />
          </linearGradient>
          <linearGradient
            id="paint88_linear_656_3"
            x1="110"
            y1="221.212"
            x2="108.336"
            y2="242.574"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#51B6F7" id="stop1610" />
            <stop offset="1" stopColor="#5BB0F7" stopOpacity="0.71" id="stop1612" />
          </linearGradient>
          <linearGradient
            id="paint89_linear_656_3"
            x1="109.674"
            y1="224.391"
            x2="106.434"
            y2="247.497"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5DB3D2" id="stop1615" />
            <stop offset="1" stopColor="#38678F" id="stop1617" />
          </linearGradient>
          <filter
            id="filter3204"
            colorInterpolationFilters="sRGB"
            y="-0.38466157"
            height="1.7693231"
            x="-0.65842758"
            width="2.3168552"
          >
            <feGaussianBlur stdDeviation="5" result="result6" id="feGaussianBlur3194" />
            <feComposite result="result8" in="SourceGraphic" operator="atop" in2="result6" id="feComposite3196" />
            <feComposite result="result9" operator="over" in2="SourceAlpha" in="result8" id="feComposite3198" />
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0 " result="result10" id="feColorMatrix3200" />
            <feBlend in="result10" mode="normal" in2="result6" id="feBlend3202" />
          </filter>
        </defs>
        <g className={styles.alertPaw}>
          <path
            d="m 46.6258,109.623 c 3.2224,-5.041 -2.6783,-14.7275 -8.7089,-13.6202 -5.1818,0.9514 -6.0536,9.6232 -4.2733,13.0062 2.2253,4.229 9.7598,5.655 12.9822,0.614 z"
            id="path1712"
          />
          <path
            d="m 49.0617,94.52 c 1.8103,-2.0813 -0.2264,-6.8983 -3.0518,-6.8007 -2.4276,0.0838 -3.4113,3.9802 -2.8279,5.6435 0.7293,2.0792 4.0694,3.2385 5.8797,1.1572 z"
            id="path1714"
          />
          <path
            d="m 42.6903,88.3627 c 1.7567,-2.1268 -0.4021,-6.8903 -3.2241,-6.7208 -2.4247,0.1457 -3.3088,4.0659 -2.6832,5.7138 0.782,2.0599 4.1506,3.1337 5.9073,1.007 z"
            id="path1716"
          />
          <path
            d="m 35.9037,93.7148 c 1.4249,-2.098 0.1169,-6.431 -1.9771,-6.1027 -1.7992,0.2821 -2.6874,4.0079 -2.3244,5.5141 0.4537,1.8829 2.8766,2.6865 4.3015,0.5886 z"
            id="path1718"
          />
        </g>
      </svg>

      {dialog && <div className={styles.dialogWrapper}>{dialog}</div>}
    </div>
  );
}
