import axios from 'axios';
import { WhyLabsLogo } from 'components/controls/widgets/WhyLabsLogo';
import {
  WhyLabsButton,
  WhyLabsCheckboxGroup,
  WhyLabsMultiSelect,
  WhyLabsSelect,
  WhyLabsSubmitButton,
  WhyLabsTextInput,
  WhyLabsTooltip,
} from 'components/design-system';
import { useAuthNavigationHandler } from 'hooks/usePageLinkHandler';
import { useUserContext } from 'hooks/useUserContext';
import LogRocket from 'logrocket';
import { PageBases } from 'pages/page-types/pageType';
import { ComponentProps, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NullableString } from 'types/genericTypes';
import { NewSalesHubSpotForm } from 'types/hubspotTypes';
import { SALES_FORM_SENT_KEY } from 'ui/constants';
import { useNewOrgSalesFormPageStyles } from './NewOrgSalesFormPageCSS';

import {
  DATA_MONITORING_OPTIONS,
  INFRASTRUCTURE_OPTIONS,
  MODEL_COUNT_OPTIONS,
  MODEL_MONITORING_OPTIONS,
  PAGE_TEXTS,
  ROLE_OPTIONS,
} from './NewOrgSalesFormTexts';

// sync with hubspot
const PORTAL_ID = '8302037';
const FORM_ID = 'e95c481b-f57e-49e2-aef7-95e6753da1c6';
const HUBSPOT_URI = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`;

type FormDataState = {
  companyName: string;
  dataMonitoring: string[];
  email: string;
  infrastructure: string[];
  modelMonitoring: string[];
  numberOfModels: string;
  role: string;
};

// request body example can be seen on this link:
// https://legacydocs.hubspot.com/docs/methods/forms/submit_form
const prepareDataForHubspot = ({
  companyName,
  dataMonitoring,
  email,
  infrastructure,
  modelMonitoring,
  numberOfModels,
  role,
}: FormDataState): NewSalesHubSpotForm => ({
  fields: [
    {
      name: 'email',
      value: email,
    },
    {
      name: 'title_or_role_',
      value: role,
    },
    {
      name: 'company',
      value: companyName,
    },
    {
      name: 'number_of_models',
      value: numberOfModels,
    },
    {
      name: 'data_monitoring_focus_',
      value: dataMonitoring.join(';'),
    },
    {
      name: 'what_infrastructure_do_you_use_',
      value: infrastructure.join(';'),
    },
    {
      name: 'model_monitoring_focus_',
      value: modelMonitoring.join(';'),
    },
  ],
});

const NewOrgSalesFormPage = (): JSX.Element => {
  const { classes } = useNewOrgSalesFormPageStyles();
  const { getCurrentUser } = useUserContext();
  const user = getCurrentUser();
  const navigate = useNavigate();
  const { triggerLogout } = useAuthNavigationHandler();

  const [problems, setProblems] = useState<string[]>([]);
  const [role, setRole] = useState<NullableString>('');
  const [companyName, setCompanyName] = useState<NullableString>('');
  const [numberOfModels, setNumberOfModels] = useState<NullableString>('');
  const [dataMonitoring, setDataMonitoring] = useState<string[]>([]);
  const [modelMonitoring, setModelMonitoring] = useState<string[]>([]);
  const [infrastructure, setInfrastructure] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const hasDataProblem = problems.includes('DATA');
  const hasModelProblem = problems.includes('MODEL');

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <h1 className={classes.pageTitle}>{PAGE_TEXTS.pageTitle}</h1>
        <WhyLabsLogo className={classes.logo} isDark />
      </header>
      <section>
        <h2 className={classes.sectionTitle}>{PAGE_TEXTS.sectionTitle}</h2>

        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <WhyLabsTextInput
            error={wasSubmitted && !companyName}
            id="company-name-text-input"
            label={PAGE_TEXTS.organizationNameLabel}
            onChange={setCompanyName}
            placeholder={PAGE_TEXTS.requiredPlaceholder}
            required
            value={companyName ?? ''}
          />
          {renderSingleSelectInput({
            data: ROLE_OPTIONS,
            error: wasSubmitted && !role,
            id: 'role-select',
            label: PAGE_TEXTS.yourRoleLabel,
            placeholder: PAGE_TEXTS.pickOnePlaceholder,
            onChange: setRole,
            value: role,
          })}
          <WhyLabsCheckboxGroup
            error={wasSubmitted && !problems.length}
            id="problems-checkbox-group"
            label={PAGE_TEXTS.problemLabel}
            onChange={onChangeProblems}
            options={[
              {
                label: PAGE_TEXTS.dataMonitoringCheckbox,
                value: 'DATA',
              },
              {
                label: PAGE_TEXTS.modelMonitoringCheckbox,
                value: 'MODEL',
              },
            ]}
            required
            value={problems}
          />
          {hasDataProblem &&
            renderMultiSelectInput({
              data: DATA_MONITORING_OPTIONS,
              error: wasSubmitted && !dataMonitoring.length,
              id: 'data-monitoring-select',
              label: PAGE_TEXTS.dataMonitoringFocusLabel,
              required: true,
              onChange: setDataMonitoring,
              value: dataMonitoring,
            })}
          {hasModelProblem &&
            renderSingleSelectInput({
              data: MODEL_COUNT_OPTIONS,
              error: wasSubmitted && !numberOfModels,
              id: 'number-of-models-select',
              label: PAGE_TEXTS.modelsCountLabel,
              placeholder: PAGE_TEXTS.pickFromOptionsPlaceholder,
              onChange: setNumberOfModels,
              value: numberOfModels,
            })}
          {hasModelProblem &&
            renderMultiSelectInput({
              data: MODEL_MONITORING_OPTIONS,
              error: wasSubmitted && !modelMonitoring.length,
              id: 'model-monitoring-select',
              label: PAGE_TEXTS.modelMonitoringFocusLabel,
              required: true,
              onChange: setModelMonitoring,
              value: modelMonitoring,
            })}
          {renderMultiSelectInput({
            data: INFRASTRUCTURE_OPTIONS,
            id: 'infrastructure-select',
            label: PAGE_TEXTS.whatInfrastructureDoYouUseLabel,
            onChange: setInfrastructure,
            value: infrastructure,
          })}
          <div className={classes.buttonsContainer}>
            {renderSubmitButton()}
            <WhyLabsButton color="gray" id="cancel-button" onClick={triggerLogout} variant="subtle">
              {PAGE_TEXTS.cancelButtonLabel}
            </WhyLabsButton>
          </div>
        </form>
      </section>
    </div>
  );

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!wasSubmitted) {
      setWasSubmitted(true);
    }

    if (!user?.email || !companyName || !role || !isProblemsSelectionValid()) return;

    setIsLoading(true);

    const data = prepareDataForHubspot({
      companyName,
      dataMonitoring,
      email: user.email,
      infrastructure,
      modelMonitoring,
      numberOfModels: numberOfModels ?? '',
      role,
    });

    axios
      .post(HUBSPOT_URI, data)
      .then((_) => {
        // Do nothing since we don't want to keep the user stuck here if it fails
      })
      .catch((error) => {
        LogRocket.error('Failed submitting hubspot form on the get started flow', {
          data,
          error,
        });
      })
      .finally(() => {
        localStorage.setItem(SALES_FORM_SENT_KEY, 'success');
        navigate(PageBases.newUser, { replace: true });
      });
  }

  function isProblemsSelectionValid() {
    // If no problems are selected, the form is not valid
    if (!problems.length) return false;

    // If data problem is selected, the data monitoring focus must be selected
    if (problems.includes('DATA') && !dataMonitoring.length) return false;

    // If model problem is selected, the model monitoring focus & the number of models must be selected
    if (problems.includes('MODEL') && (!modelMonitoring.length || !numberOfModels)) return false;

    return true;
  }

  function onChangeProblems(value: string[]) {
    setProblems(value);
  }

  function renderSingleSelectInput(
    props: Pick<
      ComponentProps<typeof WhyLabsSelect>,
      'data' | 'error' | 'id' | 'label' | 'onChange' | 'placeholder' | 'value'
    >,
  ) {
    return <WhyLabsSelect required searchable={false} {...props} />;
  }

  function renderMultiSelectInput(
    props: Pick<
      ComponentProps<typeof WhyLabsMultiSelect>,
      'data' | 'error' | 'id' | 'label' | 'onChange' | 'required' | 'value'
    >,
  ) {
    return <WhyLabsMultiSelect placeholder={PAGE_TEXTS.pickManyPlaceholder} searchable={false} {...props} />;
  }

  function renderSubmitButton() {
    const doesntHaveProblemChecked = !problems.length;

    const button = (
      <WhyLabsSubmitButton disabled={isLoading || doesntHaveProblemChecked} id="continue-button">
        {isLoading ? PAGE_TEXTS.continueButtonLoadingLabel : PAGE_TEXTS.continueButtonLabel}
      </WhyLabsSubmitButton>
    );

    if (doesntHaveProblemChecked) {
      return <WhyLabsTooltip label={PAGE_TEXTS.submitDisabledTooltip}>{button}</WhyLabsTooltip>;
    }

    return button;
  }
};

export default NewOrgSalesFormPage;
