import { useMemo } from 'react';
import { useCommonStyles } from 'hooks/useCommonStyles';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import { Colors, RedAlertBall } from '@whylabs/observatory-lib';
import { adHocAtom, useAdHoc, matchingAdHocData } from 'atoms/adHocAtom';
import { CircularProgress } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { WhyLabsTextHighlight, WhyLabsTooltip } from 'components/design-system';
import { createStyles } from '@mantine/core';
import { InvisibleButton } from 'components/buttons/InvisibleButton';

const useStyles = createStyles({
  rowProperties: {
    overflow: 'hidden',
    flexGrow: 0,
  },
  cellFixedheight: {
    maxHeight: '42px',
    minHeight: '42px',
    overflow: 'hidden',
  },
  abbreviatedCell: {
    textOverflow: 'ellipsis',
    maxWidth: '19em',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  iconContainer: {
    minWidth: '2em',
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  iconCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  morePaddingForScrollBars: {
    paddingRight: '15px',
  },
  selectedCell: {
    backgroundColor: Colors.brandPrimary800,
    color: Colors.white,
    '&:hover': {
      backgroundColor: Colors.brandPrimary900,
    },
    boxSizing: 'border-box',
  },
  unselectedCell: {
    minHeight: '42px',
    maxHeight: '42px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
    boxSizing: 'border-box',
    borderBottom: `1px solid ${Colors.brandSecondary200}`,
  },
  selectedLinkCellArea: {
    color: Colors.white,
    fontWeight: 700,
    textDecoration: 'underline',
  },
  linkCell: {
    color: Colors.linkColor,
    textDecoration: 'underline',
  },
  text: {
    fontFamily: 'Asap, sans-serif',
    fontSize: '12px',
    overflow: 'hidden',
    paddingRight: '10px',
    whiteSpace: 'nowrap',
  },
  orange: {
    borderLeft: `6px solid ${Colors.chartOrange}`,
  },
});

export interface SkinnyBodyRowProps {
  rowData: { name: string; alerts: number };
  handleClick: (featureName: string, index: number) => void;
  index: number;
  highlight: boolean;
  readonly searchTerm?: string;
}

const SkinnyBodyRow: React.FC<SkinnyBodyRowProps> = ({ rowData, handleClick, index, highlight, searchTerm }) => {
  const { classes: styles, cx } = useStyles();
  const { classes: commonStyles } = useCommonStyles();

  let cellClassName = styles.unselectedCell;
  let linkCellArea = styles.linkCell;
  if (highlight) {
    cellClassName = styles.selectedCell;
    linkCellArea = styles.selectedLinkCellArea;
  }

  const urlParams = usePageTypeWithParams();
  const [viewingAdHoc] = useAdHoc();
  const [rowIsAdHoc] = useAdHoc(urlParams.modelId, rowData.name, urlParams.segment, urlParams.pageType);
  const [adHocRecoilData] = useRecoilState(adHocAtom);

  const loadingAdHoc = useMemo(() => {
    if (
      adHocRecoilData.loading === true &&
      matchingAdHocData(adHocRecoilData, urlParams.modelId, rowData.name, urlParams.segment, urlParams.pageType, true)
    ) {
      return true;
    }
    return false;
  }, [adHocRecoilData, rowData.name, urlParams.modelId, urlParams.segment, urlParams.pageType]);

  const alertTooltipText = rowIsAdHoc
    ? 'The number of anomalies generated by the monitor preview'
    : 'The number of anomalies generated by the monitor in this time range';

  let alertCount = rowData.alerts;
  if (rowIsAdHoc && !viewingAdHoc) {
    // If we're toggled out of preview mode, don't show any alerts on previewed features
    alertCount = 0;
  }

  return (
    <div className={cx(styles.rowProperties)}>
      <InvisibleButton
        className={cx(
          styles.iconCell,
          commonStyles.clickity,
          commonStyles.tableFirstColumn,
          styles.cellFixedheight,
          commonStyles.commonLeftPadding,
          cellClassName,
          rowIsAdHoc ? styles.orange : undefined,
        )}
        onClick={() => handleClick(rowData.name, index)}
      >
        <WhyLabsTooltip label={rowData.name} position="right">
          <div className={cx(styles.abbreviatedCell, linkCellArea, styles.text)}>
            <WhyLabsTextHighlight highlight={highlight ? '' : searchTerm ?? ''} darkText>
              {rowData.name}
            </WhyLabsTextHighlight>
          </div>
        </WhyLabsTooltip>
        <div className={cx(styles.iconContainer, styles.morePaddingForScrollBars)}>
          {loadingAdHoc && <CircularProgress size={24} />}
          {!loadingAdHoc && alertCount > 0 && (
            <WhyLabsTooltip label={alertTooltipText}>
              <RedAlertBall alerts={alertCount} adHocRunId={viewingAdHoc ? rowIsAdHoc : undefined} inverted />
            </WhyLabsTooltip>
          )}
        </div>
      </InvisibleButton>
    </div>
  );
};

export default SkinnyBodyRow;
