import { HistogramDomain } from 'components/visualizations/inline-histogram/histogramUtils';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '@whylabs/observatory-lib';

import { FormControlLabel, RadioGroup } from '@material-ui/core';
import { useResizeObserver } from '@mantine/hooks';
import WhyRadio from 'components/controls/widgets/WhyRadio';
import OverlaidHistograms from '../../visualizations/OverlaidHistograms/OverlaidHistogram';
import { UnifiedHistogramWithMetadata } from '../../visualizations/OverlaidHistograms/types';

const useStyles = makeStyles(() =>
  createStyles({
    chartTitle: {
      marginBottom: '15px',
      fontFamily: 'Asap, sans-serif',
      fontSize: '14px',
      color: Colors.black,
    },
    binControls: {
      display: 'flex',
      alignItems: 'center',
    },
    graphContainer: {
      height: '100%',
      width: '100%',
      marginBottom: '12px',
    },
  }),
);

interface FeaturePanelHistogramStackProps {
  amountOfBins: number;
  setAmountOfBins: (amount: number) => void;
  unifiedBinsLoading: boolean;
  unifiedCommonYRange: HistogramDomain | null;
  commonXDomain: HistogramDomain | null;
  unifiedHistograms: UnifiedHistogramWithMetadata[] | undefined;
}

const FeaturePanelHistogramStack: React.FC<FeaturePanelHistogramStackProps> = ({
  amountOfBins,
  setAmountOfBins,
  unifiedBinsLoading,
  unifiedCommonYRange,
  commonXDomain,
  unifiedHistograms,
}) => {
  const [ref, rect] = useResizeObserver();
  const styles = useStyles();

  function displayHistogramCharts(width: number) {
    return (
      <OverlaidHistograms
        loading={unifiedBinsLoading}
        histograms={unifiedHistograms}
        histogramDomain={commonXDomain ?? undefined}
        histogramRange={unifiedCommonYRange ?? undefined}
        graphHeight={300}
        graphWidth={width}
        graphVerticalBuffer={20}
        graphHorizontalBuffer={60}
      />
    );
  }

  function renderBinsControl() {
    return (
      <>
        <RadioGroup aria-label="bins-amount" name="binsAmount" style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            value="30"
            control={<WhyRadio checked={amountOfBins === 30} onClick={() => setAmountOfBins(30)} />}
            label="30 bins"
          />
          <FormControlLabel
            value="60"
            control={<WhyRadio checked={amountOfBins === 60} onClick={() => setAmountOfBins(60)} />}
            label="60 bins"
          />
          <FormControlLabel
            value="90"
            control={<WhyRadio checked={amountOfBins === 90} onClick={() => setAmountOfBins(90)} />}
            label="90 bins"
          />
        </RadioGroup>
      </>
    );
  }

  return (
    <div style={{ flex: '1 1 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span className={styles.chartTitle} style={{ fontWeight: 'bold', marginBottom: 0 }}>
          Histogram data
        </span>
        <div style={{ display: 'flex', alignItems: 'center' }}>{renderBinsControl()}</div>
      </div>
      <div className={styles.graphContainer} ref={ref}>
        {displayHistogramCharts(rect.width)}
      </div>
    </div>
  );
};

export default FeaturePanelHistogramStack;
