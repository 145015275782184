import { useCallback, useEffect, useMemo } from 'react';
import { debounce } from '@material-ui/core';
import { SegmentTable, TempSegmentSortBy } from 'pages/model-page/model-segments/SegmentTable';
import { useSegmentFilter } from 'pages/model-page/model-segments/hooks/useSegmentFilter';
import { SegmentHeaderPanel } from 'components/panels/SegmentHeaderPanel';
import LogRocket from 'logrocket';
import {
  SegmentsListingTableQuery,
  SegmentsListingTableQueryVariables,
  SegmentSortBy,
  SortDirection,
  useGetMergedSegmentQuery,
  useSegmentsListingTableQuery,
} from 'generated/graphql';
import { usePagingInfo } from 'hooks/usePagingInfo';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import { isDashbirdError } from 'utils/error-utils';
import { ApolloError } from '@apollo/client';
import { Colors, Spacings } from '@whylabs/observatory-lib';
import { SortByKeys, SortDirectionKeys, SortDirectionType } from 'hooks/useSort/types';
import useSort from 'hooks/useSort';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import _sortBy from 'lodash/sortBy';
import { segmentTagsToString } from 'utils/segments';
import { useMount } from 'hooks/useMount';
import { useSuperGlobalDateRange } from 'components/super-date-picker/hooks/useSuperGlobalDateRange';
import { useElementSize } from '@mantine/hooks';
import { createStyles } from '@mantine/core';
import { useWhyLabsSnackbar } from 'hooks/useWhyLabsSnackbar';
import { useSetHtmlTitle } from 'hooks/useSetHtmlTitle';
import useSelectedSegments from 'hooks/useSelectedSegments';

const useContentAreaStyles = createStyles({
  panelRoot: {
    backgroundColor: Colors.white,
    display: 'flex',
    flexBasis: Spacings.tabContentHeaderHeight,
  },
  filterContainer: {
    padding: 16,
  },
  headerPanel: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  tableContainer: {
    width: '100%',
    flex: 1,
  },
});

export default function ModelPageSegmentsTabContent(): JSX.Element {
  useSetHtmlTitle('Segments');
  const { dateRange, loading: loadingDateRange } = useSuperGlobalDateRange();
  const { classes: styles } = useContentAreaStyles();
  const { modelId, pageType } = usePageTypeWithParams();
  const paging = usePagingInfo();
  const { enqueueSnackbar } = useWhyLabsSnackbar();
  const { pagingInfo, handleExceededLimit } = paging;

  const { sortDirection, sortBy, setSort, setSortQuery } = useSort<TempSegmentSortBy>(
    SortByKeys.sortSegmentsBy,
    SortDirectionKeys.sortSegmentsDirection,
  );

  useMount(() => {
    if (!sortBy) setSort(SegmentSortBy.AnomalyCount, SortDirection.Desc);
  });

  const { onChange: onChangeSegments, selectedSegment } = useSelectedSegments();
  const { renderFilter } = useSegmentFilter({ onChange: onChangeSegments, resourceId: modelId, selectedSegment });

  const setSortQueryDebounce = useMemo(() => debounce(setSortQuery, 500), [setSortQuery]);

  if (!modelId) {
    LogRocket.error(`Attempted to show segment table on a page type ${pageType}, which has no model ID`);
  }

  const variables = generateQueryVariables();

  const { data, loading, error, refetch } = useSegmentsListingTableQuery({ variables, skip: loadingDateRange });

  const shouldSkipRequestMergedSegment =
    loading || !selectedSegment.length || isMergedSegmentAlreadyFetched() || loadingDateRange;
  const mergedSegmentData = useGetMergedSegmentQuery({
    variables: { ...variables, tags: selectedSegment },
    skip: shouldSkipRequestMergedSegment,
  });

  const handleError = useCallback(
    (apolloError: ApolloError) => {
      apolloError.graphQLErrors.forEach((err) => {
        if (isDashbirdError(err)) handleExceededLimit(err);
        else
          enqueueSnackbar({
            title: 'Something went wrong.',
            variant: 'error',
          });
      });
    },
    [enqueueSnackbar, handleExceededLimit],
  );

  useEffect(() => {
    if (error) handleError(error);
  }, [error, handleError]);

  useDeepCompareEffect(() => {
    if (loadingDateRange) return;
    refetch();
    mergedSegmentData.refetch();
  }, [selectedSegment, refetch, mergedSegmentData.refetch]);

  const isLoading = loading || mergedSegmentData.loading || loadingDateRange;
  const { ref, width, height } = useElementSize<HTMLDivElement>();
  return (
    <div className={styles.content}>
      <div className={styles.panelRoot}>
        <div className={styles.filterContainer}>
          {renderFilter({
            label: 'Filter segments',
          })}
        </div>
        <div className={styles.headerPanel}>
          <SegmentHeaderPanel />
        </div>
      </div>
      <div className={styles.tableContainer} ref={ref}>
        <SegmentTable
          width={width}
          height={height}
          data={getSegmentTableData()}
          error={error}
          loading={isLoading}
          modelId={modelId}
          onSortDirectionChange={onSortDirectionChange}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
    </div>
  );

  function getSegmentTableData(): SegmentsListingTableQuery | undefined {
    if (!data) return undefined;

    const mergedSegment = mergedSegmentData.data?.model?.segment;
    if (isLoading || !mergedSegment) {
      return data;
    }

    return {
      ...data,
      model: data.model
        ? {
            ...data.model,
            segments: [mergedSegment, ...data.model.segments],
          }
        : undefined,
    };
  }

  function isMergedSegmentAlreadyFetched() {
    if (!data?.model?.segments) return false;

    // Sort by key to ensure the order of the tags is the same as the query
    const sortedSelectedSegment = _sortBy(selectedSegment, (s) => s.key);

    const compareString = segmentTagsToString(sortedSelectedSegment);
    return data.model.segments.some((s) => segmentTagsToString(s.tags) === compareString);
  }

  function onSortDirectionChange(newSortDirection: SortDirectionType, newSortBy: TempSegmentSortBy) {
    const validatedSortBy = newSortDirection ? newSortBy : undefined;
    setSort(validatedSortBy, newSortDirection);
    setSortQueryDebounce(validatedSortBy, newSortDirection);
  }

  function generateQueryVariables() {
    const queryVars: SegmentsListingTableQueryVariables = {
      model: modelId,
      ...dateRange,
      offset: pagingInfo.offset,
      limit: pagingInfo.limit,
      tags: selectedSegment,
    };

    if (isValidSortByOnBackEnd(sortBy) && sortDirection) {
      queryVars.sort = {
        by: sortBy,
        direction: sortDirection,
      };
    }

    return queryVars;
  }

  function isValidSortByOnBackEnd(sortByToValidate: TempSegmentSortBy | undefined): sortByToValidate is SegmentSortBy {
    return sortByToValidate === SegmentSortBy.Name || sortByToValidate === SegmentSortBy.AnomalyCount;
  }
}
