import { createStyles } from '@mantine/core';
import GetStartedWhyBotSVG from 'ui/get-started-why-bot-bg.svg';

export const useNewOrgSalesFormPageStyles = createStyles((theme) => ({
  container: {
    backgroundImage: `url("${GetStartedWhyBotSVG}")`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    padding: '46px 40px',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,

    [`@media (max-width: ${theme.breakpoints.md})`]: {
      flexDirection: 'column-reverse',
      alignItems: 'start',
      gap: '30px',
    },
  },
  logo: {
    marginRight: 96,

    [`@media (max-width: ${theme.breakpoints.md})`]: {
      marginLeft: '-10px',
    },
  },
  pageTitle: {
    fontFamily: "'Baloo 2'",
    fontSize: 34,
    fontWeight: 600,
    margin: 0,
  },
  sectionTitle: {
    fontFamily: 'Asap, Roboto, sans-serif',
    fontSize: 24,
    fontWeight: 400,
    marginBottom: 20,
    marginTop: 20,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    maxWidth: 430,
  },
  inputLabel: {
    fontWeight: 400,
  },
  inputRoot: {
    marginBottom: 20,
  },
  buttonsContainer: {
    display: 'flex',
    gap: 20,
  },
}));
