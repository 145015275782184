/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, makeStyles, Typography } from '@material-ui/core';
import { Colors, createModalComponent, SafeLink } from '@whylabs/observatory-lib';
import { WhyLabsButton } from 'components/design-system';
import externalLinks from 'constants/externalLinks';
import { useIsGuestSession } from 'hooks/useIsAnonymousSession';
import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    padding: '20px 15px 20px 15px',
    // backgroundImage: `url(${slide1Image})`,
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
  },
}));

const { ModalProvider, Modal, ModalContext } = createModalComponent();

const useFooterStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancel: {
    marginRight: 10,
    fontSize: 12,
  },
  cancelButton: {
    color: Colors.black,
    paddingRight: 10,
    cursor: 'pointer',
  },
  continue: {},
}));

interface FooterProps {
  onContinue(): void;
  onCancel(): void;
}

function Footer({ onCancel, onContinue }: FooterProps): JSX.Element {
  const styles = useFooterStyles();

  return (
    <div className={styles.root}>
      <Typography display="inline" className={styles.cancel}>
        <Link onClick={onCancel} className={styles.cancelButton}>
          Cancel
        </Link>
      </Typography>

      <WhyLabsButton variant="filled" color="primary" onClick={onContinue} className={styles.continue}>
        Continue
      </WhyLabsButton>
    </div>
  );
}

const cookieKey = 'anonymous-session-modal-dismissed';

export function SessionModal(): JSX.Element | null {
  const styles = useStyles();
  const { setOpen } = useContext(ModalContext);
  const { loading, userIsGuest: userIsAnonymous } = useIsGuestSession();
  const [cookies, setCookie] = useCookies([cookieKey]);

  useEffect(() => {
    if (userIsAnonymous === true) {
      // TODO need to sync in copy before enabling this
      // setOpen(true);
    }
  }, [setOpen, userIsAnonymous]);

  if (loading || !userIsAnonymous || cookies[cookieKey] === 'true') {
    return null;
  }

  function onCancel() {
    window.location.assign(externalLinks.whylabs);
  }

  function onContinue() {
    setCookie(cookieKey, 'true');
    setOpen(false);
  }

  return (
    <Modal title="WhyLabs Demo Mode" footer={<Footer onCancel={onCancel} onContinue={onContinue} />}>
      <div className={styles.root}>
        <Typography>In order to get started with demo mode you&apos;ll need to know a few things</Typography>

        <ul>
          <li>
            <Typography display="inline">
              If uploading data with a notion of time, you&apos;ll have to upload at least three days of data to use the
              data monitoring pages.
            </Typography>
          </li>

          <li>
            <Typography display="inline">
              Processing data may take a few minutes depending on how much is uploaded. The page will refresh when done.
            </Typography>
          </li>
          <li>
            <Typography display="inline">
              Join our <SafeLink primaryColor text="Slack community" href={externalLinks.slackCommunity} /> for help or
              questions.
            </Typography>
          </li>
          <li>
            <Typography display="inline">
              Sessions are immutable so feel free to create new ones to visualize different datasets or additional time
              ranges.
            </Typography>
          </li>
          <li>
            <Typography display="inline">
              Sessions can be viewed by URL so you can share them by sharing the link. They don&apos;t expire.
            </Typography>
          </li>
        </ul>

        <Typography>
          By clicking Continue, you acknowledge that you&apos;ve read and agree to the{' '}
          <SafeLink primaryColor href={externalLinks.termsOfService}>
            WhyLabs Terms of Service
          </SafeLink>
          ,{' '}
          <SafeLink primaryColor href={externalLinks.privacyPolicy}>
            Privacy Policy
          </SafeLink>{' '}
          , and{' '}
          <SafeLink primaryColor href={externalLinks.dataGovernanceDisclosures}>
            Data Governance Disclosures
          </SafeLink>
        </Typography>
      </div>
    </Modal>
  );
}

export const SessionModalProvider = ModalProvider;
