import qs from 'query-string';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export enum FilterKeys {
  modelFilter = 'modelFilter',
  featureFilter = 'featureFilter',
  outputFilter = 'outputFilter',
  profileFilter = 'includeType',
  anomalyFilter = 'anomalyFilter',
  searchString = 'searchText',
  none = '',
}
export type FilterValues = `${FilterKeys}`;

interface FilterQueryStringReturn {
  addFiltersToQueryParams: (filters: string[]) => void;
  getFiltersFromQueryParams: () => string[];
}

export default function useFilterQueryString(filterKey: FilterKeys): FilterQueryStringReturn {
  const navigate = useNavigate();

  const getFiltersFromQueryParams = useCallback((): string[] => {
    const existingSearchString = window.location.search;
    const params = qs.parse(existingSearchString);
    const filters = params[filterKey];

    if (typeof filters === 'string') return [filters];
    return filters ?? [];
  }, [filterKey]);

  const addFiltersToQueryParams = useCallback(
    (checkedFilters: string[]) => {
      const existingSearchString = window.location.search;

      const params = qs.parse(existingSearchString);
      params[filterKey] = checkedFilters;
      const newSearchString = qs.stringify(params);

      navigate(
        {
          search: newSearchString,
        },
        { replace: true },
      );
    },
    [navigate, filterKey],
  );

  return {
    addFiltersToQueryParams,
    getFiltersFromQueryParams,
  };
}
