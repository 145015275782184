import { HtmlTooltip } from '@whylabs/observatory-lib';
import { useGetSegmentCountQuery } from 'generated/graphql';
import { useModelWidgetStyles } from 'hooks/useModelWidgetStyles';
import LogRocket from 'logrocket';
import { tooltips } from 'strings/tooltips';
import { Skeleton } from '@mantine/core';
import { WhyLabsText } from 'components/design-system';

interface SegmentCountDiscreteProps {
  readonly modelId: string;
}

export function SegmentCountDiscreteWidget({ modelId }: SegmentCountDiscreteProps): JSX.Element | null {
  const { classes: styles, cx } = useModelWidgetStyles();
  const { loading, error, data } = useGetSegmentCountQuery({ variables: { model: modelId }, skip: !modelId });

  if (error !== undefined) {
    LogRocket.error(error);
    return null;
  }

  const total = data?.model?.totalSegments;

  return (
    <div className={styles.root}>
      <div className={styles.headlineColumn}>
        <WhyLabsText inherit className={cx(styles.bolded, styles.headline)}>
          Total segments
          <HtmlTooltip tooltipContent={tooltips.segment_total_count} />
        </WhyLabsText>

        {!loading && total && (
          <WhyLabsText inherit className={cx(styles.heroNumber)}>
            {total}
          </WhyLabsText>
        )}
        {loading && (
          <WhyLabsText>
            <Skeleton variant="text" width={84} height={38} animate />
          </WhyLabsText>
        )}
      </div>
    </div>
  );
}
