import { createStyles, getStylesRef } from '@mantine/core';
import { stringMax, TextCell } from '@whylabs/observatory-lib';
import useTypographyStyles from 'styles/Typography';
import { WhyLabsButton, WhyLabsTooltip } from 'components/design-system';
import { AlertTableCell } from './types';

const useStyles = createStyles(() => ({
  tooltipWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  cellWrapper: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    [`&:not(:hover) .${getStylesRef('filterBtn')}`]: {
      display: 'none',
    },
  },
  filterBtn: {
    ref: getStylesRef('filterBtn'),
    marginLeft: 'auto',
    fontStyle: 'normal',
  },
  span: {
    display: 'block',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    width: 'auto',
  },
}));

interface TextWithActionButtonCellProps extends AlertTableCell {
  text: string;
  unlinkedText?: string;
  tooltip?: string;
  width?: number;
  actionHandler?: () => void;
  actionButtonLabel: string;
  hideButton?: boolean;
}

const WORKAROUND_CELL_LIMIT = 82;

export default function TextWithActionButtonCell({
  text,
  isUnhelpful,
  unlinkedText,
  tooltip,
  width,
  actionHandler,
  actionButtonLabel,
  hideButton,
}: TextWithActionButtonCellProps): JSX.Element {
  const { classes } = useStyles();
  const { classes: typography } = useTypographyStyles();
  const cellText = text.concat(unlinkedText ?? '');
  const collapsedText = cellText.length > WORKAROUND_CELL_LIMIT;
  const showTooltip = !hideButton || collapsedText;
  const usedTooltipText = collapsedText ? `text. ${tooltip ?? ''}` : tooltip;
  const mainContent = (
    <TextCell disableCaps className={isUnhelpful ? typography.unhelpfulAlertText : undefined}>
      <WhyLabsTooltip
        maxWidth={(width ?? 0) > 200 ? width : undefined}
        label={showTooltip ? usedTooltipText : ''}
        position="bottom-start"
      >
        {text.length > 0 ? (
          <div className={classes.cellWrapper} style={{ width }}>
            <span className={classes.span}>{stringMax(cellText, 82)}</span>
            {!hideButton && actionHandler && (
              <WhyLabsButton
                onClick={actionHandler}
                className={classes.filterBtn}
                variant="outline"
                color="gray"
                size="xs"
              >
                {actionButtonLabel}
              </WhyLabsButton>
            )}
          </div>
        ) : (
          <span className={typography.textTable}>N/a</span>
        )}
      </WhyLabsTooltip>
    </TextCell>
  );

  return <div className={classes.tooltipWrapper}>{mainContent}</div>;
}
