/* eslint-disable react/no-array-index-key */
import { forwardRef, useCallback } from 'react';
import { Skeleton, createStyles } from '@mantine/core';
import Chip from '@material-ui/core/Chip';
import { Button } from '@material-ui/core';
import { Colors } from '@whylabs/observatory-lib';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import { WhyLabsBadge, WhyLabsText, WhyLabsTooltip } from 'components/design-system';
import { InsightType } from './insights';
import { useSortedInsights } from './useInsights';

const useStyles = createStyles(() => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    width: '100%',
  },
  scrollContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: Colors.white,
    borderTop: `1px solid ${Colors.brandSecondary200}`,
    borderLeft: `1px solid ${Colors.brandSecondary200}`,
    flexDirection: 'column',
    paddingTop: 17,
    paddingBottom: 17,
  },
  featureName: {
    color: Colors.brandPrimary900,
    background: Colors.lightGray,
    height: 24,
    cursor: 'pointer',
    padding: '0 13px',
    fontWeight: 500,
    fontSize: 13,
    textTransform: 'unset',
    '&:hover': {
      background: Colors.brandPrimary900,
      color: Colors.white,
    },
  },
  featureNameButton: {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: Colors.brandSecondary100,
    },
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 17,
    paddingRight: 17,
    '& p': {
      fontSize: 14,
      lineHeight: 1.1,
    },
  },
  columnFirst: {
    flexGrow: 1,
    fontFamily: 'Asap',
  },
  columnOther: {
    display: 'flex',
  },
  badgeRoot: {
    display: 'flex',
    fontFamily: 'Asap',
    flexDirection: 'row',
    marginLeft: 10,
  },
  badge: {
    borderRadius: 4,
    padding: '6px 8px',
    color: 'white',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  badgeLLM: {
    borderRadius: '0 4px 4px 0',
    padding: '6px 8px',
    color: 'white',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'bolder',
  },
  codeFont: {
    marginRight: '5px',
    fontFamily: 'Asap',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '14px',
  },
}));

const useLLMStyles = createStyles(() => ({
  verticalTextSquare: {
    display: 'flex',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px 0px 0px 4px',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    borderColor: Colors.brandSecondary500,
  },
  verticalText: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '-0.3px',
    color: Colors.secondaryLight1000,
  },
}));

const VerticalTextSquare: React.FC = () => {
  const { classes } = useLLMStyles();

  return (
    <div className={classes.verticalTextSquare}>
      <div className={classes.verticalText}>LLM</div>
    </div>
  );
};

interface BadgeProps {
  text: string;
  color: string;
  llm?: boolean;
}

function Badge(props: BadgeProps) {
  const { color, text, llm } = props;
  const { classes } = useStyles();
  return (
    <div className={classes.badgeRoot}>
      {llm && <VerticalTextSquare />}
      <WhyLabsText inherit className={llm ? classes.badgeLLM : classes.badge} style={{ backgroundColor: color }}>
        {text}
      </WhyLabsText>
    </div>
  );
}

interface InsightTypeBadgeProps {
  type: InsightType;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const InsightTypeBadge = forwardRef((props: InsightTypeBadgeProps, ref: React.ForwardedRef<any>) => {
  let content: React.ReactElement;
  switch (props.type) {
    case InsightType.HIGH_CARDINALITY:
      content = <Badge text={props.type} color={Colors.chartPrimary} />;
      break;
    case InsightType.IMBALANCED:
      content = <Badge text={props.type} color={Colors.chartPurple} />;
      break;
    case InsightType.NULL_VALUES:
      content = <Badge text={props.type} color={Colors.chartBlue} />;
      break;
    case InsightType.MIXED_DATA_TYPE:
      content = <Badge text={props.type} color={Colors.chartAqua} />;
      break;
    case InsightType.LLM_TOXICITY:
      content = <Badge llm text={props.type} color={Colors.green} />;
      break;
    case InsightType.LLM_PATTERNS:
      content = <Badge llm text={props.type} color={Colors.brown} />;
      break;
    case InsightType.LLM_NEGATIVE_SENTIMENT:
      content = <Badge llm text={props.type} color={Colors.red} />;
      break;
    case InsightType.LLM_POSITIVE_SENTIMENT:
      content = <Badge llm text={props.type} color={Colors.pink} />;
      break;
    case InsightType.LLM_REFUSAL:
      content = <Badge llm text={props.type} color={Colors.teal} />;
      break;
    case InsightType.LLM_READING_EASE:
      content = <Badge llm text={props.type} color={Colors.chartOrange} />;
      break;
    case InsightType.LLM_JAILBREAK_SIMILARITY:
      content = <Badge llm text={props.type} color={Colors.blue} />;
      break;
  }

  return (
    <div {...props} ref={ref}>
      {content}
    </div>
  );
});

export interface ProfileReportProps {
  onHideReport: (featureHilight?: string) => void;
}

export function InsightsReport(props: ProfileReportProps): JSX.Element | null {
  const { onHideReport } = props;
  const { classes } = useStyles();
  const pt = usePageTypeWithParams();
  const { modelId, profiles: profileIds, segment } = pt;

  const onFeatureClick = useCallback((featureName: string) => onHideReport(featureName), [onHideReport]);

  const { loading, error, allInsights } = useSortedInsights(profileIds, modelId, segment.tags);

  if (loading || !allInsights || error) {
    return (
      <div className={classes.root}>
        <div className={classes.scrollContainer}>
          {[...Array(20)].map((x, i) => (
            <div className={classes.row} key={i}>
              <Skeleton height={30} animate />
              <Skeleton className={classes.badgeRoot} width="10%" height={30} animate />
            </div>
          ))}
        </div>
      </div>
    );
  }

  const inProfile = (alias: string): JSX.Element => (
    <>
      {' '}
      in profile <Chip component="span" label={alias} size="small" />
    </>
  );
  return (
    <div className={classes.root}>
      <div className={classes.scrollContainer}>
        {allInsights.map(({ alias, featureName, insight }) => {
          return (
            <div className={classes.row} key={`${alias}-${featureName}-${insight.type}`}>
              <WhyLabsText inherit className={classes.columnFirst} key={`${featureName}-${insight.type}`}>
                <Button className={classes.featureNameButton} onClick={() => onFeatureClick(featureName)}>
                  <WhyLabsTooltip label="Click to view">
                    <WhyLabsBadge radius="lg" className={classes.featureName}>
                      {featureName}
                    </WhyLabsBadge>
                  </WhyLabsTooltip>
                </Button>{' '}
                {insight.description}
                {profileIds.length > 1 && inProfile(alias)}
              </WhyLabsText>

              <div className={classes.columnOther}>
                <InsightTypeBadge type={insight.type} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
