import LogRocket from 'logrocket';

/**
 * Clears Heap Analytics Session. Should be done any time the user logs out.
 * Does not throw.
 */
export const clearHeapSession = (): void => {
  try {
    const { clearEventProperties } = window.heap ?? {};
    if (clearEventProperties) {
      clearEventProperties();
    }
  } catch (e) {
    LogRocket.log(`Unable to clear Heap Session: ${e.message}`);
  }
};
