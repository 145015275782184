import { CSSObject, MantineThemeOverride } from '@mantine/core';
import { Colors } from '@whylabs/observatory-lib';

const defaultInputStyle: Record<string, CSSObject> = {
  label: {
    fontWeight: 'bold',
    fontSize: 14,
    color: Colors.brandSecondary900,
  },
};

// TODO: I'm not sure if this is the best way to do this, but it works.
export const customMantineTheme: MantineThemeOverride = {
  components: {
    Autocomplete: {
      styles: defaultInputStyle,
    },
    Checkbox: {
      styles: defaultInputStyle,
    },
    CheckboxGroup: {
      styles: defaultInputStyle,
    },
    NumberInput: {
      styles: defaultInputStyle,
    },
    PasswordInput: {
      styles: defaultInputStyle,
    },
    Select: {
      styles: defaultInputStyle,
    },
    MultiSelect: {
      styles: defaultInputStyle,
    },
    Textarea: {
      styles: defaultInputStyle,
    },
    TextInput: {
      styles: defaultInputStyle,
    },
  },
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '62em',
    lg: '75em',
    xl: '88em',
  },
  headings: { fontFamily: 'Asap, Roboto, sans-serif', fontWeight: 400 },
  fontFamily: 'Asap, Roboto, sans-serif',
};
