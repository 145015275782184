import { List, Stack, createStyles } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons';
import { Colors, SafeLink } from '@whylabs/observatory-lib';
import { WhyLabsTypography } from 'components/design-system';
import WhyLabsAlert from 'components/design-system/alert/WhyLabsAlert';
import { SubscriptionTier } from 'generated/graphql';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUser } from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useQueryParams } from 'utils/queryUtils';
import { useWhyLabsSnackbar } from 'hooks/useWhyLabsSnackbar';
import { useSetHtmlTitle } from 'hooks/useSetHtmlTitle';

const useStyles = createStyles(() => ({
  pageContainer: {
    padding: 50,
  },
  alertText: {
    fontSize: 14,
  },
  alertTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: Colors.chartBlue,
  },
  lowerText: {
    fontSize: 16,
  },
  topMargin: {
    marginTop: 20,
  },
}));

const POLL_INTERVAL = 3000;
const PLAN_DESCRIPTION_MAP = new Map<SubscriptionTier, string>([
  [SubscriptionTier.Free, 'Starter Plan'],
  [SubscriptionTier.Subscription, 'Expert Plan'],
  [SubscriptionTier.Paid, 'Enterprise Plan'],
  [SubscriptionTier.AwsMarketplace, 'AWS Marketplace Plan'],
]);

export const BillingPageContent: React.FC = () => {
  const { classes } = useStyles();
  const { user } = useUser(POLL_INTERVAL);

  useSetHtmlTitle('Billing');

  const [tier, setTier] = useState<SubscriptionTier | null>(user?.organization?.subscriptionTier ?? null);
  const { enqueueSnackbar } = useWhyLabsSnackbar();
  const { getQueryParam } = useQueryParams();
  const upgraded = !!getQueryParam('upgrade');

  const envType = useEnvironment();
  const managePaymentsLink =
    envType === 'dev'
      ? 'https://pay.whylabs.ai/p/login/test_7sI8yj38FfvCdPO288'
      : 'https://pay.whylabs.ai/p/login/00g00D5RX9Gz87KcMM';

  useEffect(() => {
    if (!!user?.organization?.subscriptionTier && user.organization.subscriptionTier !== tier) {
      const updatedTier = user.organization.subscriptionTier;
      if ((upgraded || tier === SubscriptionTier.Free) && updatedTier === SubscriptionTier.Subscription) {
        enqueueSnackbar({ title: 'Successfully upgraded plan!' });
      }
      setTier(user.organization.subscriptionTier);
    }
  }, [tier, user?.organization?.subscriptionTier, enqueueSnackbar, upgraded]);

  const isCallAdminTier = (subTier: SubscriptionTier | null): boolean => {
    return subTier === SubscriptionTier.Paid || subTier === SubscriptionTier.AwsMarketplace;
  };
  const hasPricingData = (subTier: SubscriptionTier | null): boolean => {
    return subTier === SubscriptionTier.Subscription || subTier === SubscriptionTier.Free;
  };

  const getPlanDetails = (subTier: SubscriptionTier | null): React.ReactNode => {
    if (isCallAdminTier(subTier)) {
      return (
        <>
          <span>
            <SafeLink sameTab href="contactUs">
              Contact us
            </SafeLink>
          </span>
          <span> to discuss custom plan details.</span>
        </>
      );
    }
    if (subTier === SubscriptionTier.Subscription) {
      return null;
    }
    if (subTier === SubscriptionTier.Free) {
      return <span>2 resources</span>;
    }
    return null;
  };
  const getPlanPricing = (subTier: SubscriptionTier | null): React.ReactNode => {
    if (subTier === SubscriptionTier.Subscription) {
      return null;
    }
    if (subTier === SubscriptionTier.Free) {
      return <span>Free</span>;
    }
    return null;
  };
  const renderTitle = () => (
    <WhyLabsTypography className={classes.alertTitle}>{`Current Plan: ${
      tier ? PLAN_DESCRIPTION_MAP.get(tier) : ''
    }`}</WhyLabsTypography>
  );
  const renderPrice = (subTier: SubscriptionTier | null): React.ReactNode => {
    if (subTier === SubscriptionTier.Free) {
      // Temporarily disable showing price for the subscription plan.
      return (
        <WhyLabsTypography className={classes.alertText}>
          <span>Price: </span>
          {getPlanPricing(tier)}
        </WhyLabsTypography>
      );
    }
    return null;
  };

  const renderPlanDetails = (subTier: SubscriptionTier | null): React.ReactNode => {
    if (subTier === SubscriptionTier.Subscription) {
      return null;
    }
    return (
      <WhyLabsTypography className={classes.alertText}>
        <span>Plan details: </span>
        {getPlanDetails(subTier)}
      </WhyLabsTypography>
    );
  };
  return (
    <div className={classes.pageContainer}>
      <WhyLabsAlert
        title={renderTitle()}
        icon={
          <IconInfoCircle
            style={{
              height: 18,
              color: Colors.chartBlue,
              width: 'fit-content',
            }}
          />
        }
      >
        <Stack justify="flex-start" spacing="xs" sx={{ gap: 0 }}>
          {renderPlanDetails(tier)}
          {renderPrice(tier)}
        </Stack>
      </WhyLabsAlert>
      {hasPricingData(tier) && (
        <List withPadding className={classes.topMargin}>
          <List.Item>
            <WhyLabsTypography className={classes.lowerText}>
              <span>View invoices, manage payment methods, and update or cancel your plan in your payment portal </span>
              <span>
                <SafeLink href={managePaymentsLink}>here</SafeLink>
              </span>
              <span>.</span>
            </WhyLabsTypography>
          </List.Item>
          <List.Item>
            <WhyLabsTypography className={classes.lowerText}>
              <span>View all plans and pricing information on the WhyLabs website </span>
              <span>
                <SafeLink href="https://whylabs.ai/pricing">here</SafeLink>
              </span>
              <span>.</span>
            </WhyLabsTypography>
          </List.Item>
        </List>
      )}
    </div>
  );
};
