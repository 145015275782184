import { useMemo, useState } from 'react';
import { BarChart, Bar, Tooltip, ResponsiveContainer, YAxis, XAxis, Label } from 'recharts';
import { Colors, stringMax } from '@whylabs/observatory-lib';
import useTypographyStyles from 'styles/Typography';
import { friendlyFormat } from 'utils/numberUtils';
import { arrayOfLength } from 'utils/arrayUtils';
import { useSearchProfiles } from 'hooks/useSearchProfiles';
import { createStyles } from '@mantine/core';
import CustomTooltip from './CustomTooltip';

const chartContainerHeight = 160;
const useFeaturePanelChartStyles = createStyles({
  chartContainer: {
    width: '100%',
    height: `${chartContainerHeight}px`,
  },
  chartLegend: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '15px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: 'Asap, sans-serif',
  },
  legendBox: {
    height: '11px',
    width: '11px',
    marginRight: '5px',
  },
  tooltipContainer: {
    background: Colors.white,
    border: `1px solid ${Colors.brandPrimary700}`,
    borderRadius: '5px',
    padding: '10px',
  },
  tooltipLabel: {
    fontWeight: 'bold',
    marginTop: '10px',
    marginBottom: '10px',
  },
  tooltipItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  legendItemText: {
    fontFamily: 'Asap, sans-serif',
    fontSize: '12px',
    lineHeight: '20px',
    color: Colors.brandSecondary900,
  },
});

interface ChartDataBar {
  name: string;
  fill: string;
  dataKey: string;
}

export interface ChartSingleDatum {
  name: string | number;
  [key: string]: number | string;
}

interface FeaturePanelChartProps {
  chartData: ChartSingleDatum[];
  shouldToggleLegend?: boolean;
  yDomain?: [number, number];
  isHistogramChart?: boolean;
}

type CustomTooltipPropsPayload = {
  payload: Record<string, string>;
  dataKey: string;
  color: string;
};

type CustomTooltipProps = {
  active: unknown;
  payload: CustomTooltipPropsPayload[];
  label: string;
};

export default function FeaturePanelChart({
  chartData,
  shouldToggleLegend,
  yDomain,
  isHistogramChart,
}: FeaturePanelChartProps): JSX.Element {
  const { classes: styles } = useFeaturePanelChartStyles();
  const { rawProfiles } = useSearchProfiles();
  const chartBarsDefinition = useMemo(
    () =>
      arrayOfLength(rawProfiles.length).map((data, i) => ({
        name: `Profile ${i + 1}`,
        fill: Colors.profilesColorPool[i] ?? Colors.grey,
        dataKey: `profile-${i + 1}`,
      })),
    [rawProfiles.length],
  );

  const [legendDisabled, setLegendDisabled] = useState<string[]>([]);
  const { classes: typography } = useTypographyStyles();

  function toggleProfile(profile: string) {
    if (legendDisabled.includes(profile)) {
      setLegendDisabled(legendDisabled.filter((item) => item !== profile));
    } else {
      setLegendDisabled((prevState) => [...prevState, profile]);
    }
  }

  function generateLegend() {
    return (
      <div className={styles.chartLegend}>
        <span className={typography.thinLabel}>Click to toggle:</span>
        {chartBarsDefinition.map((barDefinition) => {
          const active = legendDisabled.includes(barDefinition.dataKey);

          return (
            <div
              key={`profile-legend-${barDefinition.dataKey}`}
              onClick={() => (shouldToggleLegend ? toggleProfile(barDefinition.dataKey) : '')}
              aria-hidden="true"
              className={styles.legendItem}
            >
              {active ? (
                <>
                  <div className={styles.legendBox} style={{ background: Colors.grey }} />
                  <span className={styles.legendItemText}>{barDefinition.name}</span>
                </>
              ) : (
                <>
                  <div className={styles.legendBox} style={{ background: barDefinition.fill }} />
                  <span className={styles.legendItemText}>{barDefinition.name}</span>
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  function GenerateCustomTooltip({ active, payload, label }: CustomTooltipProps) {
    if (active && payload && payload.length) {
      const tooltipData = payload.map((item) => ({
        label: item.payload[item.dataKey],
        color: item.color,
      }));
      return <CustomTooltip data={tooltipData} label={stringMax(label, 40)} />;
    }

    return null;
  }

  return (
    <div>
      {chartData.length > 0 ? (
        <div className={styles.chartContainer}>
          {generateLegend()}

          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} barCategoryGap={0.5} barGap={0.5}>
              <YAxis tickFormatter={(tick: number) => friendlyFormat(tick, 1)} type="number" domain={yDomain}>
                <Label
                  value={isHistogramChart ? 'Count' : 'Item count'}
                  position="top"
                  style={{
                    fontFamily: 'Asap, sans-serif',
                    fontWeight: 'bold',
                    transform: 'translate(12px, 60%) rotate(-90deg)',
                    lineHeight: '20px',
                    color: Colors.brandSecondary900,
                  }}
                />
              </YAxis>
              {chartBarsDefinition.map((bar: ChartDataBar, index: number) => (
                <XAxis key={`xaxis-${bar.name}`} dataKey="name" xAxisId={index} hide={index > 0} />
              ))}

              <Tooltip content={GenerateCustomTooltip} />

              {chartBarsDefinition.map((bar: ChartDataBar, index: number) => {
                const active = !legendDisabled.includes(bar.dataKey);
                if (!active) return null;
                return (
                  <Bar
                    isAnimationActive={false}
                    key={`bar-${bar.name}`}
                    dataKey={bar.dataKey}
                    name={bar.name}
                    xAxisId={index}
                    fill={bar.fill}
                    fillOpacity={index === 0 ? 1 : 0.7}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : null}
    </div>
  );
}
