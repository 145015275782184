import { WhyLabsSubmitButton } from 'components/design-system';
import { useSuperGlobalDateRange } from 'components/super-date-picker/hooks/useSuperGlobalDateRange';
import { TARGET_ORG_QUERY_NAME } from 'graphql/apollo';
import { getNewStackEmbeddedURL } from 'hooks/useNewStackLinkHandler';
import { getParam } from 'pages/page-types/usePageType';
import { USED_ON_KEY } from 'types/navTags';

type NewCustomDashboardButtonProps = {
  usedOn: string;
};

export const NewCustomDashboardButton = ({ usedOn }: NewCustomDashboardButtonProps): JSX.Element => {
  const { datePickerSearchString } = useSuperGlobalDateRange();
  const orgId = getParam(TARGET_ORG_QUERY_NAME);

  if (!orgId) return <></>;

  const link = (() => {
    const newStackSearchParams = new URLSearchParams();
    newStackSearchParams.set(USED_ON_KEY, usedOn);

    return getNewStackEmbeddedURL({
      orgId,
      path: 'dashboards/create',
      searchParams: newStackSearchParams,
      datePickerSearchString,
    });
  })();

  return (
    <a href={link}>
      <WhyLabsSubmitButton type="button">New dashboard</WhyLabsSubmitButton>
    </a>
  );
};
