import { TitleAndContentTemplate } from '@whylabs/observatory-lib';
import { GlobalBanner } from 'components/controls/widgets/banner/GlobalBanner';
import { GlobalTitleBar } from 'components/controls/widgets/GlobalTitleBar';
import { WhyLabsInternalWarningBar } from 'pages/admin/components/WhyLabsInternalWarningBar';
import { Center, Stack, Text, createStyles } from '@mantine/core';
import { useEffect, useState } from 'react';
import { SubscriptionTier } from 'generated/graphql';
import { isExactlyNullOrUndefined } from 'utils';
import { useUser } from 'hooks/useUser';

const useStyles = createStyles(() => ({
  container: {
    height: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    flexGrow: 1,
  },
}));

const USER_UPDATE_POLL_INTERVAL = 2000;

export const PaymentsCompletePage: React.FC = () => {
  const { classes } = useStyles();
  const { user } = useUser(USER_UPDATE_POLL_INTERVAL);
  const [tier, setTier] = useState<SubscriptionTier | null>(null);

  useEffect(() => {
    const userTier = user?.organization?.subscriptionTier;
    if (isExactlyNullOrUndefined(userTier)) {
      return;
    }
    if (userTier === tier) {
      return;
    }
    setTier(userTier);
  }, [tier, user?.organization?.subscriptionTier]);

  const renderContent = () => {
    return (
      <Center>
        <Stack>
          <Text>Processing payment now...</Text>
          <Text>{`Your current subscription level is ${tier}`}</Text>
        </Stack>
      </Center>
    );
  };
  return (
    <TitleAndContentTemplate
      BannerComponent={<GlobalBanner />}
      TitleBarAreaComponent={<GlobalTitleBar />}
      TabBarComponent={<WhyLabsInternalWarningBar />}
      ContentAreaComponent={<div className={classes.root}>{renderContent()}</div>}
    />
  );
};
