import { createStyles } from '@mantine/core';
import { HtmlTooltip } from '@whylabs/observatory-lib';
import { WhyLabsButton, WhyLabsSubmitButton, WhyLabsText } from 'components/design-system';
import { useModelWidgetStyles } from 'hooks/useModelWidgetStyles';
import LogRocket from 'logrocket';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import { useCallback } from 'react';
import useTypographyStyles from 'styles/Typography';
import lightBulbIcon from 'ui/light-bulb.svg';
import { useAllInsights } from '../table/profiles-table/useInsights';

const useStyles = createStyles(() => ({
  reportButton: {
    marginLeft: 15,
  },
  lightBulb: {
    height: '85%',
    marginRight: 10,
  },
  hideReportButton: {
    marginLeft: 15,
  },
  bottomRow: {
    alignItems: 'center',
    display: 'flex',
  },
}));

export interface GuestSessionPanelWidgetProps {
  onShowReport: () => void;
  onHideProfileReport: () => void;
  shown: boolean;
}

export function GuestSessionPanelWidget(props: GuestSessionPanelWidgetProps): JSX.Element | null {
  const { onShowReport, onHideProfileReport, shown } = props;
  const { classes: modelStyles, cx } = useModelWidgetStyles();
  const styles = useStyles();
  const { classes: typography } = useTypographyStyles();

  const pt = usePageTypeWithParams();
  const { modelId, profiles: profileIds, segment } = pt;
  const { insights, loading, error } = useAllInsights(profileIds, modelId, segment.tags);
  const hideReport = useCallback(() => onHideProfileReport(), [onHideProfileReport]);

  const content = () => {
    if (loading) {
      return (
        <div className={styles.classes.bottomRow}>
          <img className={styles.classes.lightBulb} src={lightBulbIcon} alt="lightbulb icon" />
          <WhyLabsText inherit className={cx(typography.insightsWidgetTitle, modelStyles.headline)}>
            Checking insights
          </WhyLabsText>
        </div>
      );
    }

    if (error) {
      LogRocket.error(`GuestSessionPanelWidget failed to load feature counts`, error);
      return (
        <div className={styles.classes.bottomRow}>
          <img className={styles.classes.lightBulb} src={lightBulbIcon} alt="lightbulb icon" />
          <WhyLabsText inherit className={cx(typography.insightsWidgetTitle, modelStyles.headline)}>
            Error loading insights
          </WhyLabsText>
        </div>
      );
    }

    const insightsFor = profileIds.length > 1 ? `for ${profileIds.length} profiles` : '';

    const hideInsightsButton = (
      <WhyLabsButton
        variant="outline"
        className={styles.classes.hideReportButton}
        color="gray"
        onClick={hideReport}
        size="xs"
      >
        Hide Insights
      </WhyLabsButton>
    );

    if (!insights || insights.totalInsights === 0) {
      return (
        <div className={styles.classes.bottomRow}>
          <img className={styles.classes.lightBulb} src={lightBulbIcon} alt="lightbulb icon" />
          <WhyLabsText inherit className={cx(typography.insightsWidgetTitle, modelStyles.headline)}>
            No insights {insightsFor}
          </WhyLabsText>
          {shown && hideInsightsButton}
        </div>
      );
    }

    return (
      <div className={styles.classes.bottomRow}>
        <img className={styles.classes.lightBulb} src={lightBulbIcon} alt="lightbulb icon" />
        <WhyLabsText inherit className={cx(typography.insightsWidgetTitle, modelStyles.headline)}>
          {insights.totalInsights} insights {insightsFor}
        </WhyLabsText>
        {shown ? (
          hideInsightsButton
        ) : (
          <WhyLabsSubmitButton
            className={styles.classes.reportButton}
            onClick={onShowReport}
            id="show-profile-report-button"
            size="xs"
          >
            View insights
          </WhyLabsSubmitButton>
        )}
      </div>
    );
  };

  return (
    <div className={modelStyles.root}>
      <div className={modelStyles.headlineColumn}>
        <WhyLabsText inherit className={cx(typography.widgetTitle, modelStyles.bolded, modelStyles.headline)}>
          Insights
          <HtmlTooltip tooltipContent="All of the insights that have been gathered from the selected profiles." />
        </WhyLabsText>

        {content()}
      </div>
    </div>
  );
}
