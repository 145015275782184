import { useResourceContext } from 'pages/model-page/hooks/useResourceContext';
import { ensureDateRangeIsAtLeastOneDay } from 'utils/queryUtils';
import { useCallback, useMemo } from 'react';
import { GLOBAL_PICKER_ID } from 'constants/hardcoded';
import { useSearchParams } from 'react-router-dom';
import { NEW_GLOBAL_END_RANGE, NEW_GLOBAL_RANGE_PRESET, NEW_GLOBAL_START_RANGE } from 'types/navTags';
import { isNumber } from 'utils/typeGuards';
import { TimePeriod } from 'generated/graphql';
import { getFunctionsForTimePeriod, setEndOfUTCMinute } from 'utils/batchProfileUtils';
import { mapTimePeriodToPresetGranularity, RELATIVE_PRESET_TOKEN } from 'hooks/useDynamicTrailingRangePresets';
import { SimpleDateRange } from 'utils/dateRangeUtils';
import { useDateRangeParams } from './useDateRangeParams';
import { CustomRangeSearchParams, dateConstructorToReadableISOString } from '../utils';

export type SuperGlobalDateRange = {
  dateRange: SimpleDateRange;
  loading: boolean;
  setDatePickerRange: (d: SimpleDateRange, preset?: string) => void;
  appliedPreset: string;
  dateRangeWithOneDayMinimumInterval: SimpleDateRange;
  openDatePicker: (id?: string) => void;
  datePickerSearchString: string;
  isUsingFallbackRange: boolean;
  applyTrailingWindowRange: (size: number, overrideTimePeriod?: TimePeriod) => void;
};

type SuperGlobalHookProps = {
  timePeriod?: TimePeriod;
  loading?: boolean;
  // The parameter will auto adjust the timestamps to match the start and end bounds of the bucket by TimePeriod.
  // We might want to disable it in some cases like the individual profiles' dashboard or when we want to allow fetch
  // data within a grain minor than hourly.
  autoAdjustTimestampsByTimePeriod?: boolean;
} & CustomRangeSearchParams;
export const useSuperGlobalDateRange = ({
  startDateSearchParamKey = NEW_GLOBAL_START_RANGE,
  endDateSearchParamKey = NEW_GLOBAL_END_RANGE,
  presetSizeSearchParamKey = NEW_GLOBAL_RANGE_PRESET,
  timePeriod,
  loading,
  autoAdjustTimestampsByTimePeriod = true,
}: SuperGlobalHookProps = {}): SuperGlobalDateRange => {
  const {
    resourceState: { resource },
    loading: resourceLoading,
  } = useResourceContext();
  const usedLoading = timePeriod || loading ? loading : resourceLoading;
  const usedTimePeriod = timePeriod ?? resource?.batchFrequency ?? TimePeriod.P1D;
  const { startTimestamp, endTimestamp, setRangeParams, appliedPreset, isUsingFallbackRange } = useDateRangeParams({
    loading: usedLoading,
    timePeriod: usedTimePeriod,
    startDateSearchParamKey,
    endDateSearchParamKey,
    presetSizeSearchParamKey,
  });
  const [searchParams] = useSearchParams();

  const openDatePicker = (pickerId = GLOBAL_PICKER_ID) => {
    (document.querySelector(`[data-pickerid="${pickerId}--input"]`) as HTMLElement)?.click();
  };

  const datePickerSearchString = useMemo(() => {
    const start = searchParams.get(startDateSearchParamKey);
    const end = searchParams.get(endDateSearchParamKey);
    const preset = searchParams.get(presetSizeSearchParamKey);
    const globalDatePickerParams = new URLSearchParams();
    if (start && end) {
      globalDatePickerParams.set(startDateSearchParamKey, start);
      globalDatePickerParams.set(endDateSearchParamKey, end);
    }
    if (preset) {
      globalDatePickerParams.set(presetSizeSearchParamKey, preset);
    }
    if ((!start || !end) && !preset) {
      globalDatePickerParams.set(presetSizeSearchParamKey, appliedPreset);
    }
    return globalDatePickerParams.toString();
  }, [appliedPreset, endDateSearchParamKey, presetSizeSearchParamKey, searchParams, startDateSearchParamKey]);

  const setDatePickerRange = useCallback(
    (newRange: SimpleDateRange, preset?: string) => {
      const { from, to } = newRange;
      const startString = dateConstructorToReadableISOString(from);
      const endString = dateConstructorToReadableISOString(to);
      setRangeParams({ start: startString, end: endString, presetWindow: preset });
    },
    [setRangeParams],
  );

  const dateRange = useMemo((): SimpleDateRange => {
    const range: SimpleDateRange = (() => {
      if (!isNumber(startTimestamp) || !isNumber(endTimestamp)) return { from: 0, to: 0 };
      const { setStartOfProfile, setEndOfProfile } = getFunctionsForTimePeriod.get(usedTimePeriod) ?? {};
      if (autoAdjustTimestampsByTimePeriod && setStartOfProfile && setEndOfProfile) {
        return { from: setStartOfProfile(startTimestamp).getTime(), to: setEndOfProfile(endTimestamp).getTime() };
      }
      return { from: startTimestamp, to: setEndOfUTCMinute(endTimestamp).getTime() };
    })();
    // add one millisecond to the end timestamp only because backend uses exclusive operator '<' to match end timestamp
    return { ...range, to: range.to + 1 };
  }, [autoAdjustTimestampsByTimePeriod, endTimestamp, startTimestamp, usedTimePeriod]);

  const dateRangeWithOneDayMinimumInterval = useMemo((): SimpleDateRange => {
    return ensureDateRangeIsAtLeastOneDay(dateRange);
  }, [dateRange]);

  const applyTrailingWindowRange = (size: number, overrideTimePeriod?: TimePeriod) => {
    const presetGranularityToken =
      mapTimePeriodToPresetGranularity.get(overrideTimePeriod ?? usedTimePeriod) ?? 'daily';
    setRangeParams({
      start: null,
      end: null,
      presetWindow: `${presetGranularityToken}${RELATIVE_PRESET_TOKEN}${size}`,
    });
  };

  const totalLoading = usedLoading || !isNumber(startTimestamp) || !isNumber(endTimestamp);

  return {
    dateRange,
    loading: totalLoading,
    setDatePickerRange,
    appliedPreset,
    dateRangeWithOneDayMinimumInterval,
    openDatePicker,
    datePickerSearchString,
    isUsingFallbackRange,
    applyTrailingWindowRange,
  };
};
