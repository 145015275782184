import { createStyles, keyframes } from '@mantine/core';

export const $bubbleAppears = keyframes({
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const useStyles = createStyles({
  dialogRoot: {
    opacity: 0,
    transform: 'translateY(30px)',
    animation: `${$bubbleAppears} 1000ms ease-in-out forwards`,
  },
});

export default function DogDialogQuestionMark(): JSX.Element {
  const { classes: styles } = useStyles();

  return (
    <svg
      className={styles.dialogRoot}
      width="64"
      height="67"
      viewBox="0 0 64 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.63">
        <circle cx="32" cy="32" r="32" fill="#F1F1F1" />
        <path
          d="M42.3503 24.1683C42.3503 25.2511 42.2561 26.1927 42.0678 26.9931C42.2561 27.7464 42.3503 28.688 42.3503 29.8179C42.3503 30.9008 42.2561 31.8424 42.0678 32.6428H38.5368C38.7251 33.396 38.8193 34.3377 38.8193 35.4676C38.8193 36.5504 38.7251 37.492 38.5368 38.2924H35.0057C35.1941 39.0457 35.2882 39.9873 35.2882 41.1172C35.2882 42.2001 35.1941 43.1417 35.0057 43.9421H29.3561C29.1678 43.1417 29.0736 42.2001 29.0736 41.1172C29.0736 39.9873 29.1678 39.0457 29.3561 38.2924H32.8871C32.6988 37.492 32.6046 36.5504 32.6046 35.4676C32.6046 34.3377 32.6988 33.396 32.8871 32.6428H36.4182C36.2298 31.8424 36.1357 30.9008 36.1357 29.8179C36.1357 28.688 36.2298 27.7464 36.4182 26.9931C36.2298 26.1927 36.1357 25.2511 36.1357 24.1683C36.1357 23.0383 36.2298 22.0967 36.4182 21.3434H26.5313C26.7196 22.0967 26.8137 23.0383 26.8137 24.1683C26.8137 25.2511 26.7196 26.1927 26.5313 26.9931H20.8816C20.6933 26.1927 20.5991 25.2511 20.5991 24.1683C20.5991 23.0383 20.6933 22.0967 20.8816 21.3434H25.1188C24.9305 20.5431 24.8364 19.6015 24.8364 18.5186C24.8364 17.3887 24.9305 16.4471 25.1188 15.6938H39.243C39.4313 16.4471 39.5255 17.3887 39.5255 18.5186C39.5255 19.6015 39.4313 20.5431 39.243 21.3434H42.0678C42.2561 22.0967 42.3503 23.0383 42.3503 24.1683ZM35.0057 49.5917C35.1941 50.345 35.2882 51.2866 35.2882 52.4166C35.2882 53.4994 35.1941 54.441 35.0057 55.2414H29.3561C29.1678 54.441 29.0736 53.4994 29.0736 52.4166C29.0736 51.2866 29.1678 50.345 29.3561 49.5917H35.0057Z"
          fill="#B3BDBF"
        />
        <path d="M4 67V42L23 60.1818L4 67Z" fill="#F1F1F1" />
      </g>
    </svg>
  );
}
