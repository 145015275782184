
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "DataQualityEvent": [
      "DataQualityEventBase",
      "DataTypeEvent",
      "ThresholdEvent"
    ],
    "Dataset": [
      "Model",
      "Segment"
    ],
    "EventExplanation": [
      "DataTypeEventExplanation",
      "ThresholdEventExplanation",
      "BaseEventExplanation"
    ],
    "NotificationAction": [
      "SlackAction",
      "EmailAction",
      "PagerDutyAction",
      "MSTeamsAction",
      "CustomWebhookAction",
      "UnknownAction"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DataQualityEvent": [
      "DataQualityEventBase",
      "DataTypeEvent",
      "ThresholdEvent"
    ],
    "Dataset": [
      "Model",
      "Segment"
    ],
    "EventExplanation": [
      "DataTypeEventExplanation",
      "ThresholdEventExplanation",
      "BaseEventExplanation"
    ],
    "NotificationAction": [
      "SlackAction",
      "EmailAction",
      "PagerDutyAction",
      "MSTeamsAction",
      "CustomWebhookAction",
      "UnknownAction"
    ]
  }
};
      export default result;
    